import { Component, Input } from "@angular/core";
import { ClientService } from "src/app/core/utility/client.service";
import { EventCardConfig } from "src/app/yoimo-ui/cards/event/event-card.component";
import { SearchResultTabDirective } from "../search-results/search-result-tab";

@Component({
  selector: "yo-playlist-results",
  templateUrl: "./playlists.component.html",
})
export class PlaylistResultsComponent extends SearchResultTabDirective {
  @Input() playlists = [];
  @Input() canLoadMore = 0;
  @Input() isLoading = 0;

  eventCardConfig: Partial<EventCardConfig> = {
    appearance: "playlist",
    showCountdown: false,
    trackEventStatus: false,
  };

  constructor(clientService: ClientService) {
    super(clientService);
  }

  handleFinishedEvent(eventId: string) {
    console.group("Event finished");
    console.log(eventId);
    console.groupEnd();
  }
}
