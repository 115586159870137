<div [ngClass]="'table table-container ' + klass">
  <nav mat-tab-nav-bar class="table-nav">
    <div mat-tab-link *ngIf="showAdmin" [active]="activeTab == 'admin'" (click)="onTabNavSelect(0, 'admin')">Admins</div>
    <div mat-tab-link *ngIf="showTrainer" [active]="activeTab == 'trainer'" (click)="onTabNavSelect(1, 'trainer')">Trainers</div>
    <div mat-tab-link *ngIf="showStreamer" [active]="activeTab == 'streamer'" (click)="onTabNavSelect(2, 'streamer')">Streamers</div>
    <div mat-tab-link *ngIf="showMember" [active]="activeTab == 'member'" (click)="onTabNavSelect(3, 'member')">Members</div>
    <div mat-tab-link *ngIf="showInvite" [active]="activeTab == 'invite'" (click)="onTabNavSelect(4, 'invite')">Invites</div>
    <div class="pull-right yo-theme">
      <div class="actions" *ngIf="activeTab !== 'invite'">
        <button mat-raised-button [disabled]="!selection.hasValue()" i18n="user table remove button label" (click)="onRemoveClick()">
          <mat-icon>delete</mat-icon>
          <span mat-button-wrapper>Remove selected</span>
        </button>
      </div>
      <mat-form-field appearance="outlined">
        <mat-icon color="primary" matPrefix>filter_alt</mat-icon>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
    </div>
  </nav>

  <table class="" *ngIf="userDataSource.filteredData.length>0" mat-table [dataSource]="userDataSource" multiTemplateDataRows>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Avatar column -->
      <ng-container matColumnDef="avatar">
        <th mat-header-cell *matHeaderCellDef>Profile picture</th>
        <td mat-cell *matCellDef="let user">{{ user.pictureUrl }}</td>
      </ng-container>

      <!-- Email address -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email address</th>
        <td mat-cell *matCellDef="let user" [class.authenticated-user]="authenticatedUser?.email == user.email">
          {{ user.email }}</td>
      </ng-container>

      <!-- Intent Type -->
      <ng-container matColumnDef="intent">
        <th mat-header-cell *matHeaderCellDef>Invitation type</th>
        <td mat-cell *matCellDef="let user">
          {{ user.intentType }}</td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Invitation status</th>
        <td mat-cell *matCellDef="let user" [class.expired]="user.status ==='EXPIRED'"
          [class.pending]="user.status ==='PENDING'" [class.accepted]="user.status ==='ACCEPTED'">
          {{ user.status }}</td>
      </ng-container>

      <!-- Expiracy Date -->
      <ng-container matColumnDef="expiracy">
        <th mat-header-cell *matHeaderCellDef>Expiry date</th>
        <td mat-cell *matCellDef="let user">
          {{ user.expiracyDate }}</td>
      </ng-container>

      <tr mat-header-row class="row-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="row clickable" *matRowDef="let user; columns: displayedColumns;"
        [class.selected]="selectedUser?.docId == user.docId"></tr>
  </table>
   
  <div class="table-empty yo-theme mat-typography" *ngIf="userDataSource.filteredData.length===0">
    <h1>Ooops! It's empty</h1>
    <h3>Looks like there are no users to show.</h3>
  </div>
  <mat-paginator [class.hidden]="userDataSource.filteredData.length===0" #usersPaginator class="paginator"
    [length]="userLength" [pageSize]="10">
  </mat-paginator>
</div>