<yo-section [title]="'Live and Upcoming'"
  [sectionId]="'liveUpcoming'"
  [link]="['streams']"
  [linkQueryParams]="{time: 'upcoming', team: selectedTeamId}"
  [buttonLabel]="'See all streams'"
  iconSrc="assets/icons/streams_gradient.svg"
  data-header-border="true"
  data-padding="false"
  [showMore]="(streams$ | async)?.length"
>
  <yo-subchannel-selector class="subchannel-selector"
    header
    [subchannels]="teams"
    (onSubchannelSelected)="subchannelSelected($event)"
    [selectedSubchannel]="selectedTeamId"></yo-subchannel-selector>

  <yo-streams *ngIf="(streams$ | async)?.length; else empty"
    [streams]="streams$"
    [showLogos]="false"
    [customFormatFields]="{'subtitle': 'teamName' }"></yo-streams>
</yo-section>

<ng-template #empty>
  <yo-empty-section title="No streams in this channel"></yo-empty-section>
</ng-template>
