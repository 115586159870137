import { ChannelLayoutComponent } from "./channel-layout/channel-layout.component";
import { ChannelPlaylistsComponent } from "./channel-playlists/channel-playlists.component";
import { ChannelRoutingModule } from "./channel-routing.module";
import { ChannelStreamsComponent } from "./channel-streams/channel-streams.component";
import { CommonModule } from "@angular/common";
import { CoverComponent } from "./channel-layout/cover/cover.component";
import { EmptySectionComponent } from './shared/empty-section/empty-section.component';
import { HighlightsComponent } from "./channel-layout/highlights/highlights.component";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { NgModule } from "@angular/core";
import { PlaylistsComponent } from "./channel-layout/playlists/playlists.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ReplaysComponent } from "./channel-layout/replays/replays.component";
import { ShareButtonsModule } from "ngx-sharebuttons/buttons";
import { StreamsComponent } from "./channel-layout/streams/streams.component";
import { SubchannelSelectorComponent } from "./shared/subchannel-selector/subchannel-selector.component";
import { YoimoUiModule } from "../yoimo-ui/yoimo-ui.module";

@NgModule({
  declarations: [
    ChannelLayoutComponent,
    ChannelPlaylistsComponent,
    ChannelStreamsComponent,
    PlaylistsComponent,
    ReplaysComponent,
    StreamsComponent,
    CoverComponent,
	  HighlightsComponent,
    SubchannelSelectorComponent,
    EmptySectionComponent
  ],
  imports: [
    ChannelRoutingModule,
    CommonModule,
    YoimoUiModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTabsModule,
    ReactiveFormsModule,
    ShareButtonsModule.withConfig({
      include: ["copy", "facebook", "whatsapp", "messenger"],
    }),
  ],
})
export class ChannelModule {}
