<ng-container *ngIf="displayType == 'date'">
  <ng-container *ngTemplateOutlet="date"></ng-container>
</ng-container>
<ng-container *ngIf="displayType == 'range'">
  <ng-container *ngTemplateOutlet="dateRange"></ng-container>
</ng-container>


<ng-template #date>
  <div [ngSwitch]="eventStatus"
    class="label date">
  
    <div *ngSwitchDefault
      class="calendar"
      [class.today]="isStartingToday && eventStatus == 'scheduled'">
      <div class="icon">
        <ng-container *ngTemplateOutlet="calendarIcon"></ng-container>
      </div>
      <div class="label-text">{{ isStartingToday && eventStatus == 'scheduled' ? 'Today ': '' }}{{ dateLabel }}
      </div>
    </div>
    <div *ngSwitchCase="'live'"
      class="live">
      <div class="icon">
        <ng-container *ngTemplateOutlet="liveIcon"></ng-container>
      </div>
      <div class="label-text">Live {{ dateLabel }}</div>
    </div>
  </div>
</ng-template>


<ng-template #dateRange>
  <div class="label calendar">
    <div class="icon">
      <ng-container *ngTemplateOutlet="calendarIcon"></ng-container>
    </div>
    <div class="label-text">{{ dateLabel }}</div>
  </div>
</ng-template>

<ng-template #liveIcon>
  <svg viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90883 2.90953L2.90897 2.90939L3.61607 3.6165L3.61594 3.61663C1.1948 6.03777 1.19469 9.96313 3.61559 12.3844L2.90848 13.0915C0.0970564 10.2797 0.0971727 5.72119 2.90883 2.90953ZM13.0915 13.0915C15.9029 10.2797 15.9028 5.72119 13.0912 2.90953L13.091 2.90939L12.3839 3.6165L12.3841 3.61663C14.8052 6.03777 14.8053 9.96313 12.3844 12.3844L13.0915 13.0915ZM11.3941 11.3941C11.3941 11.3941 11.3941 11.3941 11.3941 11.3941C13.2685 9.51962 13.2686 6.48061 11.3944 4.60607L10.6872 5.31318C12.171 6.79719 12.1709 9.20304 10.687 10.6869C10.687 10.687 10.687 10.687 10.687 10.687L11.3941 11.3941ZM5.31302 10.687C5.31301 10.687 5.313 10.687 5.31299 10.6869C3.82908 9.20304 3.829 6.79719 5.31275 5.31318L4.60565 4.60607C2.73137 6.48061 2.73145 9.51962 4.60589 11.3941C4.6059 11.3941 4.60591 11.3941 4.60592 11.3941L5.31302 10.687Z" fill="white" />
    <circle cx="7.99961" cy="8.00059" r="2.4" fill="white" />
  </svg>
</ng-template>


<ng-template #calendarIcon>
  <svg viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="2.5" width="13" height="11" rx="1.5" stroke="currentColor" />
    <rect x="4" y="1" width="1" height="3" fill="currentColor" />
    <rect x="11" y="1" width="1" height="3" fill="currentColor" />
    <rect x="4" y="6.5" width="2" height="2" rx="1" fill="currentColor" />
    <rect x="4" y="9.5" width="2" height="2" rx="1" fill="currentColor" />
    <rect x="7" y="6.5" width="2" height="2" rx="1" fill="currentColor" />
    <rect x="7" y="9.5" width="2" height="2" rx="1" fill="currentColor" />
    <rect x="10"y="6.5"width="2"height="2"rx="1"fill="currentColor" />
    <path d="M1 4C1 2.89543 1.89543 2 3 2H13C14.1046 2 15 2.89543 15 4V5H1V4Z" fill="currentColor" />
  </svg>
</ng-template>