import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ConfirmDialogData {
  constructor(public title: string, public message: string, public icon?: string) {}
}


@Component({
  selector: "yo-confirm-dialog",
  template: `
    <h1 mat-dialog-title class="yo-theme mat-dialog-title">
      {{ title }}
    </h1>

    <div mat-dialog-content class="yo-theme mat-dialog-content">
      <p>{{ message }}</p>
    </div>

    <div mat-dialog-actions class="yo-theme mat-dialog-actions">
      <button mat-raised-button (click)="onDismiss()">No</button>
      <button mat-raised-button color="primary" (click)="onConfirm()">
        Yes
      </button>
    </div>
  `,
  styles: []
})
export class ConfirmDialogComponent implements OnInit {
  static dialog: MatDialog;

  title: string;
  message: string;
  icon: string;

  static open(
    dialog: MatDialog,
    title: string,
    message: string,
    icon?: string
  ) {
    const dialogData = new ConfirmDialogData(title, message, icon);

    const dialogRef = dialog.open(ConfirmDialogComponent, {
      backdropClass: "backdrop-custom",
      maxWidth: "500px",
      data: dialogData
    });

    return dialogRef.afterClosed();
  }

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  ngOnInit() {}
}
