<section class="operation">
  <ng-container *ngIf="callableResult$ | async as result; else loading">
    <div class="success" fxLayout="column" fxLayoutAlign="center center" *ngIf="result.success; else error">
      <p>Your action has been successful!</p>
      <p>You will soon receive a notification regarding this action.</p>
      <p>Thank you for using JOYMO, we hope that you are enjoying our services.</p>
    </div>

    <ng-template #error>
      <div class="error" fxLayout="column" fxLayoutAlign="center center">
        <p>Oops! An error has occurred while handling your request.</p>
        <p>Please contact our support and we will solve your issue as quickly as possible!</p>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <div class="awaiting" fxLayout="column" fxLayoutAlign="center center">
      <p>Handling your request . . . (it might take some time)</p>
      <yo-loading></yo-loading>
    </div>

  </ng-template>
</section>
