import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

/*
  This component uses content projection. It has one content projection slot:
  - content

  Remember to add the following to styles.css when using this component:
  .yo-no-padding-dialog .mat-dialog-container {
    padding: 0;
  }

  When opening the dialog, add the following parameter to the cofig:
  panelClass: 'yo-no-padding-dialog'
 */

@Component({
  selector: 'yo-dialog-skeleton',
  templateUrl: './dialog-skeleton.component.html',
  styleUrls: ['./dialog-skeleton.component.scss']
})
export class DialogSkeletonComponent implements OnInit {
  @Input() title: string;
  @Output() close = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  closeDialog() {
    this.close.emit(true);
  }

}
