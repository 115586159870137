import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Club } from 'src/app/core/model/interfaces/club';
import { Subscription } from 'src/app/core/model/interfaces/subscription';
import { User } from 'src/app/core/model/interfaces/user';
import { ClubsService } from 'src/app/core/model/services/clubs.service';
import { InvitesService, TransferOwnershipRequest } from 'src/app/core/model/services/invites.service';
import { UsersService } from 'src/app/core/model/services/users.service';
import { FunctionsError } from '@angular/fire/functions';

export interface TransferOwnershipDialogData {
  subscription: Subscription;
  user: User;
}

@Component({
  selector: 'yo-transfer-ownership',
  templateUrl: './transfer-ownership.component.html',
  styleUrls: ['./transfer-ownership.component.scss']
})
export class TransferOwnershipDialogComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild('adminSelection') public adminSelection: TemplateRef<any>;
  @ViewChild("success", { static: true }) success: TemplateRef<any>;
  @ViewChild("spinner") spinner: TemplateRef<any>;
  @ViewChild("contactSupport") contactSupport: TemplateRef<any>;

  currentStep: "adminSelection" | "spinner" | "success" | "contactSupport" = "adminSelection";
  club: Club;
  clubAdmins: User[] = [];
  admins: FormControl;
  adminsForm: FormGroup;
  error: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TransferOwnershipDialogData,
    public dialogRef: MatDialogRef<TransferOwnershipDialogComponent>,
    private clubService: ClubsService,
    private inviteService: InvitesService,
    private userService: UsersService,
    private formBuilder: FormBuilder
  ) {
    this.clubAdmins = [];
    this.admins = new FormControl('', [Validators.required, (control) => this.inputIsAdmin(control)]);
    this.adminsForm = this.formBuilder.group({
      admins: this.admins
    });
  }

  ngOnInit() {
    this.clubService.getByDocId(this.data.subscription.clubDocId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        club => {
          this.club = club;
          this.clubAdmins = this.club.adminDocIds.map(adminId => this.club.users[adminId]).filter(u => u.email !== this.data.user.email);
          console.group('Club Fetched');
          console.log('Club', this.club);
          console.log('Admins', this.clubAdmins);
          console.groupEnd();
        },
        err => console.error(err)
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  confirmTransfer() {
    this.currentStep = "spinner";
    console.group('Confirmed Transfer');
    console.log('selectedAdmin', this.admins.value);
    const requestData: TransferOwnershipRequest = {
      clubDocId: this.club.docId,
      newOwnerId: this.clubAdmins.find(u => u.email === this.admins.value).docId || undefined,
    };
    console.log("Request data", requestData);
    console.groupEnd();
    this.inviteService.transferOwnership(requestData)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        response => this.handleTransferOwnershipResponse(response),
        err => this.handleTransferOwnershipError(err)
      );
  }

  inputIsAdmin(control: AbstractControl) {
    return this.clubAdmins.find(u => u.email == control.value) === undefined ? { notAdmin: { value: control.value } } : null;
  }

  handleTransferOwnershipResponse(response) {
    console.group("Transfered ownership");
    console.log("Response", response);
    console.groupEnd();
    this.currentStep = "success";
  }

  handleTransferOwnershipError(err: FunctionsError) {
    console.group("Transfer Ownership failed");
    console.error(err);
    console.groupEnd();
    this.error = {
      ...err,
      message: err.message,
      userId: this.data.user.docId,
      subscriptionId: this.data.subscription.docId
    };
    this.currentStep = "contactSupport";
  }


}
