<div *ngIf="event && !useMobileTemplate"
  class="yo-theme card event"
  [ngClass]="_classes"
  [id]="event.id"
  [routerLink]="event.routerLink">
  <div class="image">

    <div class="cover">
      <div class="thumbnail"
        *ngIf="hasThumbnail"
        [style.backgroundImage]="'url(' + event.thumbnail + ')'">
      </div>
      <div class="rectangle"
        [class.thumbnail-cover]="hasThumbnail"
        [class.no-bg]="hasThumbnail"></div>
      <div class="logo-container"
        *ngIf="!hasThumbnail">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </div>
    </div>

    <div *ngIf="showCountdown && event.startDate"
      class="countdown-timer">
      <yo-countdown *ngIf="!isStartingSoon; else startingSoon"
        [targetDate]="event.startDate"
        [inline]="true"
        [resolution]="countDownConfig.resolution"
        [placesToShow]="countDownConfig.placesToShow"
        [event]="true">
      </yo-countdown>
      <ng-template #startingSoon>
        <div class="count-down event">
          <div class="segment seconds">
            <span class="value">
              < 1</span>
                <span class="unit-label">Min</span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="card-bottom">
    <div class="top">
      <ng-container *ngTemplateOutlet="eventInfo"></ng-container>
      <div *ngIf="hasThumbnail && showLogo"
        class="small-logo">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="divider"></ng-container>
    <div class="bottom">
      <div class="labels"
        [ngSwitch]="appearance">
        <ng-container *ngSwitchCase="'stream'">
          <yo-event-date [displayType]="'date'"
            [startDate]="event.startDate"
            [stopDate]="event.stopDate"
            [trackEventstatus]="trackEventStatus"></yo-event-date>
        </ng-container>
        <ng-container *ngSwitchCase="'playlist'">
          <yo-event-date [displayType]="'range'"
            [startDate]="event.startDate"
            [stopDate]="event.stopDate"
            [trackEventstatus]="trackEventStatus"></yo-event-date>
        </ng-container>
      </div>
      <div class="price">{{ event.price | async }}</div>
    </div>
  </div>
</div>

<div *ngIf="event && useMobileTemplate"
  class="yo-theme card mobile event"
  [id]="event.id"
  [routerLink]="event.routerLink">
  <div class="card-bottom">
    <div class="top">
      <div class="labels"
        [ngSwitch]="appearance">
        <ng-container *ngSwitchCase="'stream'">
          <yo-event-date [displayType]="'date'"
            [startDate]="event.startDate"
            [stopDate]="event.stopDate"
            [trackEventstatus]="trackEventStatus"></yo-event-date>
        </ng-container>
        <ng-container *ngSwitchCase="'playlist'">
          <yo-event-date [displayType]="'range'"
            [startDate]="event.startDate"
            [stopDate]="event.stopDate"
            [trackEventstatus]="trackEventStatus"></yo-event-date>
        </ng-container>
      </div>
      <ng-container *ngTemplateOutlet="eventInfo"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="divider"></ng-container>
    <div class="bottom">
      <div class="labels">
        <div class="subtitle">{{ event.subtitle }}</div>
      </div>
      <div class="price">{{ event.price | async }}</div>
    </div>
  </div>
  <div class="image">
    <div class="cover">
      <div class="thumbnail"
        *ngIf="hasThumbnail"
        [style.backgroundImage]="'url(' + event.thumbnail + ')'">
      </div>
      <div class="rectangle"
        [class.thumbnail-cover]="hasThumbnail"
        [class.no-bg]="hasThumbnail"></div>
      <div class="logo-container"
        *ngIf="!hasThumbnail">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </div>
    </div>
  </div>
</div>




<ng-template #logo>
  <div class="logo"
    [style.backgroundImage]="event.logo && event.logo != null ? 'url(' + event.logo + ')' : 'url(assets/club_default_logo.png)'">
  </div>
</ng-template>

<ng-template #eventInfo>
  <div class="info"
    [class.with-logo]="hasThumbnail">
    <div class="title">{{ event.title }}</div>
    <div class="subtitle"
      *ngIf="!useMobileTemplate && showSubtitle">{{ event.subtitle }}</div>
  </div>
</ng-template>

<ng-template #divider>
  <div class="divider"></div>
</ng-template>