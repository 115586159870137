import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'yo-social-direct-links',
  templateUrl: './social-direct-links.component.html',
  styleUrls: ['./social-direct-links.component.scss']
})
export class SocialDirectLinksComponent {

}
