import { Component, OnDestroy, OnInit } from "@angular/core";
import { EventCardComponentInput, EventCardConfig } from "src/app/yoimo-ui/cards/event/event-card.component";
import { Subject, merge, of } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";

import { ActivatedRoute } from "@angular/router";
import { ClientService } from "src/app/core/utility/client.service";
import { ClubPublic } from "src/app/core/model/interfaces/club-public";
import { LivestreamGroup } from "src/app/core/model/interfaces/livestream-group";
import { LivestreamGroupsService } from "src/app/core/model/services/livestream-groups.service";
import { PagedQueryCommand } from "src/app/core/model/collection";
import { PaymentsService } from "src/app/core/model/services/payments.service";

@Component({
  selector: 'yo-channel-playlists',
  templateUrl: './channel-playlists.component.html',
  styleUrls: ['./channel-playlists.component.scss']
})
export class ChannelPlaylistsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  canLoadMore = true;
  club: ClubPublic;
  elements: EventCardComponentInput[] = [];
  isLoading = true;
  isMobileView = false;
  loadMore: Subject<PagedQueryCommand> = new Subject();

  cardConfig: Partial<EventCardConfig> = {
    appearance: 'playlist',
    showCountdown: false,
    trackEventStatus: false,
  };

  constructor(
    private clientService: ClientService,
    private route: ActivatedRoute,
    private collectionService: LivestreamGroupsService,
    private paymentService: PaymentsService
  ) { }

  ngOnInit(): void {
    this.club = this.route.snapshot.data.club;
    this.isMobileView = this.clientService.isMobileDevice();
    console.log("Channel playlist route", this.club);
    this.fetchCollections().pipe(
      map(({ data, paging }) => {
        // Check if it should autoload the next batch of data,
        // if what we received was just archived streams
        this.canLoadMore = paging.canLoadNext;
        // const { length: notArchivedCount } = data.filter(s => !s.archived);
        // if (notArchivedCount <= this.elements.length && this.canLoadMore) {
        //   this.loadMore.next("next");
        // }
        return data;
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      collections => this.collectionsFetched(collections)
    );
  }

  fetchCollections() {
    return this.collectionService.pagedQuery(
      25,
      merge(of("reset"), this.loadMore.pipe(tap(() => this.isLoading = true))),
      [
        ["clubDocId", "==", this.club.docId],
      ],
      [{ field: "stopTime", sort: "asc" }]
    );
  }

  collectionsFetched(collections: LivestreamGroup[]) {
    const streamElements = collections.map(stream => ({ ...this.paymentService.fromCollectionToEventCardInput(stream), subtitle: stream.teamName }));
    this.elements = streamElements;
    this.isLoading = false;
  }

  trackElements(index: number, element: Object & { docId: string }): string | null {
    return element.docId || null;
  }

  handleFinishedEvent(eventId: string) {
    console.group("Event finished");
    console.log(eventId);
    console.groupEnd();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
