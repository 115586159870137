import { Injectable, Injector } from "@angular/core";

import { Observable } from "rxjs";

import { Collection } from "../collection";
import { Highlight } from "../interfaces/short";

@Injectable({ providedIn: "root" })
export class ShortsService extends Collection<Highlight> {
  constructor(injector: Injector) {
    super(injector, "shorts", true);
  }

  getByClubDocId(clubDocId: string): Observable<Highlight[]> {
    return this.query(
      [
        ["clubDocId", "==", clubDocId],
        ["archived", "==", false],
      ],
      undefined,
      [{ field: "createdTime", sort: "desc" }]
    );
  }
}
