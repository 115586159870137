import { BehaviorSubject, Observable, combineLatest, from, of } from 'rxjs';
import { Callable, Collection, uniqBy } from '../collection';
import { Injectable, Injector } from '@angular/core';
import { arrayRemove, arrayUnion } from '@angular/fire/firestore';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from '../../auth/auth.service';
import { Club } from '../interfaces/club';
import { Shareable } from '../interfaces/shareable';
import { TeamsService } from './teams.service';
import { UploadType } from '../../utility/upload.service';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: "root"
})
export class ClubsService extends Collection<Club> implements Shareable{

  private userIsAdminOrTrainer = new BehaviorSubject<boolean>(false);
  public userIsAdminOrTrainer$: Observable<boolean> = this.userIsAdminOrTrainer.asObservable();

  removeMembersCallable: Callable<{clubDocId: string, userDocIds: string[]}, {success: boolean}>;

  // clubsWhereAdminOrTrainer$: Observable<Club[]>;

  constructor(
    private _injector: Injector,
    private authService: AuthService
  ) {
    super(_injector, 'clubs', true);
    console.warn('Using the default converter for ClubsService');
    this.authService.authenticatedUser$
      .pipe(
        switchMap((user: User) => {
          return user ? this.getClubsWhereAdminOrTrainer(user.docId) : of(null);
        })
      )
      .subscribe((clubs) => {
        this.updateIsAdminOrTrainer(clubs && clubs.length > 0 ? true : false);
      });
    this.removeMembersCallable = this.declareCallable<ClubsService['removeMembersCallable']>('users-removeMembersOfClub');
  }

  updateIsAdminOrTrainer(check: boolean) {
    this.userIsAdminOrTrainer.next(check);
  }

  getClubsWhereAdminOrTrainer(userDocId: string): Observable<Club[]> {

    const clubsWhereAdmin$ = this.query(['adminDocIds', 'array-contains', userDocId]);
    const clubsWhereTrainer$ = this.query(['trainerDocIds', 'array-contains', userDocId]);

    return combineLatest([clubsWhereAdmin$, clubsWhereTrainer$])
      .pipe(
        map(([adminClubs, trainerClubs]) => {
          // Flattens the arrays
          const clubsMap = new Map<string,Club>();
          adminClubs.forEach(c => clubsMap.set(c.docId, c));
          trainerClubs.forEach(c => clubsMap.set(c.docId, c));
          return Array.from(clubsMap, ([_id, c]) => c);
        })
      );
  }

  // The role in here needs to be 'admin' or 'trainer'
  addUserToClub(club: string, user: User, role: string): Observable<any> {
    const updateData = {};
    updateData[`${role}DocIds`] = arrayUnion(
      user.docId
    );
    // TODO: Handling a user that is already an admin

    // Concerts the promise to an observable
    return from(this.update(club, updateData));
  }

  removeMembersFromClub(
    clubDocId: string,
    userDocIds: string[]
  ): Observable<boolean> {
    return this.removeMembersCallable({clubDocId, userDocIds}).pipe(map(r => r.success));
  }

  // Remove a list of users from the club
  removeUserFromClub(
    clubDocId: string,
    userDocIds: string[],
    role: "STREAMER" | "ADMIN" | "TRAINER"
  ): Observable<any> {
    switch (role) {
      case "STREAMER":
      case "ADMIN": {
        const updateData = {
          [role === "STREAMER"
            ? "streamerDocIds"
            : "adminDocIds"]: arrayRemove(
              ...userDocIds
            )
        };
        return from(this.update(clubDocId, updateData));
      }
      case "TRAINER": {
        /* TODO#: Deletegate this to the teams service */
        const teamsService = this._injector.get(TeamsService);
        return teamsService.getTeamsByClubDocId(clubDocId, true).pipe(
          switchMap(
            teams => combineLatest(
              teams.map( team => from(teamsService.removeTrainers(userDocIds, team.docId)))
            )
          )
        );
      }
    }
  }
  sortByName(clubs: Club[]): Club[] {
    return clubs.sort((a, b) => {
      const clubAName = a.name.toLowerCase();
      const clubBName = b.name.toLowerCase();

      if (clubAName < clubBName) {
        // sort ascending
        return -1;
      }
      if (clubAName > clubBName) {
        return 1;
      }
      return 0;
    });
  }

  getStoragePath(clubDocId: string, target: UploadType): string {
    return ["channels", clubDocId, target].join("/");
  }

  getShareableURL(clubDocId: string) {
    return new URL(`/api/share/c/${clubDocId}`, document.location.href)
  }
}
