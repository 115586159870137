import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Storage, getDownloadURL, ref } from '@angular/fire/storage';
import { takeUntil, tap } from 'rxjs/operators';

import { AuthService } from '../../core/auth/auth.service';
import { ClientService } from '../../core/utility/client.service';
import { LoginMode } from '../LoginMode.enum';
import { User } from 'src/app/core/model/interfaces/user';

@Component({
  selector: "yo-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent implements OnInit, OnDestroy {

  readonly LoginMode = LoginMode;
  private ngUnsubscribe = new Subject();
  isInApp = false;

  isLoading = false;

  @Input() loginMode: LoginMode = LoginMode.SIGN_IN;
  @Input() compact = false;
  @Input() targetEmail: string = undefined;

  @Output() isLoggedIn = new EventEmitter<boolean>();

  loginErrorMessage: string;
  registerErrorMessage: string;
  forgotPasswordErrorMessage: string;
  forgotPasswordEmailSentMessage: string;

  loginForm = new FormGroup({
    email: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
  });

  registerForm = new FormGroup({
    email: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
    confirmPassword: new FormControl("", Validators.required),
    conditions: new FormControl("", Validators.required),
  });

  forgotPasswordForm = new FormGroup({
    email: new FormControl("", Validators.required),
  });

  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    private afStorage: Storage
  ) { }

  ngOnInit() {
    this.isInApp = this.clientService.isClientInApp();

    // Reset error message when changing email address
    this.forgotPasswordForm.valueChanges
      .subscribe(val => this.forgotPasswordErrorMessage = this.forgotPasswordEmailSentMessage = undefined);

    if (this.targetEmail) {
      [this.loginForm, this.registerForm, this.forgotPasswordForm].forEach(group => {
        group.get('email').reset({ value: this.targetEmail, disabled: true });
      });

      this.authService.getUserSigningMethods(this.targetEmail)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          r => {
            if (r.length === 0) {
              this.switchMode(LoginMode.REGISTER);
            }
          },
          err => console.error("An error occured while fetching UserSigningMethods", err)
        );
    }

  }

  switchMode(mode: LoginMode) {
    this.loginMode = mode;
    this.loginErrorMessage = undefined;
    this.registerErrorMessage = undefined;
    this.forgotPasswordErrorMessage = undefined;
    this.forgotPasswordEmailSentMessage = undefined;
  }

  login() {
    this.isLoading = true;
    this.waitForLogin(this.authService.login(this.loginForm.getRawValue()));
  }

  useGoogle() {
    this.isLoading = true;
    this.waitForLogin(this.authService.logInWithGoogle());
  }

  waitForLogin(userObservable: Observable<User>) {
    userObservable.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        result => {
          console.log('User logged in successfully');
          this.isLoggedIn.emit(true);
          //this.isLoading = false;
        },
        error => {
          console.error("An error occured while logging in", error);
          this.loginErrorMessage = this.authService.formatErrorMessage(error);
          this.isLoading = false;
        }
      );
  }

  register() {
    if (
      this.registerForm.value.password !==
      this.registerForm.value.confirmPassword
    ) {
      this.registerErrorMessage = "The two passwords did not match.";
      return;
    }

    if (this.registerForm.get("conditions").value) {
      this.isLoading = true;
      this.authService.register(this.registerForm.getRawValue())
        .pipe(
          //tap(val => console.log(">>> Result from registration", val)),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(
          () => {
            this.isLoggedIn.emit(true);
            this.isLoading = false;
          },
          (error) => {
            this.registerErrorMessage = this.authService.formatErrorMessage(error);
            console.error(error);
            this.isLoading = false;
          }
        );
    } else {
      this.registerErrorMessage = "Terms & conditions need to be accepted";
    }
  }

  forgotPassword() {
    this.isLoading = true;
    this.authService.forgotPassword(this.forgotPasswordForm.getRawValue().email)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.forgotPasswordEmailSentMessage = "Forgot password email sent.";
          this.isLoading = false;
        },
        err => {
          console.error("An error occured while sending the forgot password email", err);
          this.forgotPasswordErrorMessage = this.authService.formatErrorMessage(err);
          // this.forgotPasswordErrorMessage = "Could not send forgot email message";
          this.isLoading = false;
        }
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
