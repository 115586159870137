import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { Livestream } from 'src/app/core/model/interfaces/livestream';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'yo-embed-code-generator',
  templateUrl: './embed-code-generator.component.html',
  styleUrls: ['./embed-code-generator.component.scss']
})
export class EmbedCodeGeneratorComponent implements OnInit {
  @Input() stream: Livestream;
  @Input() shareUrl: string;
  @Input() hideSocialSharing: boolean = false;
  constructor(
    private snackBar: MatSnackBar
  ) { }

  @ViewChild(MatButtonToggleGroup)
  public playerSizeToggleGroup: MatButtonToggleGroup;

  predefinedPlayerHeights = [640, 854, 1280, 1920];
  playerSize = this.predefinedPlayerHeights[0];

  embedInWebsite = false;

  iframeHeight: number;
  iframeWidth: number = 640;
  allowFullscreen: boolean = true;
  compactCode: boolean = true;
  generatedCode: string;

  setEmbededIframeWidth(width: number) {
    this.iframeWidth = width;
    this.iframeHeight = Math.floor( this.iframeWidth * 9 / 16 );
  }
  playerSizeChanged(source: 'toggle' | 'input', event) {
    let playerHeight;
    if (source === 'toggle') {
      playerHeight = +event.value;
    } else {
      playerHeight = +event.target.value;
      console.log(this.playerSizeToggleGroup);
      if (this.predefinedPlayerHeights.includes(playerHeight)) {
        this.playerSizeToggleGroup.value = playerHeight;
      } else {
        this.playerSizeToggleGroup.value = null;
      }
    }
    this.playerSize = playerHeight;
    this.setEmbededIframeWidth(playerHeight);
    this.generateCode();
  }

  onCheckboxChange(event) {
    this[event.source.name] = event.checked;
    this.generateCode();
  }

  generateCode() {
    let path = new URL(`/v/${this.stream.docId}`, document.location.href);
    let codeElements = [
      `<iframe id="yvp-${this.stream.docId}"`,
      `  frameborder="0"`,
      this.allowFullscreen ? `  allowfullscreen` : '',
      `  width="${this.iframeWidth}"`,
      `  height="${this.iframeHeight}"`,
      `  src="${path.toString()}"`,
      `></iframe>`];
    this.generatedCode = this.compactCode ? codeElements.map(s=>s.trim()).filter(s=>!!s).join(' '): codeElements.filter(s=>!!s).join('\n');
  }

  selectAndCopy(event, type: 'url' | 'code') {
    event.target.select();
    document.execCommand('copy');
    this.snackBar.open(`${type === "code" ? "The code" : "The URL"} has been copied to the clipboard.`, '', {duration: 1500});
  }

  ngOnInit() {
    this.setEmbededIframeWidth(this.iframeWidth);
    this.generateCode();
  }

}
