<section class="playlists">
  <div class="header" [ngClass]="{'mobile': isMobileView}">
    <a class="backward"
      [routerLink]="['/browse', 'clubs', club.docId]">
      <div class="arrow"></div>
    </a>
    <h1 class="title">{{ club.name }}</h1>
  </div>

  <!-- <div class="navigation"> -->
  <!-- <nav mat-tab-nav-bar> -->
  <!-- <div mat-tab-link
        (click)="navigateTo('time', 'upcoming')"
        [active]="activeLink == 'upcoming'">
        Live and Upcoming
      </div>
      <div mat-tab-link
        (click)="navigateTo('time', 'past')"
        [active]="activeLink == 'past'">
        Replays
      </div> -->

  <!-- Insert subchannel selector -->
  <!-- <yo-subchannel-selector [subchannels]="teams" (onSubchannelSelected)="subchannelSelected($event)" [selectedSubchannel]="selectedTeamId" class="mat-tab-link push-left"></yo-subchannel-selector> -->
  <!-- </nav> -->
  <!-- </div> -->
  <div class="content"
    *ngIf="!isMobileView; else mobileList"
    [style.margin]="- 1/2 + 'rem'">

    <yo-event-card *ngFor="let element of elements; let key = index; trackBy: trackElements"
      class="yo-theme card-holder"
      [style.margin]="1/2 + 'rem'"
      [style.flexBasis]="'calc(' + 100 / 4 + '% - ' + '1' + 'rem)'"
      [event]="element"
      [appearance]="cardConfig.appearance"
      [showLogo]="false"
      [showCountdown]="cardConfig.showCountdown"
      (eventFinished)="handleFinishedEvent(element.id)">
    </yo-event-card>

  </div>

  <ng-template #mobileList>
    <yo-event-card *ngFor="let element of elements; let key = index; trackBy: trackElements"
      class="yo-theme card-holder mobile"
      [style.margin]="1/2 + 'rem'"
      [event]="element"
      [useMobileTemplate]="true"
      [appearance]="cardConfig.appearance"
      [showLogo]="false"
      [showCountdown]="cardConfig.showCountdown"
      (eventFinished)="handleFinishedEvent(element.id)">
    </yo-event-card>
  </ng-template>

  <footer>
    <button (click)="loadMore.next('next')"
      *ngIf="!isLoading; else spinner"
      mat-stroked-button
      type="button">
      Load more
    </button>
  </footer>
</section>

<ng-template #spinner>
  <yo-spinner></yo-spinner>
</ng-template>