import { Component, Input, OnInit } from "@angular/core";

import { ChannelService } from "../../shared/channel.service";
import { LivestreamGroup } from "src/app/core/model/interfaces/livestream-group";
import { Observable } from "rxjs";

@Component({
  selector: 'yo-playlists-section',
  templateUrl: './playlists.component.html'
})
export class PlaylistsComponent implements OnInit {

  @Input("clubId") clubId: string;
  collections$: Observable<LivestreamGroup[]>;

  constructor(
    private channelService: ChannelService
  ) {}

  ngOnInit(): void {
    this.collections$ = this.channelService.playlists$;
  }

}