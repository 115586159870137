import { ErrorHandler } from "@angular/core";

/**
 * Dummy global error handler supposed to implement the logic
 * of reporting errors in production once we settle on one provider:
 * Current list of alternatives include:
 * - TrackJS
 * - Sentry
 * - Google Cloud Error (more for node.js but can be used through the rest API)
 * - RollBar
 * - LogRocket
 * - Raygun
 */
export class GlobalErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }
  handleError(error: any): void {
    super.handleError(error);
  }
}
