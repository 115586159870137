<ng-container *ngIf="!isMobileView; else mobileEvents">
  <div class="grid-container"
  *ngIf="useHeader"
    [style.margin]="breakpoint.gutterSize + 'rem ' + '-' + breakpoint.gutterSize/2 + 'rem'">

    <yo-event-card *ngFor="let element of headerElements; let key = index; trackBy: trackElements"
      class="yo-theme card-holder"
      [style.margin]="breakpoint.gutterSize/2 + 'rem'"
      [ngStyle]="{
        'width': headerElements.length < breakpoint.cols.header ? 'calc(' + 100 / breakpoint.cols.header + '% - ' + breakpoint.gutterSize + 'rem)' : 100,
        'flex-grow': headerElements.length < breakpoint.cols.header ? 0 : 1
      }"
      [event]="element"
      [appearance]="headerCardsConfig.appearance"
      [showCountdown]="headerCardsConfig.showCountdown"
      [trackEventStatus]="headerCardsConfig.trackEventStatus"
      (eventFinished)="handleFinishedEvent(element.id)"
      [size]="'large'"></yo-event-card>

  </div>
  <div class="grid-container wrap"
    [style.margin]="- breakpoint.gutterSize/2 + 'rem'">
    <yo-event-card *ngFor="let element of mainElements; let key = index; trackBy: trackElements"
      class="yo-theme card-holder"
      [style.margin]="breakpoint.gutterSize/2 + 'rem'"
      [style.flexBasis]="'calc(' + 100 / breakpoint.cols.main + '% - ' + breakpoint.gutterSize + 'rem)'"
      [event]="element"
      [appearance]="mainCardsConfig.appearance"
      [showCountdown]="mainCardsConfig.showCountdown"
      (eventFinished)="handleFinishedEvent(element.id)">
    </yo-event-card>
  </div>
</ng-container>

<ng-template #mobileEvents>
  <div class="list-container">
    <yo-event-card *ngFor="let element of headerElements; let key = index; trackBy: trackElements"
      class="yo-theme card-holder"
      [style.margin]="breakpoint.gutterSize/2 + 'rem'"
      [event]="element"
      [appearance]="headerCardsConfig.appearance"
      [showCountdown]="headerCardsConfig.showCountdown"
      [trackEventStatus]="headerCardsConfig.trackEventStatus"
      (eventFinished)="handleFinishedEvent(element.id)">
    </yo-event-card>
  </div>
  <div class="list-container">
    <yo-event-card *ngFor="let element of mainElements; let key = index; trackBy: trackElements"
      class="yo-theme card-holder mobile"
      [style.margin]="breakpoint.gutterSize/2 + 'rem'"
      [useMobileTemplate]="true"
      [event]="element"
      [appearance]="mainCardsConfig.appearance"
      [showCountdown]="mainCardsConfig.showCountdown">
    </yo-event-card>
  </div>
</ng-template>