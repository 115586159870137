import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[yoLabel]'
})
export class LabelDirective implements OnInit {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'font-size', '12px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'font-weight', '600px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'letter-spacing', '1px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'text-transform', 'uppercase');
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'block');
    this.renderer.setStyle(this.elementRef.nativeElement, 'text-align', 'left');
    this.renderer.setStyle(this.elementRef.nativeElement, 'text-align', 'left');
    this.renderer.setStyle(this.elementRef.nativeElement, 'color', '#e8eff7');
    this.renderer.setStyle(this.elementRef.nativeElement, 'padding', '5px 0');
  }
}
