import { Injectable, Injector } from '@angular/core';

import { Collection } from '../collection';
import { Observable } from 'rxjs';
import { PaymentHistory } from '../interfaces/paymentHistory';
import { PaymentHistoryProduct } from '../interfaces/paymentHistoryProduct';

@Injectable({
  providedIn: 'root'
})
export class PaymentsHistoryService extends Collection<PaymentHistory> {

  constructor(
    injector: Injector,
  ) {
    super(injector, 'paymentsHistory', true);
  }

  getProduct(userDocId: string, productDocId: string): Observable<PaymentHistoryProduct> {
    const productCollection = this.getSubCollection<PaymentHistoryProduct>(userDocId, 'products');
    return productCollection.getByDocId(productDocId);
  }
}
