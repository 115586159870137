<button class="cta" [class.mobile]="mobileView"
  mat-stroked-button routerLink="/manage/streams/new">
  <svg width="19"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7085 4.16699H0.833984V7.38221C0.833984 7.92253 1.16732 8.41313 1.68653 8.63802L2.51242 9.18799V14.4532C2.51242 15.2154 3.16575 15.8337 3.97124 15.8337H12.7085C13.514 15.8337 14.1673 15.2154 14.1673 14.4532V5.54675C14.1673 4.78525 13.514 4.16699 12.7085 4.16699Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M14.166 11.7687L19.166 14.1663V5.83301L15.7196 7.49923C14.7732 7.94489 14.166 8.91943 14.166 9.99194V11.7687Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  {{ mobileView ? '' : 'New Livestream' }}
</button>