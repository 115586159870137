<div class="search-result yo-theme">
  <ng-container *ngIf="isResultPresent">
    <header>
      <h2>{{ searchText }}</h2>
    </header>

    <mat-tab-group>
      <!-- Channels tab -->
      <mat-tab label="Channels" *ngIf="showTabMap['club']">
        <yo-channel-results
          [clubs]="channelsTab.data"
          [isLoading]="channelsTab.isLoading"
          [canLoadMore]="channelsTab.canLoadMore"
          (loadMore)="loadMoreChannels()"
        ></yo-channel-results>
      </mat-tab>
      <!-- Playlists tab -->
      <mat-tab label="Playlists" *ngIf="showTabMap['livestream-group']">
        <yo-playlist-results
          [playlists]="playlistsTab.data"
          [isLoading]="playlistsTab.isLoading"
          [canLoadMore]="playlistsTab.canLoadMore"
          (loadMore)="loadMorePlaylists()"
        ></yo-playlist-results>
      </mat-tab>
      <!-- Streams tab -->
      <mat-tab label="Streams and videos" *ngIf="showTabMap['livestream']">
        <yo-stream-results
          [streams]="streamsTab.data"
          [isLoading]="streamsTab.isLoading"
          [canLoadMore]="streamsTab.canLoadMore"
          (loadMore)="loadMoreStreams()"
        ></yo-stream-results>
      </mat-tab>
    </mat-tab-group>
    <ng-template #spinner>
      <yo-spinner></yo-spinner>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="!isResultPresent && resultsLoaded">
    <div class="search-result-not-found">
      <h3>No content found</h3>
      <p>
        We couldn't find a match for '{{ searchText }}'. Please try another
        search.
      </p>
    </div>
  </ng-container>

  <yo-loading *ngIf="!resultsLoaded"></yo-loading>
</div>
