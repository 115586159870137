import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'yo-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  @Input() variants: string[];
  name: string = "Black"; // Default name that points towards assets/Joymo_Play_Icon_Black_CMYK.svg
  fileSrc: string = "";

  constructor() { }

  ngOnInit() {
    this.name = this.variants ? this.variants.map(variant => variant.charAt(0).toUpperCase() + variant.slice(1)).join("_") : this.name;
    console.log(this.name);
    this.fileSrc = `assets/Joymo_Play_Icon_${this.name}_CMYK.svg`;
  }

}
