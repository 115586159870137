<div class='container' ngClass.lt-md='handset'
  gdAreas="header-left header header-right | left mid right | footer footer footer"
  gdAreas.lt-md="header-left header header-right | mid | footer" gdGap="5px" gdRows="auto auto auto">
  <div gdArea="mid">
    <div class="invite-form-container yo-theme">
      
      <ng-container *ngIf='!isLoading; else loadingAnimation'>
        <ng-container [ngSwitch]='acceptInviteResult'>
          <ng-container *ngSwitchCase='"UNKNOWN"'>
            
            <ng-container *ngIf="acceptInviteIntent == 'CHANGE_OWNERSHIP'; else other">
              <yo-accept-ownership [club]="club" [invite]="invite" (ownershipAccepted)="acceptInvite()"></yo-accept-ownership>
            </ng-container>
            <ng-template #other>
              <div class="terms-and-conditions" [ngSwitch]='invite.intentType'>
                <h2 style="text-align: center;">Welcome to Joymo</h2>
                <p>{{invite.message}}</p>
                <yo-terms-and-conditions></yo-terms-and-conditions>
                <!--
                  <yo-accept-invite-admin *ngSwitchCase='"ADMIN"' [inviteMessage]='invite.message'></yo-accept-invite-admin>
                  <yo-accept-invite-admin *ngSwitchCase='"TRAINER"' [inviteMessage]='invite.message'></yo-accept-invite-admin>
                  <yo-accept-invite-admin *ngSwitchCase='"STREAMER"' [inviteMessage]='invite.message'></yo-accept-invite-admin>
                  <yo-accept-invite-admin *ngSwitchCase='"MEMBER"' [inviteMessage]='invite.message'></yo-accept-invite-admin>
                -->
              </div>
              <div fxLayout="row" fxWrap="wrap" fxLayoutAlign="center" fxLayout.lt-sm="column" fxLayoutGap="5px">
                <button fxFlex="1 1 100%" mat-flat-button color="primary" (click)='acceptInvite()'>Accept invite</button>
              </div>
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchCase='"SUCCESS"'>
            Invitation accepted successfully. Redirecting.
          </ng-container>
          <ng-container *ngSwitchCase='"SUCCESS-STREAMER"'>
            <p>
              You now have permission to stream for this club. You can download the Joymo Share App from the
              <a href="https://apps.apple.com/no/app/joymo-share/id1482394966?l=nb">Appstore</a> or scan the following code.
            </p>
            <img class="qrcode" src="/assets/joymoshare-appstore-qrcode.svg">
          </ng-container>
          <ng-container *ngSwitchCase='"ERROR"'>
            <p>An error occured while accepting the invite:</p>
            <p><i><b>{{acceptInviteResultErrorMessage}}</b></i></p>
            <p>Please contact the administrator of your club.</p>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div fxLayout='row' fxLayoutAlign="space-between end" gdArea="footer">
    <div fxFlex="1 1" class="footer-copyright">
      &copy; 2016-{{ currentDate | date:'yyyy' }} <strong>JOYMO AS</strong>
    </div>
  </div>
</div>
<ng-template #loadingAnimation>
  <yo-loading [compact]='true'></yo-loading>
</ng-template>