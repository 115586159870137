<ng-container>
  <h1>{{ currentStep == 'success' ? 'Ownership request successfully sent!' : 'Transfer Ownership' }}</h1>
  <mat-dialog-content [ngSwitch]="currentStep">
    <ng-container *ngSwitchCase="'adminSelection'" [ngTemplateOutlet]="adminSelection"></ng-container>
    <ng-container *ngSwitchCase="'success'" [ngTemplateOutlet]="success"></ng-container>
    <ng-container *ngSwitchCase="'contactSupport'" [ngTemplateOutlet]="contactSupport"></ng-container>
    <ng-container *ngSwitchCase="'spinner'" [ngTemplateOutlet]="spinner"></ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="false" *ngIf="['adminSelection', 'contactSupport'].includes(currentStep)">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="admins.invalid || loading || clubAdmins.length == 0" (click)="confirmTransfer()" *ngIf="currentStep =='adminSelection'">Confirm Transfer</button>
    <button mat-button [mat-dialog-close]="true" color="primary" *ngIf="currentStep == 'success'">Ok</button>
  </mat-dialog-actions>
</ng-container>


<ng-template #adminSelection>
  <div class="transfer-field">
    <div class="info">
      <p>You are attempting to transfer ownership of this channel to an admin within your channel. What does it mean?</p>
      <ul>
        <li>The transfer process will be complete only after your request is accepted and a new payment method is added for the channel’s subscription.</li>
        <li>The current payment method will remain active until {{ data.subscription.providerData.currentPeriodEnd.toDate() }}</li>
        <li>You will no longer be the owner and will automatically become an admin. </li>
      </ul>
    </div>
    <form [formGroup]="adminsForm">
      <p>Choose an admin to transfer ownership to:</p>
      <mat-form-field *ngIf="clubAdmins.length > 0; else noAdmins">
        <mat-label>Admins</mat-label>
        <input type="text" required placeholder="Select" aria-label="Admins" matInput [formControl]="admins"
          [matAutocomplete]="auto">
        <mat-error *ngIf="admins.errors?.notAdmin">Them email you entered does not match any of the club administrators.</mat-error>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let admin of clubAdmins" [value]="admin.email">
            {{admin.displayName ? admin.displayName: admin.email}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <ng-template #noAdmins>
        <p class="no-admins" *ngIf="club">
          This channel does not have any admin to choose for the transfer, please <a [mat-dialog-close]="false" [routerLink]="['/manage', 'clubs', club.docId]">add an admin</a> before trying again.
        </p>
      </ng-template>
    </form>
  </div>
</ng-template>

<ng-template #success>
  <p>Your request to transfer ownership has been sent to {{ admins.value }}.<br>Until the request has been accepted, you are still the owner.</p>
</ng-template>

<ng-template #contactSupport>
  <div class="contact-support">
    <p>{{ error.message }}</p>
    <p>Please contact <a href="mailto:support@joymo.tv">support@joymo.tv</a> and copy/paste the following in your message so they can help you as fast as possible.</p>
    <div class="extract">
      <!-- <button mat-icon-button class="copy-button">
        <mat-icon>content_copy</mat-icon>
      </button> -->
      <pre>{{ error | json }}</pre>
    </div>
  </div>
</ng-template>

<ng-template #spinner>
  <yo-loading compact="true"></yo-loading>
</ng-template>