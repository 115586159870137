<form *ngIf="!loading; else loadingAnimation"
      [formGroup]="resetPasswordForm"
      (ngSubmit)="resetPassword()"
      class='yo-theme mat-typography'
      fxLayout="column" fxLayoutAlign="stretch">
  <ng-container *ngIf="mode=='PASSWORD_RESET'">
    <h1>Choose a new password</h1>
    <span class="mat-subheading-1">Enter your new password below</span>
  </ng-container>
  <ng-container *ngIf="mode=='FINISH_PREREGISTRATION'">
    <h1>Almost there!</h1>
    <span class="mat-subheading-1">Please fill in the form to finalize your registration</span>
  </ng-container>
  <mat-form-field>
    <input matInput type="password" placeholder="New password" required formControlName="newPassword">
  </mat-form-field>
  <mat-form-field>
    <input matInput type="password" placeholder="Confirm password" required formControlName="confirmPassword">
  </mat-form-field>
  <!--<input yoInput type="password"  formControlName="newPassword">-->

  <div *ngIf='errorMessage' class="error-message" ngClass.xs="xs">{{errorMessage}}</div>
  <button mat-flat-button color="primary"> {{ resetButtonText }} </button>
</form>

<ng-template #loadingAnimation>
  <yo-loading [compact]='true'></yo-loading>
</ng-template>
