<h1 mat-dialog-title>Upload an Image</h1>
<mat-dialog-content class="yo-theme">

  <section class="upload-form">
    <div class="uploader">
      <p>We only accept image files that are in PNG or JPEG format and less than 1 MB in size.</p>
      <input type="file" (change)="fileChangeEvent($event)" accept="image/png, image/jpeg, .png, .jpeg" />

      <ng-container *ngIf="files && files.length > 0">
        <ng-container *ngIf="(acceptedFiletype && acceptedFilesize); else fileError">
          <div class="container">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1"
              format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
            </image-cropper>

            <div class="previews">
              <h3 class="cropped-image">Image Previews</h3>

              <div class="examples">
                <div class="logo">
                  <img [src]="croppedImage" />
                </div>
                <!-- <div class="poster">
                <img [src]="croppedImage" />
              </div> -->
                <div class="badge">
                  <img [src]="croppedImage" />
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #fileError>
          <div class="warn">
            <mat-icon>dangerous</mat-icon>
            <span>The file you selected is either not supported or too large, please use .png or .jpeg images smaller
              than 1 MB only.</span>
          </div>
        </ng-template>
      </ng-container>

    </div>

    <div class="status" *ngIf="(uploadTaskStatus | async)">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>


  </section>
</mat-dialog-content>
<div mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close>Close</button>
  <button mat-button mat-raised-button color="primary" [disabled]="!files || !acceptedFiletype || !acceptedFilesize"
    (click)="uploadCroppedImage()">
    Upload
  </button>
</div>