import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject, combineLatest, of } from "rxjs";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { EventCardComponentInput, EventCardConfig } from "src/app/yoimo-ui/cards/event/event-card.component";
import { PagedQueryCommand, PagingState, QueryWhereParameter } from "src/app/core/model/collection";
import { map, switchMap, takeUntil, tap } from "rxjs/operators";

import { ClientService } from "src/app/core/utility/client.service";
import { ClubPublic } from "src/app/core/model/interfaces/club-public";
import { Livestream } from "src/app/core/model/interfaces/livestream";
import { LivestreamsService } from "src/app/core/model/services/livestreams.service";
import { PaymentsService } from "src/app/core/model/services/payments.service";
import { TeamPublic } from "src/app/core/model/interfaces/teamPublic";
import { TeamsPublicService } from "src/app/core/model/services/teams-public.service";
import { merge } from "rxjs"

type ACTIVE_LINK = 'upcoming' | 'past';

@Component({
  selector: 'yo-channel-streams',
  templateUrl: "./channel-streams.component.html",
  styleUrls: ['./channel-streams.component.scss']
})
export class ChannelStreamsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  activeLink: ACTIVE_LINK = 'upcoming';
  canLoadMore = true;
  club: ClubPublic;
  elements: EventCardComponentInput[] = [];
  streamSource: BehaviorSubject<Livestream[]> = new BehaviorSubject([]);
  streams$ = this.streamSource.asObservable();
  loadMore: Subject<PagedQueryCommand> = new Subject();
  isLoading = true;
  isMobileView = false;
  selectedTeamSource: BehaviorSubject<string> = new BehaviorSubject(undefined);
  selectedTeam$ = this.selectedTeamSource.asObservable();
  selectedTeamId: string = undefined;
  teams: TeamPublic[];

  cardConfig: Partial<EventCardConfig> = {
    appearance: 'stream',
    showCountdown: true,
    trackEventStatus: true,
  };

  get QUERY_PAGE_SIZE(): number { return 25; };
  set QUERY_PAGE_SIZE(value: number) {
    console.log("This field should not be changed", this.QUERY_PAGE_SIZE);
  }

  constructor(
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private publicTeamService: TeamsPublicService,
    private livestreamService: LivestreamsService,
    private paymentService: PaymentsService
  ) { }

  get sortDirection() {
    return this.activeLink === "upcoming" ? "asc" : "desc";
  }

  ngOnInit(): void {
    this.club = this.route.snapshot.data.club;
    const qParamsMap$ = this.route.queryParamMap;
    this.isMobileView = this.clientService.isMobileDevice();

    qParamsMap$
      .pipe(
        switchMap((qParamsMap) => {
          this.activeLink = this.activeLink =
            (qParamsMap.get("time") as ACTIVE_LINK) || "upcoming";

          if (qParamsMap.get("team")) {
            this.selectedTeamId = qParamsMap.get("team");
            this.selectedTeamSource.next(this.selectedTeamId);
          }

          this.elements = [];

          // console.log(this.selectedTeamId);
          return this.publicTeamService.getTeamsByClubDocId(this.club.docId);
        }),
        switchMap((teams) => {
          this.teams = teams;
          return this.listenToParameters();
        }),
        switchMap((qWheres) => this.fetchStreams(qWheres)),
        map(({ data, paging }) => {

          if (this.needToAutoLoadMore(data, paging)) {
            this.loadMore.next("next");
          }

          return this.livestreamService.sortByStartTime(
            data.filter((s) => !s.archived),
            this.sortDirection
          );
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        (streams) => this.streamsFetched(streams),
        (err) => console.error("An error occured while fetching streams", err)
      );

  }

  needToAutoLoadMore(data: Livestream[], paging: PagingState): boolean {
    this.canLoadMore = paging.canLoadNext;
    const { length: notArchivedCount } = data.filter(s => !s.archived);
    const needMoreEvents = notArchivedCount <= this.elements.length || notArchivedCount <= this.QUERY_PAGE_SIZE;
    return needMoreEvents && this.canLoadMore;
  }

  navigateTo(key: string, slug: string) {
    this.router.navigate([], {
      queryParams: {
        [key]: slug
      },
      queryParamsHandling: "merge",
      relativeTo: this.route,
      replaceUrl: true
    });
  }

  listenToParameters(): Observable<QueryWhereParameter[]> {
    return combineLatest([of(this.activeLink), this.selectedTeam$]).pipe(
      map(([time, team]) => {
        let qWheres: QueryWhereParameter[] = [];

        this.cardConfig.showCountdown = this.cardConfig.trackEventStatus = time == "upcoming";

        if (time && time !== null) {
          qWheres.push([
            "scheduledStopTime",
            time == 'upcoming' ? '>' : '<',
            new Date()
          ]);
        }

        if (team && team !== null) {
          qWheres.push([
            "teamDocId",
            "==",
            team
          ]);
        }

        this.selectedTeamId = team;

        qWheres.push(["clubDocId", "==", this.club.docId]);
        qWheres.push(['isPublic', '==', true]);

        return qWheres;
      }),
      takeUntil(this.ngUnsubscribe)
    );
  }

  fetchStreams(qWheres: QueryWhereParameter[]) {
    return this.livestreamService.pagedQuery(
      this.QUERY_PAGE_SIZE,
      merge(of("reset"), this.loadMore.pipe(tap(() => this.isLoading = true))),
      qWheres,
      [{ field: "scheduledStopTime", sort: this.sortDirection }]
    );
  }

  streamsFetched(streams: Livestream[]) {
    // console.group("Channel Streams");
    // console.log("streams", streams);
    // console.log("active link", this.activeLink);
    // console.log("selectedTeam", this.selectedTeamId);
    // console.groupEnd();
    const streamElements = streams.map(stream => ({ ...this.paymentService.fromStreamToEventCardInput(stream), subtitle: stream.teamName }));
    this.streamSource.next(streams);
    this.elements = streamElements;
    this.cardConfig.showCountdown = this.cardConfig.trackEventStatus =
      this.activeLink == "upcoming";
    this.isLoading = false;
  }

  subchannelSelected(teamId) {
    this.selectedTeamSource.next(teamId);
    this.navigateTo("team", teamId);
  }

  trackElements(index: number, element: Object & { docId: string }): string | null {
    // console.log(element);
    return element.docId || null;
  }

  handleFinishedEvent(eventId: string) {
    // console.group("Event finished");
    console.log(eventId);
    console.groupEnd();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
