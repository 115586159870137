import { Callable, Collection } from '../collection';
import { Injectable, Injector } from '@angular/core';
import {
  Subscription,
  SubscriptionChannelDetails,
  SubscriptionContactDetails
} from '../interfaces/subscription';
import { first, map, switchMap } from 'rxjs/operators';

import { AuthService } from '../../auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../interfaces/user';

export interface UpdateSubscriptionRequest {
  userId: string;
  clubId: string;
  subscriptionId: string;
  newSubscriptionPlanId: string;
  checkRestrictionOnly?: boolean;
  reason?: string;
}

export interface UpdateSubscriptionDeniedResponse {
  members: {
    newValue: number,
    oldValue: number
  } | null;
  storage: {
    newValue: number,
    oldValue: number
  } | null;
  concurrentLivestreams: ConcurrentLivestreamSet | null;
}

export function isUpdateSubscriptionDeniedResponse(obj): obj is UpdateSubscriptionDeniedResponse {
  return 'members' in obj;
}
export function isUpdateSubscriptionSuccessResponse(obj): obj is UpdateSubscriptionSuccessResponse {
  return 'invoice' in obj;
}

export function isUpdateSubscriptionCheckSuccessResponse(obj): obj is UpdateSubscriptionCheckResponse {
  return 'status' in obj;
}

export function isHttpErrorResponse(obj): obj is HttpErrorResponse {
  return 'error' in obj;
}

export interface UpdateSubscriptionCheckSuccessResponse {
  status: "OK";
  subscriptionUpdateType: "upgrade" | "downgrade";
}

export interface UpdateSubscriptionSuccessResponse {
  status: "OK";
  invoice: {
    tax: number,
    total: number,
    next_payment_attempt: number
  }
}

export type UpdateSubscriptionResponse =
  | UpdateSubscriptionDeniedResponse
  | UpdateSubscriptionSuccessResponse
  | UpdateSubscriptionCheckResponse |
  HttpErrorResponse;

export type UpdateSubscriptionCheckResponse = UpdateSubscriptionDeniedResponse | UpdateSubscriptionCheckSuccessResponse | HttpErrorResponse;

export interface ConcurrentLivestreamSet {
  [index: number]: ConcurrentLivestream;
}

export interface ConcurrentLivestream {
  livestreamIds: string[];
  livestreamNames?: string[];
  startWindow: number; // timestamp
  endWindow: number; // timestamp
}

export interface SetDowngradeReasonRequest {
  userId: string;
  clubId: string;
  subscriptionId: string;
  reason: string;
}

@Injectable({
  providedIn: "root",
})
export class SubscriptionService extends Collection<Subscription> {

  public updateSubscriptionCallable: Callable<UpdateSubscriptionRequest, UpdateSubscriptionResponse>;
  public setDowngradeReason: Callable<SetDowngradeReasonRequest, true | HttpErrorResponse>;

  constructor(
    injector: Injector,
    protected authService: AuthService,
  ) {
    super(injector, 'subscriptions', true);
    this.updateSubscriptionCallable = this.declareCallable<SubscriptionService['updateSubscriptionCallable']>('subscriptions-updateSubscription');
    this.setDowngradeReason = this.declareCallable<SubscriptionService['setDowngradeReason']>('subscriptions-setDowngradeReason');
  }

  /**
   * Initiate a subscription and returns an observable on that subscription.
   * @param subscription Subscription object that will initiate the whole process
   */
  initiateSubscription(
    planId: string,
    selectedPlanOption: string,
    paymentMethodId: string,
    couponCode: string | undefined,
    channelDetails: SubscriptionChannelDetails,
    contactDetails: SubscriptionContactDetails,
    heardFrom?: string,
    heardFromDescription?: string,
  ): Observable<Subscription> {
    return this.authService.authenticatedUser$.pipe(
      first(),
      map<User, Subscription>(user => ({
        provider: 'STRIPE',
        planId,
        selectedPlanOption,
        providerData: {
          paymentMethodId,
          subscriptionType: 'STRIPE',
          couponCode: couponCode || '',
        },
        status: 'INITIATED',
        userDocId: user.docId,
        channelDetails,
        heardFrom,
        heardFromDescription,
        contactDetails
      })),
      switchMap(subscription => this.createAndListen(subscription))
    );
  }

  /**
   * Returns an observable to retrieve all the user's Subscriptions.
   * @param userDocId User's document id.
   */
  getSubscriptionsForUser(userDocId: String): Observable<Subscription[]> {
    return this.query([
      ['userDocId', '==', userDocId],
      ['status', 'in', [
        'ACTIVE',
        'TERMINATED',
        'SUSPENDED',
      ]],
    ]);
  }

  /*setDowngradeReason(request: SetDowngradeReasonRequest): Observable<true | HttpErrorResponse> {
    const callable = this.fns.httpsCallable('subscriptions-setDowngradeReason');
    return callable(request);
  }*/

  updateSubscription(requestData: UpdateSubscriptionRequest): Observable<UpdateSubscriptionResponse>;
  updateSubscription(requestData: UpdateSubscriptionRequest, checkOnly: true): Observable<UpdateSubscriptionCheckResponse>;
  public updateSubscription(requestData: UpdateSubscriptionRequest, checkOnly = false)  {
    if (checkOnly) {
      return this.updateSubscriptionCallable({...requestData, checkRestrictionOnly: true}) as Observable<UpdateSubscriptionCheckResponse>;
    } else {
      return this.updateSubscriptionCallable({...requestData, checkRestrictionOnly: false});
    }
  }
}
