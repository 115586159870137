import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClientService } from 'src/app/core/utility/client.service';
import {
  UploadService,
  UploadType,
  UploadTaskStatus,
} from 'src/app/core/utility/upload.service';
export const ACCEPTED_FILE_TYPES = ['image/png', 'image/jpeg'];
export const ACCEPTED_FILE_SIZE = 1 * 1024 * 1024; // Less than 1 MB;

export interface UploadFormDialogData {
  docId: string;
  name: string;
  path: string;
  type: UploadType;
  userDocId: string;
}

@Component({
  selector: 'yo-upload-form',
  templateUrl: './upload-form-dialog.component.html',
  styleUrls: ['./upload-form-dialog.component.scss']
})
export class UploadFormDialogComponent implements OnInit {

  acceptedFilesize;
  acceptedFiletype = false;
  files: FileList;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isMobileView = false;
  uploadTaskStatus: Observable<UploadTaskStatus>;

  constructor(
    public dialogRef: MatDialogRef<UploadFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UploadFormDialogData,
    private clientService: ClientService,
    private uploadService: UploadService
  ) { }

  ngOnInit() {
    this.isMobileView = this.clientService.isMobileDevice();
  }

  fileChangeEvent(event): void {
    this.imageChangedEvent = event;
    this.files = event.target.files;
    this.acceptedFilesize = this.files.item(0).size <= ACCEPTED_FILE_SIZE;
    this.acceptedFiletype =  ACCEPTED_FILE_TYPES.includes(this.files.item(0).type);
  }

  /**
   * Triggered every time peple stop performing actions on the picture.
   * @param event 
   */
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  uploadCroppedImage() {
    const originalFile: File = this.files.item(0);
    const originalFileNameSplitted = originalFile.name.split('.');
    const originalFileExtension = originalFileNameSplitted[originalFileNameSplitted.length - 1];
    const fileName = [this.data.type.slice(0, -1), originalFileExtension].join('.');
    this.uploadTaskStatus = this.uploadService.uploadBase64Uri(
      this.croppedImage,
      fileName,
      this.data.path,
      {
        contentType: originalFile.type,
        customMetadata: {
          docId: this.data.docId,
          uploadedBy: this.data.userDocId
        }
      }
    ).pipe(
      tap(uploadStatus => {
        if (uploadStatus.status === 'FINISHED') {
          this.dialogRef.close(uploadStatus.url);
        }
      })
    );
  }

}
