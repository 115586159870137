<section class="arena">
  <yo-arena-header [activeLink]="activeLink" [hasManageAccess]="hasManageAccess" (onToggleSideNav)="sidenav.toggle()" (onToggleLogin)="toggleLogin()"></yo-arena-header>
  <mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation" [autoFocus]="false">
      <mat-nav-list>
        <a mat-list-item  routerLink="/browse" (click)="sidenav.close()" [class.active]="activeLink === 'browse'">
          <mat-icon color="primary">home</mat-icon> <span class="nav-caption top-level">Browse</span>
        </a>
        <mat-nav-list class="sub-navigation">
            <a mat-list-item routerLink="/browse/streams" (click)="sidenav.close()" [class.active]="activeLink.startsWith('browse-streams')">
              <span class="nav-caption">Streams</span>
            </a>
            <a mat-list-item routerLink="/browse/collections" (click)="sidenav.close()" [class.active]="activeLink.startsWith('browse-collections')">
              <span class="nav-caption">Playlists</span>
            </a>
            <a mat-list-item routerLink="/browse/clubs" (click)="sidenav.close()" [class.active]="activeLink.startsWith('browse-clubs')">
              <span class="nav-caption">Channels</span>
            </a>
        </mat-nav-list>
        <a class="top-margin" mat-list-item *ngIf="hasManageAccess" routerLink="/manage/streams" (click)="sidenav.close()" [class.active]="activeLink.endsWith('manage-streams')">
          <mat-icon color="primary">video_library</mat-icon> <span class="nav-caption top-level">My Streams</span>
        </a>
        <a class="top-margin" mat-list-item *ngIf="hasManageAccess" routerLink="/manage/streams/new" (click)="sidenav.close()" [class.active]="activeLink.startsWith('manage-streams-new')">
          <mat-icon color="primary">videocam</mat-icon> <span class="nav-caption top-level">Create A Stream</span>
        </a>
        <a class="top-margin" mat-list-item *ngIf="hasManageAccess" routerLink="/manage" (click)="sidenav.close()" [class.active]="activeLink.endsWith('manage')">
          <mat-icon color="primary">insert_chart_outlined</mat-icon> <span class="nav-caption top-level">Dashboard</span>
        </a>
        
        <!-- <mat-nav-list *ngIf="hasManageAccess" class="sub-navigation">
            <a mat-list-item routerLink="/manage/streams" (click)="sidenav.close()"  [class.active]="activeLink.startsWith('manage-streams')">
              <span class="nav-caption">Streams</span>
            </a>
            <a mat-list-item routerLink="/manage/teams" (click)="sidenav.close()" [class.active]="activeLink.startsWith('manage-teams')">
              <span class="nav-caption">Teams</span>
            </a>
            <a mat-list-item routerLink="/manage/clubs" (click)="sidenav.close()" [class.active]="activeLink.startsWith('manage-clubs')">
              <span class="nav-caption">Clubs</span>
            </a>
            <a mat-list-item routerLink="/manage/recordings" (click)="sidenav.close()" [class.active]="activeLink.startsWith('manage-recordings')">
              <span class="nav-caption">Recordings</span>
            </a>
        </mat-nav-list> -->
        <a class="top-margin" mat-list-item  routerLink="/browse/tickets" [class.active]="activeLink.startsWith('/browse/tickets')" (click)="sidenav.close()">
          <mat-icon color="primary">confirmation_number</mat-icon><span class="nav-caption top-level">My Tickets</span>
        </a>
        <a class="top-margin" mat-list-item  routerLink="/account" [class.active]="activeLink.startsWith('account')" (click)="sidenav.close()">
          <mat-icon color="primary">person</mat-icon><span class="nav-caption top-level">My Account</span>
        </a>
        <a class="top-margin" mat-list-item (click)="toggleLogin()">
          <mat-icon color="primary">{{ userLogged ? 'exit_to_app' : 'login' }}</mat-icon> <span class="nav-caption top-level">{{ userLogged ? 'Sign Out ' : 'Sign In ' }}</span>
        </a>
        <div class="full-width sticky-bottom" fxLayout="column">
          <a mat-list-item class="centered" target="_blank" href="https://help.joymo.tv/knowledge">Need Help?</a>
          <a mat-list-item class="centered" onClick="UC_UI.showSecondLayer();">Privacy Settings</a>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <main class="content" #scrollContainer>
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</section>