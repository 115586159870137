<div data-test="card-capture-container" fxLayout="column" fxLayoutAlign="center center"fxLayoutGap="1em" [class.hidden]="ticketOption.price===0">
  <p data-test="product-description">{{ticketOption.description}}</p>
  <span data-test="product-price">{{ticketOption.formattedPrice}}</span>
  <p fxFlexAlign="start">Your card:</p>
  <ngx-stripe-card class="card-element" fxFlexAlign="stretch" class="stripe-card-element" [options]="cardOptions">
  </ngx-stripe-card>
  <div fxLayoutGap="1em" fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-button data-test="submit-payment" [disabled]="processingCard" color="primary" mat-raised-button (click)="createToken()">Buy ticket</button>
    </div>
    <span class="payment-service-text">Safe and secure checkout</span>
    <img class="payment-service-badge" src="/assets/stripe-banner.png">
  </div>
</div>
