import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'yo-new-stream-button',
  templateUrl: './new-stream-button.component.html',
  styleUrls: ['./new-stream-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewStreamButtonComponent implements OnInit {

  @Input('mobile') mobileView = false;  

  constructor() { }

  ngOnInit(): void {
  }

}
