import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { takeUntil, tap } from 'rxjs/operators';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/core/model/interfaces/user';

export interface UserData {
  docId: string;
  email: string;
  expiracyDate?: string;
  intent?: string;
  name?: string;
  pictureUrl?: string;
  status?: string;
}

export type ValidTabs =
  | 'admin'
  | 'trainer'
  | 'streamer'
  | 'member'
  | 'invite';


@Component({
  selector: "yo-users-table",
  templateUrl: "./users-table.component.html",
  styleUrls: ["./users-table.component.scss"]
})
export class UsersTableComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  @Input() activeTab: ValidTabs;
  @Input() authenticatedUser: User;
  @Input() klass: string = '';
  @Input() displayedColumns: string[];
  @Input() showAdmin: boolean;
  @Input() showTrainer: boolean;
  @Input() showStreamer: boolean;
  @Input() showMember: boolean;
  @Input() showInvite: boolean;
  @Input() userSource: Observable<UserData[]>;

  @Output() onTabSelected = new EventEmitter<ValidTabs>();
  @Output() selectionChanged = new EventEmitter<
    SelectionChange<Partial<UserData>>
  >();
  @Output() onRemoveClicked = new EventEmitter<boolean>();

  @ViewChild("usersPaginator", {static: true}) usersPaginator: MatPaginator;

  selectedUser: UserData;
  selectedTab: number = 0;
  selection: SelectionModel<Partial<UserData>>;
  userDataSource: MatTableDataSource<UserData>;
  userLength: number = 0;
  users: UserData[];

  constructor() {}

  ngOnInit() {
    console.group("User Table Init");

    this.userDataSource = new MatTableDataSource([]);
    this.userDataSource.paginator = this.usersPaginator;
    this.selection = new SelectionModel(true, []);
    this.selection.changed
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(selection => {
        this.selectionChanged.emit(selection);
      });
    this.users = [];

    console.log("Paginator at init", this.usersPaginator);

    this.userSource.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      users => {
        console.group("Users table - user source triggered");
        console.log("Users", this.users);
        console.log("received users", users);
        console.log("Paginator is ", this.usersPaginator);
        this.users = users || [];
        this.userDataSource.data = this.users;
        this.userDataSource.paginator = this.usersPaginator;
        this.selection.clear();
        console.groupEnd();
      },
      err => console.error(err)
    );

    console.groupEnd();
  }

  applyFilter(filterValue: string): void {
    this.userDataSource.filter = filterValue.trim().toLowerCase();
  }

  /** The label for the checkbox on the passed user */
  checkboxLabel(user?: Partial<UserData>): string {
    if (!user) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${
      this.selection.isSelected(user) ? "deselect" : "select"
    } user ${user.docId + 1}`;
  }

  /** Whether the number of selected elements matches the total number of users. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numUsers = this.userDataSource.data.length;
    return numSelected === numUsers;
  }

  /** Selects all users if they are not all selected; otherwise clear selection. */
  masterToggle() {
    //console.log("Master selection", this.isAllSelected());
    this.isAllSelected()
      ? this.selection.clear()
      : this.userDataSource.data.forEach(user => this.selection.select(user));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onTabNavSelect(selectedTabIndex: number, activeTab: ValidTabs): void {
    console.group("Selecting tab");
    console.log("Selected tab index", selectedTabIndex);
    console.log("Selected active tab", activeTab);
    console.log("Current active tab", this.activeTab);
    console.groupEnd();
    this.onTabSelected.emit(activeTab);
  }

  onRemoveClick() {
    this.onRemoveClicked.emit(true);
  }
}
