import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { AnalyticsService } from "src/app/core/model/services/analytics.service";
import { ClientService } from "src/app/core/utility/client.service";
import { sortArrayOfObjects } from "src/app/core/utility/utils";
import { AutoComplete, Completion } from "./search.model";
import { SearchService } from "../core/model/services/search.service";
import { Subject } from "rxjs";

@Component({
  selector: "yo-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent {
  private ngUnsubscribe: Subject<any> = new Subject();
  @Output() goBack = new EventEmitter();
  searchControl: FormControl = new FormControl({
    text: "",
  });
  searchText = "";
  prompt = "test";
  suggestions = [];
  prevSearches = [];

  showSuggestions = false;

  suggestionsTitleMap = {
    club: "channels",
    "livestream-group": "playlists", // Future rename to Events
  };

  routeMap = {
    club: `clubs`,
    "livestream-group": `collections`,
  };
  isMobileView = false;
  constructor(
    private searchService: SearchService,
    private router: Router,
    private analytics: AnalyticsService,
    private clientService: ClientService
  ) {
    this.isMobileView = this.clientService.isMobileDevice();

    this.searchControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap((searchText) => {
          //  Use either the suggested option or the search term typed by the user
          this.searchText =
            searchText.text !== undefined ? searchText.text : searchText;
          this.suggestions = [];
          this.prompt = "";
        }),
        filter((_) => this.searchText.length >= 3),
        switchMap((searchText) =>
          this.searchService.getSuggestions(encodeURIComponent(this.searchText))
        )
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: AutoComplete[]) => {
        this.showSuggestions = true;
        this.prompt = this.getPrompt(data);

        this.suggestions = this.getSuggestions(data);
      });
  }

  /**
   * Function that maps an option's control value to its display value in the trigger.
   * @returns text field from option object.
   */
  getDisplayText = (val: Completion) => {
    return val?.text;
  };

  /**
   * Determines term in 'Did you mean <term>?'
   * @param data - list of categories(channels,events) each with a list of terms that match typed search text.
   * @returns term that matches search text with highest score if any.
   */
  getPrompt(data: AutoComplete[]) {
    let terms = data
      .filter((category) => category.terms.length) // Filter only entities containing terms response.
      .map((category) => category.terms[0]); // Pick the term with highest score in each entity.

    if (!terms.length) {
      return "";
    }
    if (terms.length > 1) {
      terms = sortArrayOfObjects(terms, "score", "desc"); // To pick term with highest score across entities.
    }
    return terms[0].text;
  }

  getSuggestions(data: AutoComplete[]) {
    return data
      .filter((category) => category.completions.length)
      .map((category) => ({
        name: this.suggestionsTitleMap[category.completions[0].type],
        items: category.completions,
      }));
  }

  onSearch() {
    this.showSuggestions = false;
    const searchObj = this.searchControl.value;

    //  Use either the suggested option or the search term typed by the user.
    const searchText =
      searchObj.text !== undefined ? searchObj.text : searchObj;

    if (searchText.length < 3) {
      return;
    }

    this.analytics.search(searchText);
    if (searchObj.type && searchObj.id) {
      this.searchControl.setValue({ text: searchText });
      this.router.navigateByUrl(
        `/browse/${this.routeMap[searchObj.type]}/${searchObj.id}`
      );
    } else {
      this.router.navigate([`/search`], {
        queryParams: { searchText: encodeURIComponent(searchText) },
      });
    }

    if (this.isMobileView) {
      this.handleBackClick();
    }
  }

  clearSearch() {
    this.searchControl.setValue({
      text: "",
    });
    this.showSuggestions = false;
  }

  handleBackClick() {
    this.clearSearch();
    this.goBack.emit();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
