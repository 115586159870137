import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentCaptureHostDirective } from './model/services/payments.service';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import {
  getAnalytics,
  provideAnalytics,
} from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from 'src/environments/environment';

import { GlobalErrorHandler } from './utility/global-error-handler';

@NgModule({
  declarations: [
    PaymentCaptureHostDirective,
  ],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() =>  getFirestore()),
    provideAuth(() => getAuth() ),
    provideFunctions(() => getFunctions() ),
    provideStorage(() => getStorage() ),
    provideAnalytics(() => getAnalytics()),
    CommonModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  exports: [
    PaymentCaptureHostDirective,
  ]
})
export class CoreModule {

  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    /*if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }*/
  }

 }
