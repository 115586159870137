import 'hammerjs';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import {
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig
} from '@angular/platform-browser';
import { NgModule, OnInit } from '@angular/core';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ArenaComponent } from './arena/arena.component';
import { ArenaHeaderComponent } from './arena/arena-header/arena-header.component';
import { AuthModule } from './auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ChannelModule } from './channel/channel.module';
import { CheckoutModule } from './checkout/checkout.module';
import { CoreModule } from './core/core.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ICONS } from '../icons';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxStripeModule } from 'ngx-stripe';
import { YoimoUiModule } from './yoimo-ui/yoimo-ui.module';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { SearchModule } from './search/search.module';

@NgModule({
  declarations: [AppComponent, ArenaComponent, ArenaHeaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule,
    FlexLayoutModule,
    FontAwesomeModule,
    GoogleTagManagerModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatTabsModule,
    MatToolbarModule,
    YoimoUiModule,
    CheckoutModule,
    CoreModule,
    NgxStripeModule.forRoot(environment.stripe.publicKey),
    ChannelModule,
    HttpClientModule,
    SearchModule
  ],
  providers: [
    //GoogleTagManagerService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: 'googleTagManagerId', useValue: environment.google.gtmId },
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule implements OnInit {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(...ICONS);
  }
  ngOnInit(): void {
    // firebase.analytics();
  }
}
