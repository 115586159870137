import { BackgroundColorDirective } from './directives/background-color.directive';
import { BottomnavComponent } from './bottomnav/bottomnav.component';
import { ChannelCardComponent } from './cards/channel/channel-card.component';
import { ColorDirective } from './directives/color.directive';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmPaymentDialogComponent } from './confirm-payment-dialog/confirm-payment-dialog.component';
import { CountdownComponent } from './countdown/countdown.component';
import { DialogSkeletonComponent } from './dialog-skeleton/dialog-skeleton.component';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { EmbedCodeGeneratorComponent } from './embed-code-generator/embed-code-generator.component';
import { EventCardComponent } from './cards/event/event-card.component';
import { EventDateComponent } from './cards/event/event-date/event-date.component';
import { FileUploadButtonComponent } from './file-upload-button/file-upload-button.component';
import { FlatButtonDirective } from './directives/flat-button.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { Grid2Component } from './grids/grid/grid2.component';
import { GridComponent } from './grids/grid.component';
import { HeaderSkeletonComponent } from './header-skeleton/header-skeleton.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InputDirective } from './directives/input.directive';
import { LabelDirective } from './directives/label.directive';
import { LastDirective } from './directives/last.directive';
import { LivestreamGroupCardComponent } from './livestream-group-card/livestream-group-card.component';
import { LoadingComponent } from './loading/loading.component';
import { LogoComponent } from './logo/logo.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NewStreamButtonComponent } from './new-stream-button/new-stream-button.component';
import { NgModule } from '@angular/core';
import { NgxStripeModule } from 'ngx-stripe';
import { PlaylistsComponent } from './grids/playlists/playlists.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RtmpEndpointComponent } from './rtmp-endpoint/rtmp-endpoint-component';
import { SectionComponent } from './section/section.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { SocialDirectLinksComponent } from './social-direct-links/social-direct-links.component';
import { SocialIconsComponent } from './social-icons/social-icons.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StreamsComponent } from './grids/streams/streams.component';
import { StreamsListViewComponent } from './streams-list-view/streams-list-view.component';
import { StreamsTableComponent } from './streams-table/streams-table.component';
import { StripeSubscriptionPaymentComponent } from './stripe-subscription-payment/stripe-subscription-payment.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TransferOwnershipDialogComponent } from './subscription-dialog/transfer-ownership/transfer-ownership/transfer-ownership.component';
import { UpgradeDowngradeDialogComponent } from './subscription-dialog/upgrade-downgrade/upgrade-downgrade/upgrade-downgrade.component';
import { UploadFormDialogComponent } from './upload-form/upload-form-dialog.component';
import { UsersListComponent } from './users-list/users-list.component';
import { UsersTableComponent } from './users-table/users-table.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FlexLayoutModule,
    ImageCropperModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDividerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTableModule,
    MatTabsModule,
    MatDialogModule,
    MatToolbarModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    FormsModule,
    EcoFabSpeedDialModule,
    ReactiveFormsModule,
    NgxStripeModule,
    ShareButtonsModule,
    MatAutocompleteModule
  ],
  declarations: [
    BackgroundColorDirective,
    BottomnavComponent,
    ColorDirective,
    CountdownComponent,
    DialogSkeletonComponent,
    FileUploadButtonComponent,
    FlatButtonDirective,
    HeaderSkeletonComponent,
    InputDirective,
    LabelDirective,
    LastDirective,
    LivestreamGroupCardComponent,
    LoadingComponent,
    SidenavComponent,
    SocialDirectLinksComponent,
    SpinnerComponent,
    StreamsListViewComponent,
    StreamsTableComponent,
    UsersListComponent,
    UsersTableComponent,
    ConfirmDialogComponent,
    LogoComponent,
    TermsAndConditionsComponent,
    SnackBarComponent,
    UploadFormDialogComponent,
    StripeSubscriptionPaymentComponent,
    ConfirmPaymentDialogComponent,
    EmbedCodeGeneratorComponent,
    RtmpEndpointComponent,
    UpgradeDowngradeDialogComponent,
    TransferOwnershipDialogComponent,
    EventCardComponent,
    ChannelCardComponent,
    SectionComponent,
    StreamsComponent,
    PlaylistsComponent,
    GridComponent,
    Grid2Component,
    NewStreamButtonComponent,
    EventDateComponent,
    SocialIconsComponent
  ],
  exports: [
    BackgroundColorDirective,
    BottomnavComponent,
    ColorDirective,
    CountdownComponent,
    DialogSkeletonComponent,
    FlatButtonDirective,
    FileUploadButtonComponent,
    HeaderSkeletonComponent,
    InputDirective,
    LabelDirective,
    LastDirective,
    LivestreamGroupCardComponent,
    LoadingComponent,
    SidenavComponent,
    SnackBarComponent,
    SocialDirectLinksComponent,
    SpinnerComponent,
    StreamsListViewComponent,
    StreamsTableComponent,
    UploadFormDialogComponent,
    UsersListComponent,
    UsersTableComponent,
    TermsAndConditionsComponent,
    EmbedCodeGeneratorComponent,
    RtmpEndpointComponent,
    EventCardComponent,
    ChannelCardComponent,
    SectionComponent,
    StreamsComponent,
    PlaylistsComponent,
    NewStreamButtonComponent,
    EventDateComponent,
    SocialIconsComponent
  ],
  providers: [
  ],
})
export class YoimoUiModule {}
