import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions } from '@stripe/stripe-js';
import { switchMap, takeUntil } from 'rxjs/operators';

import { AuthService } from 'src/app/core/auth/auth.service';
import { ChangeOwnershipInvite } from 'src/app/core/model/interfaces/invite';
import { Club } from 'src/app/core/model/interfaces/club';
import {
  InvitesService
} from 'src/app/core/model/services/invites.service';
import { MatDrawer } from '@angular/material/sidenav';
import { Subject } from 'rxjs';
import { Subscription } from 'src/app/core/model/interfaces/subscription';
import { SubscriptionService } from 'src/app/core/model/services/subscription.service';
import { User } from 'src/app/core/model/interfaces/user';
import countries from 'src/app/user/subscription-form/countries-list/countries';

@Component({
  selector: 'yo-accept-ownership',
  templateUrl: './accept-ownership.component.html',
  styleUrls: ['./accept-ownership.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AcceptOwnershipComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  @Input("club") club: Club;
  @Input("invite") invite: ChangeOwnershipInvite;
  @Output("ownershipAccepted") ownershipAccepted: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('intro') intro: TemplateRef<any>;
  @ViewChild('paymentInfo') paymentInfo: TemplateRef<any>;
  @ViewChild('success') success: TemplateRef<any>;
  @ViewChild('spinner') spinner: TemplateRef<any>;
  @ViewChild('contactSupport') contactSupport: TemplateRef<any>;
  @ViewChild(MatDrawer) drawer: MatDrawer;
  @ViewChild(StripeCardComponent) stripeCard: StripeCardComponent;

  cardChanges: StripeCardElementChangeEvent;
  cardErrors: string;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#FFF',
        color: '#FFF',
        fontWeight: '300',
        fontFamily: 'sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#DDD',
        },
      },
    },
  };

  error;
  countryList = countries;
  currentStep: "intro" | "paymentInfo" | "success" | "spinner" | "contactSupport" = "intro";
  dialCode = new FormControl('', Validators.required);
  termsAndConditions = new FormControl('', Validators.required);
  stripeFormGroup: FormGroup = new FormGroup({
    nameControl: new FormControl('', [Validators.required]),
    termsAndConditions: this.termsAndConditions,
    dialCode: this.dialCode,
    phoneNumber: new FormControl('', [Validators.required]),
    stripeCardControl: new FormControl('', [Validators.required])
  });

  drawerOpened: boolean = false;
  loadingPhase:
    | 'NONE'
    | 'PROCESSING_CARD_INFO'
    | 'INITIATING_SUBSCRIPTION'
    | 'WAITING_USER_ACTION'
    | 'PROVISIONING_SERVICE'
    | 'DONE' = 'NONE';
  
  subscription: Subscription;
  user: User;

  constructor(
    protected stripeService: StripeService,
    private authService: AuthService,
    private inviteService: InvitesService,
    private subscriptionService: SubscriptionService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    console.group("Inputs");
    console.log("Club", this.club);
    console.log("Invite", this.invite);
    console.groupEnd();

    this.authService.authenticatedUser$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      user => this.user = user,
      err => console.error(err)
    )
  }

  acceptOwnership() {
    console.log("Accepted ownership – processing payment card");
  }

  goToPaymentDetails() {
    this.currentStep = "paymentInfo";
  }

  handleCardChanges(changes: StripeCardElementChangeEvent) {
    this.cardChanges = changes;
    // console.log(changes);
    this.cardErrors = changes.error ? changes.error.message : null;
    this.stripeFormGroup.get("stripeCardControl")
      .setValue(changes.complete && !this.cardErrors ? 'true' : null);
  }

  moveBack() {
    this.stripeFormGroup.reset();
    this.currentStep = "intro";
  }

  sendPaymentDetails() {
    console.log("User wants to send in payment details");
    console.log(this.stripeCard);
    console.log(this.stripeFormGroup);
    console.log(this.cardErrors);
    if (!this.stripeFormGroup.valid || this.cardErrors) { return; }


    this.stripeService.createPaymentMethod({
      type: 'card',
      card: this.stripeCard.element,
    }).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      result => {
        console.log(result);
        this.currentStep = "spinner";
        if (result.paymentMethod) {
          //console.log("Sending to UserAcceptInvite Callable", data);
          this.inviteService.acceptTransferOwnershipInvite(
            this.route.snapshot.queryParamMap.get('iid'),
            this.route.snapshot.queryParamMap.get('token'),
            result.paymentMethod.id,
            this.stripeFormGroup.get('nameControl').value,
            {
              dialCode: this.stripeFormGroup.get('dialCode').value.split(" ")[0],
              phoneNumber: this.stripeFormGroup.get('phoneNumber').value
            }
          ).pipe(
            switchMap(r => {
              // this.currentStep = "success";
              // setTimeout(() => this.router.navigate(["manage", "clubs", this.invite.clubDocId]), 2000);
              console.log("AcceptInvite Result", r);
              if (!r.success) {
                this.error = {
                  message: "Something went wrong with the invite. Contact support",
                  userId: this.user.docId,
                  clubDocId: this.club.docId,
                };
                this.currentStep = "contactSupport";
                return;
              }

              return this.subscriptionService.getByDocId(r.subscriptionId);
            }),
            takeUntil(this.ngUnsubscribe)
          ).subscribe(
            subscription => {
              // this.currentStep = "success";
              // setTimeout(() => this.router.navigate(["manage", "clubs", this.invite.clubDocId]), 2000);
              // console.log("AcceptInvite Result", result);
              console.log("Subscription", subscription);
              this.subscription = subscription;
              switch (subscription.status) {
                case 'INITIATED':
                  this.loadingPhase = 'INITIATING_SUBSCRIPTION';
                  break;
                case "NEEDS_ACTION":
                  this.loadingPhase = 'WAITING_USER_ACTION';
                  this.stripeService.stripe
                    .confirmCardPayment(
                      this.subscription.providerData.paymentIntentClientSecret
                    )
                    .subscribe((r) => {
                      console.log("Stripe Service Response", r);
                      if (r.error) {
                        this.loadingPhase = "NONE";
                        this.currentStep = "paymentInfo";
                        this.showCardProcessingError(r.error.message);
                      }
                    });
                  break;
                case "ACTIVE":
                  this.loadingPhase = "DONE";
                  this.currentStep = "success";
                  setTimeout(() => this.router.navigate(["manage", "clubs", this.invite.clubDocId]), 2000);
                  break;
                case 'CREATION_ERROR':
                  this.currentStep = "paymentInfo";
                  this.loadingPhase = 'NONE';
                  setTimeout(_ => this.showCardProcessingError(
                    /*{
                      card_declined:
                        "The card was declined, please use another card.",
                      expired_card:
                        "The card was declined because it expired, please use another card.",
                      incorrect_cvc: "CVC check error.",
                      processing_error:
                        "Error while processing payment, please retry in a moment.",
                      incorrect_number: "Incorrect card number.",
                      ""
                    }[this.subscription.providerError.code] ||
                    "An unexpected error occured please try again."*/
                    this.subscription.providerError.errorMessage
                  ), 150);
                  break;
                default:
                  console.error('Unhandled case for subscription');
                  this.error = {
                    message: "We could not handle the subscription initialisation. Something wrong happened. Contact support"
                  };
                  this.currentStep = "contactSupport";
              }
            },
            err => {
              this.error = err;
              this.currentStep = "contactSupport";
              console.error(err);
            }
          );
        }
      },
      err => {
        this.error = err;
        this.currentStep = "contactSupport";
        console.error(err);
      }
    );
  }

  showCardProcessingError(error: string) {
    this.stripeCard.element.focus();
    this.stripeCard.element.clear();
    this.loadingPhase = 'NONE';
    setTimeout(x => this.cardErrors = error, 200);
  }

  toggleDrawer() {
    this.drawerOpened = !this.drawerOpened;
    console.log(this.drawer);
    this.drawer.toggle(this.drawerOpened);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
