export const SUPPORTED_CURRENCY_FOR_CHANNEL = [
  {
    code: 'USD',
    name: '$ - USD - U.S. Dollar',
  },
  {
    code: 'EUR',
    name: '€ - EUR - Euro',
  },
  {
    code: 'GBP',
    name: '£ - GBP - Pounds',
  },
  {
    code: 'NOK',
    name: 'NOK - Norwegian Krone',
  },
  {
    code: 'SEK',
    name: 'SEK - Swedish Krone',
  },
  {
    code: 'DKK',
    name: 'DKK - Danish Krone',
  },
  {
    code: 'CAD',
    name: 'CA$ - CAD - Canadian Dollar',
  },
  {
    code: 'AUD',
    name: 'AU$ - AUD - Australian Dollar',
  },
  {
    code: 'CHF',
    name: 'CHF - Swiss franc'
  }
];


export const COUNTRY_TO_CURRENCY_MAP = {
  AU: 'AUD',
  CX: 'AUD',
  CH: 'CHF',
  CC: 'AUD',
  HM: 'AUD',
  KI: 'AUD',
  NR: 'AUD',
  NF: 'AUD',
  TV: 'AUD',
  AS: 'EUR',
  AD: 'EUR',
  AT: 'EUR',
  BE: 'EUR',
  FI: 'EUR',
  FR: 'EUR',
  GF: 'EUR',
  TF: 'EUR',
  DE: 'EUR',
  GR: 'EUR',
  GP: 'EUR',
  IE: 'EUR',
  IT: 'EUR',
  LU: 'EUR',
  MQ: 'EUR',
  YT: 'EUR',
  MC: 'EUR',
  NL: 'EUR',
  PT: 'EUR',
  RE: 'EUR',
  WS: 'EUR',
  SM: 'EUR',
  SI: 'EUR',
  ES: 'EUR',
  VA: 'EUR',
  GS: 'GBP',
  GB: 'GBP',
  JE: 'GBP',
  IO: 'USD',
  GU: 'USD',
  MH: 'USD',
  FM: 'USD',
  MP: 'USD',
  PW: 'USD',
  PR: 'USD',
  TC: 'USD',
  US: 'USD',
  UM: 'USD',
  VG: 'USD',
  VI: 'USD',
  CA: 'CAD',
  BV: 'NOK',
  NO: 'NOK',
  SJ: 'NOK',
  DK: 'DKK',
  FO: 'DKK',
  GL: 'DKK',
  SE: 'SEK',
  AX: 'EUR',
  IM: 'GBP',
  ME: 'EUR',
  BL: 'EUR',
  SH: 'GBP',
  PM: 'EUR',
};


export function formatCurrency(price, currency) {
  if (Intl && Intl.NumberFormat) {
    return new Intl.NumberFormat('en-US', {style: 'currency', currency}).format(price);
  } else {
    return currency + ' ' + price.toFixed(2);
  }
}
