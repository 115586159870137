import { Component, OnInit, Renderer2 } from "@angular/core";

import { AuthService } from "./core/auth/auth.service";
import { ClubsService } from "./core/model/services/clubs.service";
import { RoutingStateService } from "./core/utility/routing-state.service";
import { Subscription } from "rxjs";
import { environment } from '../environments/environment';

@Component({
  selector: "yo-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  userFromDatabase$: Subscription;

  constructor(
    private authService: AuthService,
    private renderer: Renderer2,
    private routingService: RoutingStateService
  ) {
    // console.log("Loading Routing State Service");
    this.routingService.loadRouting();
  }

  ngOnInit() {
    // Adds hubspot tracker lib + workaround for spams
    let hubspotCheat = this.renderer.createElement("div");
    hubspotCheat.id = `CollectedForms-${environment.hubspot.trackerId}`;
    hubspotCheat.setAttribute("style", "visibility: hidden;width: 0px;height: 0;");
    this.renderer.appendChild(document.body, hubspotCheat);

    let hubspotScript = this.renderer.createElement("script");
    hubspotScript.id = "hs-script-loader";
    hubspotScript.src = `//js.hs-scripts.com/${environment.hubspot.trackerId}.js`;
    hubspotScript.type = 'text/javascript';
    hubspotScript.async = true;
    this.renderer.appendChild(document.body, hubspotScript);
    this.authService.finalizeInitialization();
  }

}
