<yo-section
  [title]="'Playlists'"
  [sectionId]="'playlists'"
  [link]="['playlists']"
  [buttonLabel]="'See all playlists'"
  iconSrc="assets/icons/playlists_gradient.svg"
  data-header-border="true"
  data-padding="false"
  [showMore]="(collections$ | async)?.length"
>
  <yo-playlists
    *ngIf="(collections$ | async)?.length; else empty"
    [collections]="collections$"
  ></yo-playlists>
</yo-section>

<ng-template #empty>
  <yo-empty-section title="No playlists in this channel"></yo-empty-section>
</ng-template>
