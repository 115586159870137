import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AcceptInviteComponent } from './auth/accept-invite/accept-invite.component';
import { ArenaComponent } from './arena/arena.component';
import { AuthGuard } from './core/auth/auth.guard';
import { BoundAuthGuard } from './core/auth/bound-auth.guard';
import { CancelComponent } from './checkout/cancel/cancel.component';
import { CheckoutLivestreamGroupViewComponent } from './checkout/livestream-group/checkout-livestream-group-view/checkout-livestream-group-view.component';
import { LoginViewComponent } from './auth/login-view/login-view.component';
import { NgModule } from '@angular/core';
import { OperationComponent } from './operations/operation/operation.component';
import { SuccessComponent } from './checkout/success/success.component';

const routes: Routes = [
  {
    path: 'arena',
    component: ArenaComponent,
    canActivate: [AuthGuard],
    children: [
      // Home
      {
        path: 'home',
        children: [
          // {
          //   path: '',
          //   component: RelevantLivestreamsViewComponent,
          // },
          {
            path: '',
            // component: LivestreamsViewComponent,
            redirectTo: '/browse',
            pathMatch: 'full',
          },
          {
            path: 'livestream/:streamDocId',
            // component: PlayLivestreamViewComponent,
            // canActivate: [ProductGuard],
            // data: {
            //   productType: 'livestream',
            //   routingType: 'live',
            // },
            redirectTo: '/browse/streams/:streamDocId',
            pathMatch: 'full',
          },
          {
            path: 'livestream-group/:collectionDocId',
            // component: LivestreamGroupHomeComponent,
            data: {
              productType: 'livestreamGroup',
              routingType: 'live',
            },
            redirectTo: '/browse/collections/:collectionDocId',
            pathMatch: 'full',
          },
        ],
      },
      // Manage
      {
        path: 'manage',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            // component: ManageHomeComponent,
            redirectTo: '/manage',
            pathMatch: 'full',
          },
          // Scheduler
          {
            path: 'scheduler',
            children: [
              {
                path: 'new',
                // component: CreateLivestreamViewComponent,
                redirectTo: '/manage/streams/new',
                pathMatch: 'full',
              },
              {
                path: 'edit/:livestreamDocId',
                // component: UpdateLivestreamViewComponent,
                redirectTo: '/manage/streams/edit/:livestreamDocId',
                pathMatch: 'full',
              },
              {
                path: ':teamDocId',
                // component: SchedulerViewComponent,
                redirectTo: '/manage/streams/:teamDocId',
                pathMatch: 'full',
              },
              {
                path: '',
                // component: SchedulerViewComponent,
                redirectTo: '/manage/streams',
                pathMatch: 'full',
              },
            ],
          },
          // Sporties
          {
            path: 'sporties',
            // component: SportiesViewComponent,
            redirectTo: '/manage/sporties',
            pathMatch: 'full',
          },
          // Teams
          {
            path: 'teams',
            children: [
              {
                path: 'edit/:teamDocId',
                // component: TeamViewComponent,
                redirectTo: '/manage/teams/edit/:teamDocId',
                pathMatch: 'full',
              },
              {
                path: ':clubDocId',
                // component: TeamsViewComponent,
                redirectTo: '/manage/teams/:clubDocId',
                pathMatch: 'full',
              },
              {
                path: '',
                // component: TeamsViewComponent,
                redirectTo: '/manage/teams',
                pathMatch: 'full',
              },
            ],
          },
          // Clubs
          {
            path: 'clubs',
            children: [
              {
                path: '',
                // component: ClubsViewComponent,
                redirectTo: '/manage/clubs',
                pathMatch: 'full',
              },
              {
                path: 'edit/:clubDocId',
                // component: ClubViewComponent,
                redirectTo: '/manage/clubs/edit/:clubDocId',
                pathMatch: 'full',
                // TODO: We should add a guard for users that are not allowed to manage admins of a club
              },
            ],
          },
        ],
      },
      // Recordings
      {
        path: 'recordings',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            // component: RecordingsViewComponent,
            // canActivate: [AuthGuard]
            redirectTo: '/recordings',
            pathMatch: 'full',
          },
          {
            path: 'recording/:recordingDocId',
            // component: RecordingViewComponent,
            redirectTo: '/recordings/:recordingDocId',
            // TODO: Needs a RecordingGuard to check that the user has access to the livestream
          },
        ],
      },
      // Vods
      {
        path: 'vods',
        children: [
          {
            path: '',
            // component: VodsViewComponent,
            // canActivate: [AuthGuard],
            redirectTo: '/browse/streams',
            pathMatch: 'full',
          },
          {
            path: 'vod/:streamDocId',
            // component: VodViewComponent,
            // canActivate: [ProductGuard],
            // data: {
            // productType: 'livestream',
            // routingType: 'vod'
            // }
            redirectTo: '/browse/streams/:streamDocId',
            pathMatch: 'full',
          },
          {
            path: 'group/:collectionDocId',
            // component: VodGroupViewComponent
            redirectTo: '/browse/collections/:collectionDocId',
            pathMatch: 'full',
          },
        ],
      },
      // Default
      {
        path: '',
        redirectTo: '/browse',
        pathMatch: 'full',
      },
    ],
  },
  // Login
  {
    path: 'login',
    component: LoginViewComponent
  },
  {
    canActivate: [BoundAuthGuard],
    path: 'accept_invite',
    component: AcceptInviteComponent
  },
  {
    // embedable views
    path: 'v',
    loadChildren: () => import('./embed/embed.module').then(m => m.EmbedModule),
  },
  {
    path: '',
    component: ArenaComponent,
    children: [
      // Checkout
      {
        path: 'checkout',
        children: [
          // {
          //   path: 'streams/:productId',
          //   component: CheckoutLivestreamViewComponent,
          //   data: { productType: 'livestream' }
          // },
          {
            path: 'collections/:productId',
            component: CheckoutLivestreamGroupViewComponent,
            data: { productType: 'livestreamGroup' },
          },
          {
            path: 'success/:productId',
            component: SuccessComponent,
          },
          {
            path: 'cancel/:productId',
            component: CancelComponent
          }
        ],
      },
      // Browse
      {
        path: 'browse',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      },
      // Manage
      {
        path: 'manage',
        //canActivate: [AuthGuard],
        loadChildren: () => import('./manage/manage.module').then(m => m.ManageModule)
      },
      // Operations
      {
        path: 'operations/:operationDocId',
        component: OperationComponent,
      },
      // User
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
      {
        path: 'account',
        redirectTo: 'user/account',
        pathMatch: 'full',
      },
      /*{
        path: 'subscribe',
        canActivate: [AuthGuard],
        component: SubscriptionFormComponent,
        data: {
          preferedLoginMode: 'REGISTER'
        }
      },*/
      {
        path: 'subscribe',
        redirectTo: 'user/subscribe',
        pathMatch: 'full',
      },
      {
        path: 'action',
        component: LoginViewComponent,
        data: { mode: 'RESET_PASSWORD' }
      },
      //search
      {
        path:'search',
        loadChildren: ()=>import('./search/search.module').then(m=>m.SearchModule)
      },
      // Arena Old Routes
      {
        canActivate: [BoundAuthGuard],
        path: 'accept_invite',
        component: AcceptInviteComponent
      },
      // Entrypoint
      {
        path: '',
        redirectTo: 'browse',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
      enableTracing: false, // For debugging purposes only
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
