import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Event} from '@angular/router';

@Component({
  selector: 'yo-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.css']
})
export class FileUploadButtonComponent implements OnInit {
  @Input() text = 'Upload';
  @Output() upload = new EventEmitter<Event>();

  // For some reason the "id" and "for" attributes got mixed up when multiple of this component. That is why a random id.
  id = Math.random().toString(36).substring(7);

  constructor() { }

  ngOnInit() {
  }

  onChange(event) {
    this.upload.emit(event);
  }

}
