import {Component, Input, OnInit} from '@angular/core';

/*
  This component uses content projection. It has three content projection slots:
  - left-header-content
  - center-header-content
  - right-header-content
 */

@Component({
  selector: 'yo-header-skeleton',
  templateUrl: './header-skeleton.component.html',
  styleUrls: ['./header-skeleton.component.scss']
})
export class HeaderSkeletonComponent implements OnInit {
  @Input() height: string; // Remember to add type, eg. px, em, etc.
  @Input() backgroundImg: string;
  topGradientHeight = '1px';

  constructor() { }

  ngOnInit() { }

}

