<ng-container *ngIf="backgroundImg; else noImg">
    <div class="backgrounded" [style.backgroundImage]="'url(' + backgroundImg + ')'" fxLayout="row" fxLayoutAlign="space-between center" [ngStyle]="{'height': 'calc(' + height + ' - ' + topGradientHeight + ')'}">
      <ng-content select=".left-header-content.backgrounded"></ng-content>
      <ng-content select=".center-header-content.backgrounded"></ng-content>
      <ng-content select=".right-header-content.backgrounded"></ng-content>
    </div>
</ng-container>
<ng-template #noImg>
    <div fxLayout="row" fxLayoutAlign="space-between center" [ngStyle]="{'height': 'calc(' + height + ' - ' + topGradientHeight + ')'}">
      <ng-content select=".left-header-content"></ng-content>
      <ng-content select=".center-header-content"></ng-content>
      <ng-content select=".right-header-content"></ng-content>
    </div>
</ng-template>

<div class="top-gradient" [ngStyle]="{'height': topGradientHeight}"></div>
