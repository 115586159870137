import {Directive, ElementRef, Input, Renderer2, OnInit} from '@angular/core';
import {darkTheme} from '../theme/dark-theme';

@Directive({
  selector: '[yoColor]'
})
export class ColorDirective implements OnInit {
  @Input() yoColor = 'first';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'color', darkTheme.properties[this.yoColor]);
  }

}
