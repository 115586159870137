import {Callable, Collection} from '../collection';
import { Injectable, Injector } from '@angular/core';
import {switchMap, take} from 'rxjs/operators';

import {Observable} from 'rxjs';
import {Operation} from '../interfaces/operation';

@Injectable({
  providedIn: 'root'
})
export class OperationsService extends Collection<Operation> {

  public handleNextStepCallable: Callable<{operation: Operation}, any>;
  constructor(
    injector: Injector,
  ) {
    super(injector, 'operations', true);
    this.handleNextStepCallable = this.declareCallable<OperationsService['handleNextStepCallable']>('operations-handleNextStep');
  }

  /**
   * Returns the response of the callable function as an observable.
   * @param operationDocId
   */
  handleNextStep(operationDocId: string): Observable<any> {
    return this.getByDocId(operationDocId).pipe(
      take(1),
      switchMap(
        (operation: Operation) => this.handleNextStepCallable({operation: operation})
      )
    );
  }

}
