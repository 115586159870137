<section class="streams">
  <div class="content" [style.margin]="-1 / 2 + 'rem'">
    <yo-event-card
      *ngFor="
        let item of streams;
        let key = index;
        trackBy: trackElements
      "
      class="yo-theme card-holder"
      [ngClass]="{ mobile: isMobileView }"
      [style.margin]="1 / 2 + 'rem'"
      [style.flexBasis]="isMobileView ? none : 'calc(25% - 1rem)'"
      [style.maxWidth]="isMobileView ? none : 'calc(25% - 1rem)'"
      [event]="item"
      [useMobileTemplate]="isMobileView"
      [appearance]="streamCardConfig.appearance"
      [showCountdown]="streamCardConfig.showCountdown"
      [showLogo]="false"
      (eventFinished)="handleFinishedEvent(item.id)"
    >
    </yo-event-card>
  </div>
  <!-- Streams load more  -->
  <footer>
    <p *ngIf="!canLoadMore">You've reached the end of this page.</p>
    <ng-container *ngIf="canLoadMore">
      <button (click)="loadMoreDocs()" mat-stroked-button type="button">
        <ng-container *ngIf="!isLoading; else spinner">
          Load more
        </ng-container>
      </button>
    </ng-container>
    <ng-template #spinner>
      <yo-spinner></yo-spinner>
    </ng-template>
  </footer>
</section>
