<section *ngIf="club"
         [ngClass]="{'no-content': !hasStreams && !hasReplays && !hasPlaylists && !highlights.length}">
  <yo-cover [avatar]="club.imageUrl"
            [image]="club.coverImageUrl"
            [video]="club.coverVideoUrl"
            [description]="club.description"
            [isExclusive]="club.isExclusive"
            [name]="club.name"
            [clubId]="club.docId"></yo-cover>
  <yo-streams-section *ngIf="hasStreams"></yo-streams-section>
  <yo-section *ngIf="club.clips?.length"
              title="Highlights"
              sectionId="channelHighlights"
              link="streams"
              iconSrc="assets/icons/streams_gradient.svg"
              data-header-border="true"
              data-padding="false"
              [showMore]="false">
    <yo-highlights [highlights]="highlights"></yo-highlights>
  </yo-section>


  <yo-replays-section *ngIf="hasReplays"></yo-replays-section>
  <yo-playlists-section *ngIf="hasPlaylists"
                        [clubId]="clubDocId"></yo-playlists-section>

  <yo-section *ngIf="!hasStreams && !hasReplays"
              [title]="'Channel Events'"
              [sectionId]="'channelEvents'"
              [link]="['streams']"
              [linkQueryParams]="{time: 'upcoming', team: selectedTeamId}"
              iconSrc="assets/icons/streams_gradient.svg"
              data-header-border="true"
              data-padding="false"
              [showMore]="false">
    <yo-subchannel-selector class="subchannel-selector"
                            header
                            [subchannels]="teams"
                            (onSubchannelSelected)="onTeamSelected($event)"
                            [selectedSubchannel]="selectedTeamId"></yo-subchannel-selector>

    <yo-empty-section [title]="(selectedTeam ? selectedTeam : 'This channel') + ' does not have events yet.'">
    </yo-empty-section>
  </yo-section>

</section>
