import { Analytics, logEvent } from '@angular/fire/analytics';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface Item {
  value: number;
  currency: string;
  productId: string;
  // the product type: can be livestream or livestreamGroup
  productType: string;
  productName: string;
  // VOD / BUNDLE / PPV
  transactionType: string;
}

function itemToAnalytics(selectedItem: Item) {
  return {
    category: selectedItem.productType,
    item_name: selectedItem.productName,
    item_id: selectedItem.productId,
    price: selectedItem.value,
    currency: selectedItem.currency?.toUpperCase(),
  };
}
/**
 * Thin wrapper around firebase/ga analytics.
 * Implements some of the recommended metrics and other metrics
 * related to video.
 */
@Injectable({
  providedIn: "root"
})
export class AnalyticsService {
  constructor(private analyticsService: Analytics ) {
  }

  private logEvent(eventName: string, eventParams: {[k: string]: any}) {
    if (this.analyticsService) {
      //console.log("Sending analytics", eventName, eventParams);
      logEvent(this.analyticsService, eventName, eventParams);
    }
  }

  public userLoggedIn(method: string) {
    this.logEvent('login', { method });
  }

  public userSignedUp(flowLeadingToSignup: 'SPONTANEOUS' | 'AFTER_CHECKOUT') {
    this.logEvent('sign_up', {
      signup_flow: flowLeadingToSignup
    });
  }

  public screenView(screenName: string, screenParams: any) {
    this.logEvent('screen_view', {
      ...screenParams,
      app_name: "default",
      screen_name: screenName
    });
  }

  public presentProduct(
    productType: string,
    productId: string,
    productName: string
  ) {
    this.logEvent('present_offer', {
      item_category: productType,
      item_name: productName,
      item_id: productId
    });
  }

  public beginCheckout(selectedItem: Item) {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#begin_checkout
    this.logEvent('begin_checkout', {
      value: selectedItem.value,
      currency: selectedItem.currency?.toUpperCase(),
      items: [itemToAnalytics(selectedItem)],
      transaction_type: selectedItem.transactionType
    });
  }

  public addPaymentInfo(selectedItem: Item) {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_payment_info
    this.logEvent('add_payment_info', {
      value: selectedItem.value,
      currency: selectedItem.currency?.toUpperCase(),
      payment_type: 'Card',
      transaction_type: selectedItem.transactionType,
      items: [itemToAnalytics(selectedItem)],
    });
  }
  public viewItemsList(category: string, items: Item[]) {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item_list
    this.logEvent('view_item_list', {
      item_list_name: category,
      items: items.map(itemToAnalytics)
    });
  }

  public selectItem(category: string, item: Item) {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#select_item
    this.logEvent('select_item', {
      item_list_name: category,
      items: [itemToAnalytics(item)]
    });
  }

  public purchase(transactionId: string, item: Item) {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
    this.logEvent('purchase', {
      currency: item.currency,
      value: item.value,
      transaction_id: transactionId,
      items: [itemToAnalytics(item)]
    });
  }

  public checkoutStep(
    stepName: string,
    value: number,
    currency: string,
    productId: string,
    productType: string,
    productName: string,
    transactionType: string
  ) {
    const items /*: firebase.analytics.Item[]*/ = [
      { category: productType, name: productName, id: productId }
    ];
    this.logEvent('checkout_progress', {
      step_name: stepName,
      value,
      currency,
      transaction_type: transactionType
    });
  }

  public videoStreamStart(
    videoType: "LIVE" | "VOD",
    streamName: string,
    streamId: string
  ) {
    const event = { LIVE: "live_video_start", VOD: "vod_video_start" }[
      videoType
    ];
    this.logEvent(event, {
      video_id: streamId,
      video_name: streamName
    });
  }

  public videoStreamProgress(
    videoType: "LIVE" | "VOD",
    format: "PANORAMA" | "TRACKED",
    streamName: string,
    streamId: string,
    secondsConsumed: number,
    minutesFromStart: number,
    origin: string,
    isFullScreen: boolean
  ) {
    const event = { LIVE: "live_video_consumed", VOD: "vod_video_consumed" }[
      videoType
    ];
    this.logEvent(event, {
      video_id: streamId,
      video_name: streamName,
      seconds_consumed: secondsConsumed,
      minutes_from_start: minutesFromStart,
      video_format: format,
      access_origin: origin,
      video_fullscreen: isFullScreen
    });
  }

  public videoStreamStalled(
    videoType: "LIVE" | "VOD",
    format: "PANORAMA" | "TRACKED",
    streamName: string,
    streamId: string,
    minutesFromStart: number
  ) {
    const event = { LIVE: "live_video_stalled", VOD: "vod_video_stalled" }[
      videoType
    ];
    this.logEvent(event, {
      video_id: streamId,
      video_name: streamName,
      minutes_from_start: minutesFromStart,
      video_format: format
    });
  }
  public videoStreamErrored(
    videoType: "LIVE" | "VOD",
    format: "PANORAMA" | "TRACKED",
    streamName: string,
    streamId: string,
    minutesFromStart: number
  ) {
    const event = { LIVE: "live_video_errored", VOD: "vod_video_errored" }[
      videoType
    ];
    this.logEvent(event, {
      video_id: streamId,
      video_name: streamName,
      minutes_from_start: minutesFromStart,
      video_format: format
    });
  }

  public search(searchTerm) {
    this.logEvent('search', { search_term: searchTerm });
  }
}
