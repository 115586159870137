import { BehaviorSubject, Subject, timer } from "rxjs";
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";

export type EventStatus = "live" | "scheduled" | "past";

const SLICE = 1000;
const SECONDS = Math.floor(1000 / SLICE);
const MINUTES = 60 * SECONDS;

@Component({
  selector: 'yo-event-date',
  templateUrl: './event-date.component.html',
  styleUrls: ['./event-date.component.scss'],
})
export class EventDateComponent implements OnInit, OnChanges, OnDestroy {

  private ngUnsubscribe = new Subject();

  @Input('displayType') displayType?: 'date' | 'range';
  @Input('startDate') startDate: Date;
  @Input('stopDate') stopDate: Date;
  @Input('trackEventStatus') trackEventStatus: boolean = false;

  eventStatus: EventStatus = 'scheduled';
  eventStatusUpdater = new BehaviorSubject<EventStatus>("scheduled");
  isStartingToday: boolean = false;
  dateLabel: string = '';

  ngOnInit(): void {
    this.isStartingToday = this.isToday();
    this.getDateLabel();
    this.eventStatus = this.calculateEventStatus();
    if (this.trackEventStatus) { this.listenEventStatus(); }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((!changes.startDate.firstChange && changes.startDate.previousValue != changes.startDate.currentValue)
      || (!changes.stopDate.firstChange && changes.stopDate.previousValue != changes.stopDate.currentValue)) {
      // Date Inputs changed, update the label
      this.isStartingToday = this.isToday();
      this.eventStatus = this.calculateEventStatus();
      this.getDateLabel();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  calculateEventStatus(): EventStatus {
    const now = new Date().getTime();
    return this.stopDate.getTime() < now ? "past"
      : this.startDate.getTime() > now ?
        "scheduled"
        : "live";
  }

  getDateLabel() {
    this.dateLabel = this.displayType == 'date' ? this.getStartDate() : this.getRangeDate();
  }

  getRangeDate(): string {
    let options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: "short",
      year: "2-digit",
    };

    const startDate = this.startDate;
    const stopDate = this.stopDate;

    const dateFormatter = new Intl.DateTimeFormat("en-GB", options);
    let formattedStartDate = dateFormatter.format(startDate);
    let formattedStopDate = dateFormatter.format(stopDate);

    return `${formattedStartDate} - ${formattedStopDate}`;
  }

  getStartDate(): string {
    const date = this.startDate;
    let options: Intl.DateTimeFormatOptions =
      this.isToday() && this.eventStatus != "past" ? {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      } : {
        day: '2-digit',
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      };

    const dateFormatter = new Intl.DateTimeFormat("en-GB", options);
    let formattedDate = dateFormatter.format(date);

    if (!this.isToday()) {
      formattedDate = formattedDate.replace(",", " -");
    }

    return formattedDate;
  }

  isToday(): boolean {
    let now = new Date();
    const startDate: Date = this.startDate;
    return (startDate.getDate() == now.getDate())
      && (startDate.getMonth() == now.getMonth())
      && (startDate.getFullYear() == now.getFullYear());
  }

  listenEventStatus() {
    timer(0, (MINUTES * SLICE) / 2).pipe(
      map(interval => Math.ceil((this.startDate.getTime() - new Date().getTime()) / (MINUTES * SLICE))),
      filter(delta => delta <= 1),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      delta => {
        if (Math.abs(delta) > Math.ceil((this.stopDate.getTime() - this.startDate.getTime()) / (MINUTES * SLICE))) {
          this.eventStatus = 'past';
        } else if (delta < 1) {
          this.eventStatus = 'live';
        }
      },
      err => console.error("An error occured while calculating event status", err)
    );
  }

}