import { Callable, Collection } from '../collection';
import { Injectable, Injector } from '@angular/core';

import { Invite } from '../interfaces/invite';
import { Observable } from 'rxjs';
import { SubscriptionContactDetails } from '../interfaces/subscription';

interface InviteAdministrativeUserRequestParams {
  userEmail: string;
  name?: string;
  type: 'ADMIN' | 'STREAMER' | 'TRAINER' | 'MEMBER';
  clubDocId: string;
  teamDocId?: string;
  message?: string;
}
export interface InviteUserResponse {
  success: boolean;
  needsRegistration: boolean;
}

/* #TODO: Change this interface to have multiple types of accept invites */
export interface AcceptInviteRequestParams {
  inviteId: string;
  token: string;
}

export interface AcceptUserInviteResponse {
  success: boolean;
  message: string;
}

export interface AcceptTransferOwnershipInviteRequestParams
  extends AcceptInviteRequestParams {
  paymentMethodId: string;
  newOwnerName: string;
  newOwnerPhoneNumber?: SubscriptionContactDetails;
}

export interface AcceptTransferOwnershipInviteResponse
  extends AcceptUserInviteResponse {
  subscriptionId: string;
}

export interface TransferOwnershipRequest {
  newOwnerId: string;
  clubDocId: string;
  message?: string;
  name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class InvitesService extends Collection<Invite> {
  private createInvite: Callable<
    InviteAdministrativeUserRequestParams,
    InviteUserResponse
  >;
  private acceptInvite: Callable<
    AcceptInviteRequestParams,
    AcceptUserInviteResponse
  >;
  private acceptTransferOwnershipInviteCallable: Callable<
    AcceptTransferOwnershipInviteRequestParams,
    AcceptTransferOwnershipInviteResponse
  >;
  public transferOwnership: Callable<
    TransferOwnershipRequest,
    { success: boolean }
  >;

  constructor(injector: Injector) {
    super(injector, 'invites', true);
    this.createInvite =
      this.declareCallable<InvitesService['createInvite']>('users-inviteUser');
    this.acceptInvite = this.declareCallable<InvitesService['acceptInvite']>(
      'users-acceptUserInvite'
    );
    this.acceptTransferOwnershipInviteCallable = this.declareCallable<
      InvitesService['acceptTransferOwnershipInviteCallable']
    >('users-acceptUserInvite');
    this.transferOwnership = this.declareCallable<
      InvitesService['transferOwnership']
    >('users-transferOwnership');
  }

  createAdminInvite(
    email: string,
    message: string | undefined,
    clubDocId: string
  ): Observable<InviteUserResponse> {
    return this.createInvite({
      type: 'ADMIN',
      userEmail: email,
      message,
      clubDocId,
    });
  }

  createStreamerInvite(
    email: string,
    message: string,
    clubDocId: string
  ): Observable<InviteUserResponse> {
    return this.createInvite({
      type: 'STREAMER',
      userEmail: email,
      message,
      clubDocId,
    });
  }
  createTrainerInvite(
    email: string,
    message: string | undefined,
    clubDocId: string,
    teamDocId: string
  ): Observable<InviteUserResponse> {
    return this.createInvite({
      type: 'TRAINER',
      userEmail: email,
      message,
      clubDocId,
      teamDocId,
    });
  }

  createMemberInvite(
    email: string,
    message: string | undefined,
    clubDocId: string
  ): Observable<InviteUserResponse> {
    return this.createInvite({
      type: 'MEMBER',
      userEmail: email,
      message,
      clubDocId,
    });
  }

  acceptUserInvite(inviteId: string, token: string) {
    return this.acceptInvite({ inviteId, token });
  }

  acceptTransferOwnershipInvite(
    inviteId: string,
    token: string,
    paymentMethodId: string,
    newOwnerName: string,
    newOwnerPhoneNumber?: SubscriptionContactDetails
  ) {
    return this.acceptTransferOwnershipInviteCallable({
      inviteId,
      token,
      paymentMethodId,
      newOwnerName,
      newOwnerPhoneNumber,
    });
  }
}
