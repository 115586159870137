import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, PRIMARY_OUTLET, Router, RoutesRecognized, UrlSegment, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Injectable } from '@angular/core';
import { Livestream } from '../model/interfaces/livestream';

export interface Intent {
  path: string;
  id: string;
  livestream: Livestream;
}

export interface Resource {
  type: string;
  docId: string;
}

@Injectable({
  providedIn: 'root'
})
export class RoutingStateService {
  private history = [];
  private pathParamsHistory = [];

  private segmentSource = new BehaviorSubject<UrlSegment[]>(undefined);
  private intentSource = new BehaviorSubject<Intent>(undefined);
  private pathParamsSource = new BehaviorSubject<Resource>(undefined);

  segmentStream = this.segmentSource.asObservable();
  intentStream = this.intentSource.asObservable();
  pathParamsStream: Observable<Resource>;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gtmService: GoogleTagManagerService
  ) {
    this.pathParamsStream = this.pathParamsSource.asObservable();
  }

  loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof RoutesRecognized))
      .subscribe((routerEvent: NavigationEnd | RoutesRecognized) => {

        // Please don't look here
        switch (true) {
          case routerEvent instanceof NavigationEnd:
            this.handleNavigationEnds(routerEvent as NavigationEnd);
            break;
          case routerEvent instanceof RoutesRecognized:
            setTimeout(_ => this.handleRecognizedRoute(routerEvent as RoutesRecognized), 1000);
            break;
          default:
            break;
        }
      });
  }

  getHistory(): string[] {
    return this.history;
  }

  getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/browse';
  }

  getGrandParentPathFromRoot(activatedRoute: ActivatedRoute): string {
    const path = activatedRoute.snapshot.parent.parent.pathFromRoot.map((activatedRouteSnapshot: ActivatedRouteSnapshot) => {
      return activatedRouteSnapshot.url.join('/');
    }).join('/');

    return path;
  }

  getParentPathFromRoot(activatedRoute: ActivatedRoute): string {
    return activatedRoute.snapshot.parent.pathFromRoot.map((activatedRouteSnapshot: ActivatedRouteSnapshot) => {
      return activatedRouteSnapshot.url.join('/');
    }).join('/');
  }

  setFirstSegment(): void {
    console.group('First segment');
    const urlTree: UrlTree = this.router.parseUrl(this.router.url);
    console.log('First segment', urlTree.root.children[PRIMARY_OUTLET].segments);
    console.groupEnd();

    this.segmentSource.next(urlTree.root.children[PRIMARY_OUTLET].segments);
  }

  setIntent(intent: Intent) {
    this.intentSource.next(intent);
  }

  addPathParams(newPathParams: Resource) {
    console.group('Routing State Service');
    console.log('resource', newPathParams);
    console.log('pathParamsHistory', this.pathParamsHistory);
    console.groupEnd();
    this.pathParamsSource.next(newPathParams);
    this.pathParamsHistory.push(newPathParams);
  }

  removePathParams(resourceType: string) {
    this.pathParamsHistory = this.pathParamsHistory.filter((pathParam: Resource) => pathParam.type !== resourceType);
    this.pathParamsSource.next(null);
  }

  handleNavigationEnds(routerEvent: NavigationEnd): void {
    // console.group('NavigationEnd event');
    const { urlAfterRedirects } = routerEvent;
    this.history = [...this.history, urlAfterRedirects];
    // console.log('History', this.history);
    // console.log('Next url', urlAfterRedirects);
    // console.log('Activated route', this.activatedRoute);
    // console.log('Router', this.router);
    // console.log('Navigation', { ...routerEvent });
    const event = {
      event: 'page',
      pageName: routerEvent.url
    };
    this.gtmService.pushTag(event);

    this.setFirstSegment();
    // console.groupEnd();
  }

  handleRecognizedRoute(routerEvent: RoutesRecognized): void {
    // console.group("Routes Recognized");
    // console.log("Event", routerEvent);
    const data = this.aggregateData(routerEvent.state.root);
    // console.log("Data", data);

    const hubspotIframe = document.getElementById('hubspot-messages-iframe-container');
    const hubspot = data.hubspot;
    // console.log("Hubspot Iframe", hubspotIframe);

    if (hubspotIframe && hubspot != false) {
      hubspotIframe.classList.add("visible");
    }
    // console.groupEnd();
  }

  // Recursively pulls the data fields from the routing tree. Child routes will override
  aggregateData(root: ActivatedRouteSnapshot): {[key: string]: any} {
    const firstChild = root.firstChild;
    let data = {};

    if (firstChild !== null) {
      data = this.aggregateData(firstChild);
    }

    data = {...root.data, ...data};

    return data;
  }

}
