import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'yo-accept-invite-admin',
  templateUrl: './accept-invite-admin-tpl.html',
  styles: []
})
export class AcceptInviteAdminComponent {
  @Input() inviteMessage: string;
}

@Component({
  selector: "yo-accept-invite-trainer",
  templateUrl: "./accept-invite-trainer-tpl.html",
  styles: []
})
export class AcceptInviteTrainerComponent {
  @Input() inviteMessage: string;
}

@Component({
  selector: "yo-accept-invite-streamer",
  templateUrl: "./accept-invite-streamer-tpl.html",
  styles: []
})
export class AcceptInviteStreamerComponent {
  @Input() inviteMessage: string;
}

@Component({
  selector: "yo-accept-invite-viewer",
  templateUrl: "./accept-invite-viewer-tpl.html",
  styles: []
})
export class AcceptInviteViewerComponent {
  @Input() inviteMessage: string;
}
