import { Injectable, Injector } from '@angular/core';

import { Collection } from '../collection';
import { Observable } from 'rxjs';
import { TeamPublic } from '../interfaces/teamPublic';

@Injectable({
  providedIn: 'root'
})
export class TeamsPublicService extends Collection<TeamPublic> {

  constructor(
    injector: Injector,
  ) {
    super(injector, 'teamsPublic', true);
  }

  getTeamsByClubDocId(clubDocId: string): Observable<TeamPublic[]> {
    return this.query(
      ['clubDocId', '==', clubDocId],
      undefined,
      [{field: 'name', sort: 'asc'}]
    );
  }

  sortByName(teams: TeamPublic[]): TeamPublic[] {
    return teams.sort((a, b) => {
      const teamAName = a.name.toLowerCase();
      const teamBName = b.name.toLowerCase();

      if (teamAName < teamBName) {
        // sort ascending
        return -1;
      }
      if (teamAName > teamBName) {
        return 1;
      }
      return 0;
    });
  }
}
