import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription, combineLatest, of } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Intent, RoutingStateService } from 'src/app/core/utility/routing-state.service';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/core/model/services/analytics.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LivestreamGroup } from '../../../core/model/interfaces/livestream-group';
import { LivestreamGroupsService } from '../../../core/model/services/livestream-groups.service';
import { LoginMode } from 'src/app/auth/LoginMode.enum';
import { Payment } from 'src/app/core/model/interfaces/payment';
import { PaymentsService } from 'src/app/core/model/services/payments.service';
import { ProductFeatures } from 'src/app/core/model/interfaces/product';
import { User } from 'src/app/core/model/interfaces/user';

interface PackageConfig {
  type: 'bundle' | 'vod' | 'ppv';
  price?: number;
  formattedPrice?: string;
  currency?: string;
  available: boolean;
  description: string;
  label: string;
  selected: boolean;
  features?: ProductFeatures[];
}

const defaultVodFeatures: ProductFeatures[] = [/*
  {
    icon: "monetization_on",
    heading: "Inntekt til klubben",
    items: [
      "70% av inntekten går tilbake til klubben og utbetales fortløpende.",
    ],
  },
  {
    icon: "ondemand_video",
    heading: "Se klubbens arrangementer",
    items: [
      "Få tilgang til treninger, kamper, arrangementer o.l. som klubben sender med Joymo.",
      "Få med deg idrettsgleden live og/eller i opptak",
    ],
  },
  {
    icon: "history",
    heading: "Se fra årets sesong",
    items: [
      "Mens vi venter i spenning på neste sesong, får du allerede nå tilgang til sendinger fra årets sesong (sendt med Joymo). Gjenopplev favorittøyeblikkene dine på nytt.",
    ],
  },
  {
    icon: "thumb_up",
    heading: "Støtt favorittlaget ditt",
    items: [
      "Før du bekrefter kjøpet, får du mulighet til å si hvilket lag som du ønsker å støtte (valgfritt).",
    ],
  },
*/];
const defaultBundleFeatures: ProductFeatures[] = [];
const defaultPpvFeatures: ProductFeatures[] = [];

@Component({
  selector: "yo-checkout-livestream-group-view",
  templateUrl: "./checkout-livestream-group-view.component.html",
  styleUrls: ["./checkout-livestream-group-view.component.scss"],
})
export class CheckoutLivestreamGroupViewComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  private subscriptions: Subscription[] = [];
  private intent: Intent;

  LoginMode = LoginMode;

  authenticatedUser: User;
  livestreamGroup: LivestreamGroup;
  availablePackages: PackageConfig[];
  accessCheck$ = new BehaviorSubject<
    { access: boolean; redirect: string } | boolean | null
  >(null);
  redirectAfterPayment: string;
  shareUrl: string;
  dialogRef;

  paymentStep:
    | "init"
    | "login"
    | "waiting_for_payment"
    | "already_has_access"
    | "redirecting_to_stripe" = "init";
  selectedPackage$ = new BehaviorSubject<PackageConfig | undefined>(undefined);
  selectedPackage: PackageConfig;

  paymentMessage$ = new Subject<string>();
  paymentMessage: string;

  constructor(
    private livestreamGroupsService: LivestreamGroupsService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private routingStateService: RoutingStateService,
    private paymentsService: PaymentsService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    // console.log('Route snapshot >>>', this.route.snapshot);
    this.livestreamGroupsService
      .getByDocId(this.route.snapshot.params["productId"])
      .pipe(
        take(1),
        tap(livestreamGroup => {
          this.livestreamGroup = livestreamGroup;
          this.shareUrl = new URL(`/api/share/${livestreamGroup.docId}`, document.location.href).toString();
        }),
        switchMap((livestreamGroup) => {

          this.availablePackages = this.buildAvailablePackages(this.livestreamGroup);
          return combineLatest([
            this.selectedPackage$,
            this.authService.authenticatedUser$,
          ]);
        }),
        switchMap(([selectedPackage, authenticatedUser]) => {
          if (!authenticatedUser && selectedPackage) {
            this.paymentStep = 'login';
            this.analytics.beginCheckout({
              currency: this.livestreamGroup.force_currency || 'nok',
              productId: this.livestreamGroup.docId,
              productName: this.livestreamGroup.name,
              value: selectedPackage.price,
              productType: 'livestreamGroup',
              transactionType: selectedPackage.type,
            });
            return of(null);
          } else if (authenticatedUser && selectedPackage) {
            this.authenticatedUser = authenticatedUser;
            this.analytics.checkoutStep(
              "logged_in",
              selectedPackage.price,
              this.livestreamGroup.force_currency || 'nok',
              this.livestreamGroup.docId,
              'livestreamGroup',
              this.livestreamGroup.name,
              selectedPackage.type
            );
            return combineLatest([
              this.livestreamGroupsService.hasViewingRights(
                this.livestreamGroup.docId,
                authenticatedUser.docId,
              ).pipe(take(1)),
              this.routingStateService.intentStream,
            ]);
          }
          return of(null);
        }),
        filter((check) => check !== null),
        switchMap(([access, intent]) => {
          let redir: string;
          if (this.intent) {
            redir = `${intent.path}${intent.id}`;
          } else {
            redir = this.route.snapshot.queryParamMap.get('redir') || `/browse/collections/${this.livestreamGroup.docId}`;
          }

          const redirectAfterPayment = new URL(redir, document.location.href).toString();
          if (access) {
            this.paymentStep = "already_has_access";
            this.redirectAfterPayment = redirectAfterPayment;
            return of(null);
          } else {
            // this.paymentStep = "waiting_for_payment";
            const payment: Partial<Payment> = {
              integration_version: 1,
              paymentType: "stripe",
              productDocId: this.livestreamGroup.docId,
              productType: "livestreamGroup",
              transactionType: this.selectedPackage.type,
              userDocId: this.authenticatedUser.docId,
              redirectUrl: redirectAfterPayment,
              successUrl: new URL(`/checkout/success/${this.livestreamGroup.docId}`, document.location.href).toString(),
              cancelUrl:  new URL(`/checkout/cancel/${this.livestreamGroup.docId}`, document.location.href).toString(),
            };
            return of(payment);
          }
        }),
        take(1), // ensure we only take one payment ensuring we don't flood the payments collection
      )
      .subscribe((payment: Payment) => {
        if (!payment) {
          console.log("No payment object");
          this.router.navigateByUrl(`/browse/collections/${this.livestreamGroup.docId}`);
          
        } else {
          this.paymentStep = "redirecting_to_stripe";

          this.analytics.checkoutStep(
            "redirected_to_payment",
            this.selectedPackage.price,
            this.livestreamGroup.force_currency || "nok",
            this.livestreamGroup.docId,
            'livestreamGroup',
            this.livestreamGroup.name,
            this.selectedPackage.type
          );
  
          payment.referral = null;
  
          this.paymentsService.createAndReturnPayment(payment)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((updatedPayment) => {
              
              if (updatedPayment.confirmed) {
                this.router.navigateByUrl(`/browse/collections/${this.livestreamGroup.docId}`)
              }

              if (updatedPayment.stripeSession) {
                this.paymentsService
                  .redirectToCheckout(
                    updatedPayment.stripeSession.id
                  ).subscribe(result => {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer
                    // using `result.error.message`.
                    console.log("Result of redirection is ", result);
                  });
              }
            });
        }

        

        
        //
        //  Commented out section involving "Vote for your team"
        //

        // this.paymentMessage$
        //   .pipe(
        //     take(1),
        //     takeUntil(this.ngUnsubscribe),
        //     switchMap((message) => {
        //       this.paymentStep = "redirecting_to_stripe";

        //       this.analytics.checkoutStep(
        //         "redirected_to_payment",
        //         this.selectedPackage.price,
        //         this.livestreamGroup.force_currency || "nok",
        //         this.livestreamGroup.docId,
        //         'livestreamGroup',
        //         this.livestreamGroup.name,
        //         this.selectedPackage.type
        //       );

        //       payment.referral = message ? message : null;

        //       return this.paymentsService.createAndReturnPayment(payment);
        //     })
        //   )
        //   .subscribe((updatedPayment) => {
        //     if (!updatedPayment.stripeSession) {
        //       return;
        //     }
        //     this.paymentsService
        //       .redirectToCheckout(
        //         updatedPayment.stripeSession.id
        //       ).subscribe(result => {
        //         // If `redirectToCheckout` fails due to a browser or network
        //         // error, display the localized error message to your customer
        //         // using `result.error.message`.
        //         console.log("Result of redirection is ", result);
        //       });
        //   });
      });
  }

  buildAvailablePackages(livestreamGroup: LivestreamGroup): PackageConfig[] {
    const currency = livestreamGroup.force_currency || "nok";
    const packages = [] as PackageConfig[];

    console.log(livestreamGroup);
    // Live
    if (
      livestreamGroup.price &&
      livestreamGroup.stopTime.toMillis() > Date.now()
    ) {
      packages.push({
        type: "ppv",
        available: true,
        currency,
        description:
          livestreamGroup.priceDescription || "Watch events while live",
        price: livestreamGroup.price,
        formattedPrice: this.paymentsService.formatPrice(livestreamGroup.price, livestreamGroup.force_currency),
        label: livestreamGroup.priceLabel || "LIVE ONLY",
        selected: false,
        features: livestreamGroup.priceFeatures || defaultPpvFeatures,
      });
    }

    // Vod
    if (
      livestreamGroup.availableForVod &&
      livestreamGroup.stopTime.toMillis() < Date.now()
    ) {
      packages.push({
        type: "vod",
        available: true,
        currency,
        description:
          livestreamGroup.vodDescription || "7 Days Unlimited Replay Access",
        price: livestreamGroup.vodPrice,
        formattedPrice: this.paymentsService.formatPrice(livestreamGroup.vodPrice, livestreamGroup.force_currency),
        label: livestreamGroup.vodLabel || "FULL ACCESS",
        selected: false,
        features: livestreamGroup.vodFeatures ||  defaultVodFeatures,
      });
    }

    // Bundle
    if (
      livestreamGroup.availableForBundle &&
      livestreamGroup.stopTime.toMillis() > Date.now()
    ) {
      packages.push({
        type: "bundle",
        available: true,
        currency,
        description:
          livestreamGroup.bundleDescription || "Live + Unlimited Replay Access",
        price: livestreamGroup.bundlePrice,
        formattedPrice: this.paymentsService.formatPrice(livestreamGroup.bundlePrice, livestreamGroup.force_currency),
        label: livestreamGroup.bundleLabel || "FULL ACCESS",
        selected: false,
        features:
          livestreamGroup.bundleFeatures ||
          defaultVodFeatures  ||
          defaultBundleFeatures,
      });
    }

    return packages;
  }

  buy(type: PackageConfig["type"]) {
    console.log("Trying to buy", type);
    const selectedPackage = this.availablePackages.find((x) => x.type === type);
    this.selectedPackage$.next(selectedPackage);
    this.selectedPackage = { ...selectedPackage, selected: true };
  }

  goBack() {
    document.location.href = this.redirectAfterPayment;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
