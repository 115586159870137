<div class="ownership-invite">

  <ng-container [ngSwitch]="currentStep">
    <h2 class="title" *ngSwitchCase="'spinner'">We are transferring the ownership to you!</h2>
    <h2 class="title" *ngSwitchCase="'contactSupport'">An error occured with the transfer!</h2>
    <h2 class="title" *ngSwitchDefault>Final step to accept ownership of <a
        [routerLink]="['/browse', 'clubs', club.docId]" target="_blank">{{club.name}}</a></h2>
  </ng-container>

  <ng-container [ngTemplateOutlet]="this[currentStep]"></ng-container>

  <div class="actions">
    <button mat-stroked-button *ngIf="currentStep == 'paymentInfo'"
      (click)="moveBack()">Back</button>
    <button mat-flat-button color="primary" *ngIf="currentStep == 'intro'" (click)='goToPaymentDetails()'>Update
      Payment</button>
    <button mat-flat-button color="primary" *ngIf="currentStep == 'paymentInfo'" [disabled]="!stripeFormGroup.valid" (click)='sendPaymentDetails()'>{{
      loadingPhase != 'NONE' ? loadingPhase : 'Accept
      ownership' }}</button>
  </div>
  <!-- <mat-card>
    <mat-card-title>Congratulations, you have been transferred an ownership!</mat-card-title>
    <mat-card-content>
      <div class="terms-and-conditions">
        <yo-terms-and-conditions></yo-terms-and-conditions>
      </div>
      <div class="info" *ngIf="club">
        You have been transfered an ownership for <a [routerLink]="['/browse', 'clubs', club.docId]"
          target="_blank">{{club.name}}</a> to you. Please provide card details to renew the subscription and become an
        owner.
      </div>
      <div class="message" *ngIf="invite.message">
        <p>Here is the message the previous owner has for you:</p>
        {{ invite.message }}
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-flat-button color="primary" disabled (click)='acceptOwnership()'>Accept Ownership</button>
    </mat-card-actions>
  </mat-card> -->
</div>

<ng-template #intro>
  <div class="terms-and-conditions-container">
    <div class="info" *ngIf="club">
      <p>To confirm ownership, please update your payment information and review the terms and conditions.</p>
    </div>
    <div class="message" *ngIf="invite.inviteMessage">
      <p>Here is the message the previous owner has for you:</p>
      {{ invite.inviteMessage }}
    </div>
  </div>
</ng-template>

<ng-template #paymentInfo>
  <div class="payment-info">
    <div class="info" *ngIf="club">
      You have been transfered the ownership for <a [routerLink]="['/browse', 'clubs', club.docId]"
        target="_blank">{{club.name}}</a>. Please provide card details to renew the subscription and become an
      owner.
    </div>
    <form [formGroup]="stripeFormGroup" class="payment-details-form">

      <mat-form-field>
        <mat-label>Full Name</mat-label>
        <input matInput #nameInput type="text" name="name" id="name" required [value]="user && user.displayName ? user.displayName : ''" formControlName="nameControl">
        <mat-error *ngIf="stripeFormGroup.get('nameControl').errors">Your full name is required.</mat-error>
      </mat-form-field>

      <div class="full-width" fxLayout="row wrap" fxLayoutGap="0.3em" fxLayoutGap.lt-md="0px">
        <div class="field">
          <mat-form-field class="phone-number">
            <mat-label>Phone Country Code</mat-label>
            <mat-select panelClass="dial-code-panel" formControlName="dialCode" id="dialCode" required>
              <mat-select-trigger>
                {{ dialCode.value ? dialCode.value.split(' ')[0] : '+' }}
              </mat-select-trigger>
              <mat-option *ngFor="let country of countryList" [value]="country.dialCode + ' ' + country.name">{{ "(" +
                country.dialCode + ") " + country.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="stripeFormGroup.get('dialCode').errors || stripeFormGroup.get('phoneNumber').errors">This
              field is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field>
            <mat-label>Phone Number</mat-label>
            <input type="text" matInput formControlName="phoneNumber" required name="phoneNumber" id="phoneNumber">
            <mat-error *ngIf="stripeFormGroup.get('phoneNumber').errors">This field is required.</mat-error>
          </mat-form-field>
        </div>
      </div>

      <mat-form-field class="stripe-card-container">
        <mat-label>Card</mat-label>
        <ngx-stripe-card class="stripe-card-element" [options]="cardOptions" (change)="handleCardChanges($event)">
        </ngx-stripe-card>
        <input hidden matInput placeholder="" formControlName="stripeCardControl" required>
      </mat-form-field>
      <mat-error *ngIf="cardErrors">{{cardErrors}}</mat-error>

      <mat-checkbox color="primary" formControlName="termsAndConditions" required>
        I have read and agree to the
        <a href="https://www.joymo.tv/terms-of-service-content-creator" class="highlighted" target="_blank">Joymo
          Terms of Service</a>.*
      </mat-checkbox>
    </form>

  </div>
</ng-template>


<ng-template #success>
  <div class="success">
    <p>Redirecting . . .</p>
  </div>
</ng-template>

<ng-template #contactSupport>
  <div class="contact-support">
    <p>{{ error.message ? error.message : '' }}</p>
    <p>Please contact <a href="mailto:support@joymo.tv">support@joymo.tv</a> and copy/paste the following in your
      message so they can help you as fast as possible.</p>
    <div class="extract" *ngIf="error">
      <!-- <button mat-icon-button class="copy-button">
        <mat-icon>content_copy</mat-icon>
      </button> -->
      <pre>{{ error | json }}</pre>
    </div>
  </div>
</ng-template>

<ng-template #spinner>
  <yo-loading compact="true"></yo-loading>
</ng-template>