import { Injectable, Injector } from '@angular/core';
import {Observable, combineLatest, from, noop} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';

import { Club } from './../interfaces/club';
import { ClubsService } from './clubs.service';
import {Collection} from '../collection';
import {Team} from '../interfaces/team';
import { UploadType } from '../../utility/upload.service';
import { arrayRemove } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TeamsService extends Collection<Team> {
  // teamsWhereAdminOrTrainer$: Observable<Team[]>;

  constructor(
    injector: Injector,
    private clubsService: ClubsService
  ) {
    super(injector, 'teams', true);
    // this.teamsWhereAdminOrTrainer$ = this.getTeamsWhereAdminOrTrainer().pipe(shareReplay());
  }

  getTeamsWhereAdminOrTrainer(userDocId: string): Observable<Team[]> {
    return this.clubsService.getClubsWhereAdminOrTrainer(userDocId)
      .pipe(
        switchMap((clubs: Club[]): Observable<{club: Club, teams: Team[]}[]> => {
          const teamsObservables = clubs.map(clubWhereAdmin =>
            this.getTeamsByClubDocId(clubWhereAdmin.docId).pipe(
              map(clubTeams => ({ club: clubWhereAdmin, teams: clubTeams }))
            )
          );
          return combineLatest(teamsObservables);
        }),
        map(arrayOfClubTeams => {
          // Flattens the arrays
          const arrayOfTeams = [];
          for (const {club, teams} of arrayOfClubTeams) {
            if (club.adminDocIds.includes(userDocId)) {
              // add all the teams of the club
              arrayOfTeams.push(...teams.map(t => ({clubData: club, ...t})));
            } else {
              // only add the teams where the user is a trainer
              arrayOfTeams.push(
                ...teams.filter( t => t.trainerDocIds.includes(userDocId)).map(t => ({ clubData: club, ...t }))
              );
            }
          }
          return arrayOfTeams;
        })
      );
  }

  getTeamsByClubDocId(clubDocId: string, onlyOnce = false): Observable<Team[]> {
    return this.query(
      ['clubDocId', '==', clubDocId],
      undefined,
      [{field: 'name', sort: 'asc'}]
    ).pipe(
      (onlyOnce ? take(1) : tap( _ => _ ))
    );
  }

  getTeamsWhereUserIsTrainer(uid: string): Observable<Team[]> {
    return this.query(['trainerDocIds', 'array-contains', uid]);
  }

  changeImageUrl(teamDocId: string, imageUrl: string): Observable<any> {
    console.log(teamDocId);
    return from(this.update(teamDocId, { imageUrl }));
  }

  async removeTrainers(userDocIds: string[], teamDocId: string): Promise<boolean> {
    console.log("Will update this team", teamDocId);
    const update = {
      trainerDocIds: arrayRemove(...userDocIds)
    };

    try {
      await this.update(teamDocId, update);
      return true;
    } catch (error) {
      console.error('Error while trying to remove a user from team', error);
      throw new Error('Error while trying to remove a user from team');
    }
  }

  /*async removeUserFrom(userDocId: string, role: string, teamDocId: string): Promise<boolean> {
    const updateData = {};
    updateData[`${role}DocIds`] = firebase.firestore.FieldValue.arrayRemove(userDocId);

    // Concerts the promise to an observable
    try {
      await this.update(teamDocId, updateData);
      return true;
    } catch (error) {
      console.error('Error while trying to remove a user from team', error);
      throw new Error('Error while trying to remove a user from team');
    }
  }*/

  sortByName(teams: Team[]): Team[] {
    return teams.sort((a, b) => {
      const teamAName = a.name.toLowerCase();
      const teamBName = b.name.toLowerCase();

      if (teamAName < teamBName) {
        // sort ascending
        return -1;
      }
      if (teamAName > teamBName) {
        return 1;
      }
      return 0;
    });
  }

  getStoragePath(team: Team, target: UploadType): string {
    return ["channels", team.clubDocId, "teams", team.docId, target].join("/");
  }
}
