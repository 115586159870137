import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from '../app-routing.module';
import { AuthModule } from '../auth/auth.module';
import { CancelComponent } from './cancel/cancel.component';
import { CheckoutLivestreamGroupViewComponent } from './livestream-group/checkout-livestream-group-view/checkout-livestream-group-view.component';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import {MatStepperModule} from '@angular/material/stepper';
import { NgModule } from '@angular/core';
import { NgxStripeModule } from 'ngx-stripe';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { SuccessComponent } from './success/success.component';
import { YoimoUiModule } from '../yoimo-ui/yoimo-ui.module';

@NgModule({
  imports: [
    CommonModule,
    YoimoUiModule,
    AppRoutingModule,
    FormsModule, // Used by Stripe
    AuthModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatIconModule,
    MatStepperModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatListModule,
    MatExpansionModule,
    ShareButtonsModule,
    NgxStripeModule,
  ],
  declarations: [
    CheckoutLivestreamGroupViewComponent,
    SuccessComponent,
    CancelComponent,
  ],
  entryComponents: [],
})
export class CheckoutModule {}
