<ng-container>
  <h1 mat-dialog-title *ngIf="!error">{{ selectedPlanOption ? 'Changing ' : 'Change broadcasting' }} plan {{ selectedPlanOption ? 'to ' + toUCFirst(selectedPlanOption.planName) + ', billed ' + selectedPlanOption.periodicity : '' }}</h1>
  <h1 mat-dialog-title *ngIf="error && currentStep =='contactSupport'">An error occured</h1>
  <mat-dialog-content *ngIf="this[currentStep]">
    <ng-container [ngTemplateOutlet]="this[currentStep]"></ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="start">
    <button mat-stroked-button [mat-dialog-close]="false" *ngIf="currentStep !=='success' && currentStep !=='spinner'">Cancel</button>
    <button mat-raised-button color="primary" *ngIf="currentStep == 'actionNeeded'" (click)="onPlanSelected(selectedPlan)">Retry</button>
    <button mat-raised-button color="primary" *ngIf="currentStep == 'confirmation'" [disabled]="isDowngrade && reasons.invalid" (click)="onChangePlan()">Okay</button>
    <button mat-raised-button color="primary" *ngIf="currentStep == 'success'" [mat-dialog-close]="true">Close</button>
  </mat-dialog-actions>
</ng-container>

<ng-template #planSelection>
  <div class="plan-selection" *ngIf="plans.length > 0; else spinner">
    <!-- <p class="description">Select the plan you would like to upgrade or downgrade to.</p> -->
    <div class="list">
      <mat-accordion multi>
        <mat-expansion-panel *ngFor="let plan of plans" [expanded]="isCurrentPlan(plan)" [class.current]="isCurrentPlan(plan)">

          <div class="current-label">Current Plan</div>

          <mat-expansion-panel-header>
            <mat-panel-title>{{ toUCFirst(plan.docId) }}</mat-panel-title>
            <mat-panel-description>{{ getMonthlyPrice(plan) }}<span>/mo</span><span class="vat-included" *ngIf="data.subscription.selectedPlanOption.split('_')[1] == 'nok'">with VAT ({{ getTax(plan) }})</span></mat-panel-description>
          </mat-expansion-panel-header>

          <mat-card-content fxFlex>
            <ul><li *ngFor="let item of plan.featureItems" mat-line>{{ item.title }}</li ></ul>
          </mat-card-content>

          <mat-action-row>
            <ng-container *ngTemplateOutlet="selectButton;context:{plan: plan}"></ng-container>
          </mat-action-row>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <ng-template #selectButton let-plan='plan'>
    <button mat-raised-button (click)="onPlanSelected(plan)" color="primary" [disabled]="isCurrentPlan(plan)">Select</button>
  </ng-template>



</ng-template>
<ng-template #actionNeeded>
  <div class="actions-needed">
    <p>Before we proceed, the following features require your action.</p>
    <div class="storage" *ngIf="updateCheckResponse.storage && updateCheckResponse.storage !== null">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>warning</mat-icon>
              <span>storage</span>
            </mat-panel-title>
            <mat-panel-description>
              {{ updateCheckResponse.storage.oldValue }} of {{ updateCheckResponse.storage.newValue }} used
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-card-content>
            <p>If you downgrade to this plan you won’t be able to make new streams. <br>However you can still sell tickets and get revenue out of existing videos.</p>
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="concurrent-streams" *ngIf="updateCheckResponse.concurrentLivestreams && updateCheckResponse.concurrentLivestreams !== null">
      <mat-accordion>
        <mat-expansion-panel [class.no-toggle]="updateCheckResponse.storage == null && updateCheckResponse.members == null" [expanded]="true" [hideToggle]="updateCheckResponse.storage == null && updateCheckResponse.members == null">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>warning</mat-icon>
              <span>Events</span>
            </mat-panel-title>
            <mat-panel-description>Issues found</mat-panel-description>
          </mat-expansion-panel-header>

          <mat-card-content fxFlex class="list">
            <p>The selected plan does not support starting multiple events at the same time.<br>
               Please delete or reschedule the following events.</p>
            <ng-container *ngFor="let pair of (concurrentStreamsArr || getPairs(updateCheckResponse.concurrentLivestreams))">
              <ng-container *ngTemplateOutlet="streams;context{pair: pair}"></ng-container>
            </ng-container>
          </mat-card-content>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="members" *ngIf="updateCheckResponse.members && updateCheckResponse.members !== null">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>warning</mat-icon>
              <span>Athlete Access</span>
            </mat-panel-title>
            <mat-panel-description>
              {{ updateCheckResponse.members.oldValue }} of {{ updateCheckResponse.members.newValue }} used
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-card-content>
            <p *ngIf="selectedPlanOption && selectedPlanOption.planName == 'elite'">The selected plan supports only 20 athlete accesses.<br>
               Please remove athletes to proceed.</p>
            <p *ngIf="selectedPlanOption && selectedPlanOption.planName == 'starter'">The selected plan does not support any athlete accesses.<br>
               Please remove athletes to proceed.</p>
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</ng-template>
<ng-template #confirmation>
  <div class="confirmation">
    <div class="reason-form" *ngIf="isDowngrade">
      <p>Is there something we could have done better?</p>
      <form [formGroup]="reasonsForm">
        <mat-form-field [style.width.%]="100">
          <mat-label>Please tell us why you'd like to change plans.</mat-label>
          <mat-select formControlName="reasons" #reasons required>
            <mat-option value="one_stream">I do not need more than one stream at a time</mat-option>
            <mat-option value="too_much_storage">I do not need as much storage</mat-option>
            <mat-option value="streaming_less">I will livestream less than anticipated</mat-option>
            <mat-option value="athlete_access_not_needed">I do not need Athlete Access</mat-option>
            <mat-option value="other">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="confirm-text">
      <p>Note: The price difference in your subscription plan will be adjusted in the next billing cycle</p>
    </div>
  </div>
</ng-template>
<ng-template #contactSupport>
  <div class="contact-support">
    <p>{{ error.message }}</p>
    <p>Please contact <a href="mailto:support@joymo.tv">support@joymo.tv</a> and copy/paste the following in your message so they can help you as fast as possible.</p>
    <div class="extract">
      <!-- <button mat-icon-button class="copy-button">
        <mat-icon>content_copy</mat-icon>
      </button> -->
      <pre>{{ error | json }}</pre>
    </div>
  </div>
</ng-template>
<ng-template #success>
  <div class="success">
    <p>Your broadcast plan was successfully changed to {{ toUCFirst(selectedPlanOption.planName) + ', billed ' + selectedPlanOption.periodicity }}.</p>
    <p>You will receive an email with your next invoice shortly.</p>
  </div>
</ng-template>
<ng-template #streams let-pair="pair">
  <div class="separator"></div>
  <div class="pair">
    <div class="date">{{ getWindowDate(pair.startWindow) }}
      <ng-container *ngIf="getWindowDate(pair.endWindow) != getWindowDate(pair.startWindow)">
        <span class="spacing">&nbsp;–&nbsp;</span>{{ getWindowDate(pair.endWindow) }}
      </ng-container>
    </div>
    <div class="names">
      <ng-container *ngFor="let streamId of pair.livestreamIds; let i = index">
        <ng-container *ngTemplateOutlet="stream;context{
          stream: {
            id: streamId,
            name: pair.livestreamNames[i]
          }
        }"></ng-container>
        <span class="spacing" *ngIf="(i + 1) < pair.livestreamIds.length">&nbsp;–&nbsp;</span>
        
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #stream let-stream="stream">
  <a aria-label="link to edit stream" target="_blank" [routerLink]="['/manage', 'streams', 'edit', stream.id]">{{ stream.name }}</a>
</ng-template>

<ng-template #spinner>
  <yo-loading compact="true"></yo-loading>
</ng-template>