import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[yoInput]'
})
export class InputDirective implements OnInit {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'border', '0px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'padding', '8px 11px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'box-sizing', 'border-box');
    this.renderer.setStyle(this.elementRef.nativeElement, 'font-size', '13px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'color', '#000000');
    this.renderer.setStyle(this.elementRef.nativeElement, 'border-radius', '5px');
  }

}
