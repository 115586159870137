import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'yo-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {

  @Input("iconSrc") iconSrc: string;
  @Input("title") title: string;
  @Input("link") link: string = null;
  @Input("linkQueryParams") linkQueryParams: { [key: string]: any } = {};
  @Input("buttonLabel") buttonLabel: string = '';
  @Input("sectionId") sectionId: string;
  @Input("headSection") headSection = false;
  @Input("isMobileView") isMobileView = false;
  @Input() showMore = true;

  @Output("onHeaderClicked") onHeaderClicked = new EventEmitter();
  @Output("onSubheaderClicked") onSubheaderClicked = new EventEmitter();
  @Output("onActionButtonClicked") onActionButtonClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  headerClicked() {
    this.onHeaderClicked.emit(true);
  }
  
  subheaderClicked() {
    this.onSubheaderClicked.emit(true);
  }

  actionButtonClicked() {
    this.onActionButtonClicked.emit(true);
  }

}
