<div>
  <form (submit)='$event.preventDefault()' fxLayout="column" class="yo-theme" fxLayoutGap="1em" fxLayoutAlign="center stretch">
    <div *ngIf="!hideSocialSharing" fxLayout="column" fxLayoutGap="0.5em" fxLayoutAlign="center stretch">
      <mat-form-field class="full-width suffix-button-attached-to-input">
        <input type="text" matInput readonly="readonly" placeholder="Direct link" [value]="shareUrl" (focus)="selectAndCopy($event, 'url')">
        <button matSuffix type="button" mat-raised-button color="accent" aria-label="copy">Copy</button>
      </mat-form-field>
      <h3>On social media</h3>
      <share-buttons (click)="false" showText="true" [include]="['copy', 'facebook', 'whatsapp' , 'messenger']" [url]="shareUrl" fxFlexAlign="center">
      </share-buttons>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="yo-theme" fxlayoutAlign="start center">
      <h3 fxFlex>Embed in your website</h3>
      <mat-slide-toggle color="primary" name="embedInWebsite" [(ngModel)]="embedInWebsite"></mat-slide-toggle>
    </div>
    <div *ngIf='embedInWebsite' fxLayout="column" fxLayoutGap="0.5em" fxLayoutAlign="center stretch">
      <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="1em" fxFlexAlign="stretch">
        <mat-form-field>
          <mat-label>Player width, px</mat-label>
          <input type="text" [value]="playerSize" type="number" (input)="playerSizeChanged('input', $event)"name="playerSize" matInput placeholder="Custom width, px">
        </mat-form-field>
        <mat-button-toggle-group class="input-like" (change)="playerSizeChanged('toggle', $event)" name="playerSize" [value]="playerSize">
          <ng-template ngFor let-item [ngForOf]="predefinedPlayerHeights">
            <mat-button-toggle [value]="item">{{item}}</mat-button-toggle>
          </ng-template>
        </mat-button-toggle-group>
      </div>
      <!--<mat-slide-toggle color="primary" name="allowFullscreen" checked="allowFullscreen" (change)="onCheckboxChange($event)">Allow fullscreen</mat-slide-toggle>-->
      <!--<mat-checkbox name="compactCode" (change)="onCheckboxChange($event)">Compact code</mat-checkbox>-->
      <mat-form-field>
        <mat-label>Add the following code to your web page</mat-label>
        <textarea rows="5" matInput [value]="generatedCode" (focus)="selectAndCopy($event, 'code')"></textarea>
      </mat-form-field>
      <mat-label>
        If you use the <em>sandbox</em> attribute please make sure you give at least the following permissions: <em>allow-scripts allow-same-origin allow-presentation</em>
      </mat-label>
    </div>
  </form>
</div>
