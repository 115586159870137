import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { filter, map } from "rxjs/operators";

import { ChannelService } from "../../shared/channel.service";
import { Livestream } from "src/app/core/model/interfaces/livestream";
import { LivestreamsService } from "src/app/core/model/services/livestreams.service";
import { TeamPublic } from "src/app/core/model/interfaces/teamPublic";

@Component({
  selector: 'yo-streams-section',
  templateUrl: '../streams/streams.component.html',
  styleUrls: ['../streams/streams.component.scss']
})
export class StreamsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  streams$: Observable<Livestream[]>;
  teams: TeamPublic[] = [];
  selectedTeamId: string = undefined;

  constructor(
    private livestreamService: LivestreamsService,
    private channelService: ChannelService,
  ) { }

  ngOnInit(): void {
    this.streams$ = this.channelService.upcomingEvents$.pipe(
      filter(streams => streams !== undefined),
      map(streams => this.livestreamService.sortByStartTime(streams.filter(s => !s.archived), "asc")),
    );

    this.channelService.subchannels$.pipe(filter(subchannels => subchannels !== undefined)).subscribe(subchannels => this.teams = subchannels);
    this.channelService.selectedSubchannel$.subscribe(selectedSubchannel => this.selectedTeamId = selectedSubchannel);
  }


  subchannelSelected(teamId) {
    this.channelService.updateSelectedSubchannel(teamId);
  }

  ngOnDestroy(): void {
    // Destroy any remaining Obs
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}