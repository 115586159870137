import { Observable, from } from 'rxjs';

import { Injector } from '@angular/core';
import { map } from 'rxjs/operators';

export type Callable<Input, Output> = (data: Input) => Observable<Output>;

export type _I<T> = T extends Callable<infer Input, any> ? Input : never;
export type _O<T> = T extends Callable<any, infer Output> ? Output : never;

export function declareCallable<F extends Callable<any, any>>(functionName: string, injector: Injector): (input: _I<F>) => Observable<_O<F>> {
  return (params: _I<F>): Observable<_O<F>> => {
    return from(
      import('@angular/fire/functions').then(
        ({httpsCallable, Functions}) => {
          return httpsCallable<_I<F>, _O<F>>(injector.get(Functions), functionName)(params);
        }
      )
    ).pipe(
      // tap(x => console.log("Result of callable", x)),
      map(r =>  r.data)
    );
  };
}

