import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ClientService } from "src/app/core/utility/client.service";
import { AuthService } from "src/app/core/auth/auth.service";
import { startWith } from "rxjs/operators";

@Component({
  selector: 'yo-arena-header',
  templateUrl: './arena-header.component.html',
  styleUrls: ['./arena-header.component.scss']
})
export class ArenaHeaderComponent implements OnInit {
  @Output() onToggleSideNav = new EventEmitter();
  @Output() onToggleLogin = new EventEmitter();
  @Input() hasManageAccess: boolean = false;
  @Input() activeLink?: string;

  isMobileView: boolean = false;
  isMobileSearchActive: boolean = false;

  readonly user$ = this.authService.authenticatedUser$.pipe(
    startWith(null)
  );

  constructor(
    private authService: AuthService,
    private clientService: ClientService
  ) {}

  ngOnInit() {
    this.isMobileView = this.clientService.isMobileDevice();
  }
}
