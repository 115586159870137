import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarComponent, SnackBarData } from 'src/app/yoimo-ui/snack-bar/snack-bar.component';

import { Injectable } from '@angular/core';

export type SNACK_BAR_TYPE = "SUCCESS" | "ERROR" | "INFO";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(
    message: string,
    snackType: SNACK_BAR_TYPE,
    snackIcon?: string,
    duration: number = 2000,
    dismissable = true
  ): MatSnackBarRef<SnackBarComponent> {
    const data: SnackBarData = {
      message,
      snackType,
      snackIcon,
      dismissable,
    };

    let classes = ["snackbar"];
    switch (snackType) {
      case "ERROR":
        classes.push("error");
        break;
      case "SUCCESS":
        classes.push("success");
        break;
      case "INFO":
        classes.push("info");
        break;
      default:
        break;
    }

    return this.snackBar.openFromComponent(SnackBarComponent, {
      duration,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: classes,
      data
    });
  }

}
