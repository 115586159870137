<div fxLayout="column" style="width: 100%" class="yo-theme" fxLayoutGap="0.5em" fxLayoutAlign="center stretch">
  <div *ngIf='!availableToStream' class="connect-endpoint-not-ready">
    <mat-form-field class="full-width">
      <mat-label>Stream Settings:</mat-label>
      <textarea matInput hidden></textarea>
      <mat-hint>The stream has ended and the endpoint is not available.</mat-hint>
    </mat-form-field>
  </div>
  <div *ngIf='availableToStream && rtmpEndpoint===undefined' class="connect-endpoint-not-ready">
    <mat-form-field class="full-width">
      <mat-label>Stream Settings:</mat-label>
      <textarea matInput hidden></textarea>
      <mat-hint>We will generate an RTMP/Server URL and a stream key {{AvailableTimeBeforeStart}} before the stream starts and send it to your email <b>{{authenticatedUserEmail}}</b>.</mat-hint>
    </mat-form-field>
  </div>
  <ng-container *ngIf='availableToStream && rtmpEndpoint!==undefined'>
    <mat-form-field class="full-width suffix-button-attached-to-input">
      <input type="text" matInput readonly="readonly" placeholder="RTMP/Server URL" [value]="rtmpEndpoint" (focus)="selectAndCopy($event)">
      <button matSuffix type="button" mat-raised-button color="accent" aria-label="copy">Copy</button>
    </mat-form-field>
    <mat-form-field class="full-width suffix-button-attached-to-input">
      <input type="text" matInput readonly="readonly" placeholder="Stream Key" [value]="rtmpStreamKey" (focus)="selectAndCopy($event)">
      <button matSuffix type="button" mat-raised-button color="accent" aria-label="copy">Copy</button>
    </mat-form-field>
  </ng-container>
</div>
