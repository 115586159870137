import { ActivatedRoute, PRIMARY_OUTLET, Router, UrlTree } from '@angular/router';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';

import { AuthService } from '../core/auth/auth.service';
import { ClubsService } from '../core/model/services/clubs.service';
import { HeaderService } from '../core/utility/header.service';
import { MatSidenav } from '@angular/material/sidenav';
import { RoutingStateService } from '../core/utility/routing-state.service';
import { TeamsService } from '../core/model/services/teams.service';
import { User } from '../core/model/interfaces/user';
import { ViewService } from '../core/utility/view.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'yo-arena',
  templateUrl: './arena.component.html',
  styleUrls: ['./arena.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArenaComponent implements OnInit, OnDestroy {

  @ViewChild('scrollContainer', { static: true }) private scrollContainer: ElementRef;
  @ViewChild(MatSidenav, { static: true }) private sidenav: MatSidenav;

  private ngUnsubscribe = new Subject();
  activeLink = 'browse';
  intervalId: any;
  sideNavToggled: boolean = false;
  user: User;
  userLogged: boolean = false;
  hasManageAccess: boolean = false;

  constructor(
    private authService: AuthService,
    private headerService: HeaderService,
    private router: Router,
    private routingStateService: RoutingStateService,
    private teamService: TeamsService,
    private clubsService: ClubsService,
  ) { }

  ngOnInit() {
    this.headerService.updateHeaderConfig({ text: 'Joymo', navigateBackTo: null });

    // this.authService.authenticatedUser$.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(
    //   user => this.userLogged = user ? true : false,
    //   err => console.error(err)
    // );

    this.routingStateService.segmentStream.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      segments => {
        this.activeLink = segments.map(segment => segment.path).join('-');
        console.log("Active Link", this.activeLink);
      },
      err => console.error(err)
    );

    // this.clubsService.clubsWhereAdminOrTrainer$.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(
    //   clubs => this.hasManageAccess = !!(clubs && clubs.length > 0),
    //   err => console.log('An error occured while checking access to manage section')
    // );

    combineLatest([this.authService.authenticatedUser$, this.clubsService.userIsAdminOrTrainer$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        ([user, isAdminOrTrainer]) => {
          this.user = user;
          this.userLogged = user ? true : false;
          this.hasManageAccess = isAdminOrTrainer;
        },
        err => console.log('An error occured while checking access to manage section')
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleLogin() {
    if (this.userLogged) {
      this.userLogged = false;
      this.authService.logout();
      this.router.navigate(["/browse"]);
      return;
    }
    this.authService.useSessionCookie(true);
  }
}
