<section
  class="cover"
  [ngClass]="[image || video ? 'cover__with-media' : 'cover__no-media']"
>
  <video [src]="video" [poster]="image" data-paused="true"></video>

  <div *ngIf="image && video" class="cover--play">
    <button type="button" role="play-video">
      <img alt="Play cover video" src="assets/icons/play.svg" />
    </button>
  </div>

  <figure class="cover--header">
    <div class="cover--avatar">
      <img *ngIf="avatar" [src]="avatar" [alt]="name" />
    </div>
    <figcaption>
      <h1>
        <strong>{{ name }}</strong>
      </h1>
      <p class="cover--exclusive" *ngIf="isExclusive">
        <img
          src="assets/icons/star.svg"
          alt="This channel is part of the Joymo Exclusive experience"
        />
        Exclusively on Joymo
      </p>
    </figcaption>
  </figure>

  <section class="cover--description">
    <ng-container *ngIf="description">
      <p>{{ description }}</p>
      <br />
    </ng-container>
    <yo-social-icons type="club" [docId]="clubId"></yo-social-icons>
  </section>
</section>
