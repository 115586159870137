import { Injectable, Injector } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { Timestamp, arrayUnion } from '@angular/fire/firestore';
import { map, switchMap, take } from 'rxjs/operators';

import { Collection } from '../collection';
import { LivestreamGroup } from '../interfaces/livestream-group';
import { PaymentsHistoryService } from './payments-history.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LivestreamGroupsService extends Collection<LivestreamGroup> {

  constructor(
    injector: Injector,
    private paymentsHistoryService: PaymentsHistoryService,
  ) {
    super(injector, 'livestreamGroups', true);
    console.warn('Using the default converter for LivestreamGroupsService');
  }

  hasViewingRights(livestreamGroupId: string, userDocId: string): Observable<boolean> {
      return this.paymentsHistoryService.getProduct(
        userDocId,
        livestreamGroupId
      ).pipe(
        take(1),
        map( p => !!p && p.access && p.expiracyDate.toMillis() > Date.now())
      );
  }

  getPublicGroups(): Observable<LivestreamGroup[]> {
    return this.query(['isPublic', '==', true]);
  }

  getByClubDocId(docId: string): Observable<LivestreamGroup[]> {
    return this.query([
      ['clubDocId', '==', docId],
      ['stopTime', '>', new Date()],
    ]);
  }
  getWhereOwnerOrBuyer(userDocId: string): Observable<LivestreamGroup[]> {
    const ownerSnapshots = this.query(['ownerDocId', '==', userDocId]);
    const buyerSnapshots = this.query(['buyerDocIds', 'array-contains', userDocId]);
    return combineLatest([buyerSnapshots, ownerSnapshots]).pipe(
      map(([buyer, owner]) => [...buyer, ...owner])
    );
  }

  attachLivestreamToGroup(livestreamDocId: string, groupDocId: string): Promise<void> {
    return this.update(groupDocId, {
      livestreamDocIds: arrayUnion(livestreamDocId)
    });
  }

  sortByName(livestreamGroups: LivestreamGroup[]): LivestreamGroup[] {
    return livestreamGroups.sort((a, b) => {
      const livestreamGroupAName = a.name.toLowerCase();
      const livestreamGroupBName = b.name.toLowerCase();

      if (livestreamGroupAName < livestreamGroupBName) {
        // sort ascending
        return -1;
      }
      if (livestreamGroupAName > livestreamGroupBName) {
        return 1;
      }
      return 0;
    });
  }

  getGroupsForLivestream(streamDocId: string): Observable<LivestreamGroup[]> {
    // console.log('Getting streams for ', streamDocId);
    return this.query(['livestreamDocIds', 'array-contains', streamDocId]).pipe(
      // Read the master groups containing the listed groups.
      switchMap((groups) => {
        return combineLatest([
          of(groups),
          groups.length > 0
            ? this.query(['subGroups', 'array-contains-any', groups.map((g) => g.docId )])
            : of([] as LivestreamGroup[]),
        ]);
      }),
      // flatten the list, cast to Model and filter unavailable.
      map(([simpleGroups, parentGroups]) => {
        return [simpleGroups, parentGroups]
          .reduce((acc, x) => [...acc, ...x], [] as LivestreamGroup[])
          .filter(group => {
            const { vodPrice, bundlePrice, price, stopTime, isPublic, availableForVod } = group;
            return (
              isPublic &&
              (stopTime.toMillis() < Date.now()
                ? availableForVod && vodPrice !== undefined
                : (price !== undefined || bundlePrice !== undefined)
              )
            );
          });
      })
    );

  }

  getStartTime(stream: LivestreamGroup) {
    const startTime = stream.startTime;
    return moment(new Timestamp(startTime.seconds, startTime.nanoseconds).toDate()).calendar(null, {
      // sameDay: '[Today] - HH:mm',
      // nextDay: '[Tomorrow] - HH:mm',
      // nextWeek: 'dddd - HH:mm',
      // lastDay: '[Yesterday] - HH:mm',
      // lastWeek: '[Last] dddd - HH:mm',
      sameDay: '[Today] - HH:mm',
      nextDay: '[Tomorrow] - HH:mm',
      nextWeek: 'DD/MM/YYYY - HH:mm',
      lastDay: 'DD/MM/YYYY - HH:mm',
      lastWeek: 'DD/MM/YYYY - HH:mm',
      sameElse: 'DD/MM/YYYY - HH:mm'
    });
  }

  getStopTime(stream: LivestreamGroup) {
    const stopTime = stream.stopTime;
    return moment(new Timestamp(stopTime.seconds, stopTime.nanoseconds).toDate()).calendar(null, {
      sameDay: '[Today] - HH:mm',
      nextDay: '[Tomorrow] - HH:mm',
      nextWeek: 'DD/MM/YYYY - HH:mm',
      lastDay: 'DD/MM/YYYY - HH:mm',
      lastWeek: 'DD/MM/YYYY - HH:mm',
      sameElse: 'DD/MM/YYYY - HH:mm'
    });
  }
}
