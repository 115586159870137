import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AuthService } from "./auth.service";

/**
 * Checks if the user is authenticated with the invitation email.
 *
 * @remarks Guarded URL is expected to have a query param named "bu" (?bu=some@xample.com)
 */
@Injectable({ providedIn: "root" })
export class BoundAuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(
    _r: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const boundUserParamKey = "bu";
    const boundUser = state.root.queryParamMap.get(boundUserParamKey)

    return this.authService.authenticatedUser$.pipe(
      map((user) => {
        if (user?.email !== boundUser) {
          alert(`You must log in or register as "${boundUser}" to continue`);
          this.authService.goToAuthApp({ [boundUserParamKey]: boundUser });
          return false;
        }
        return true;
      })
    );
  }
}
