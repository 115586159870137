import { Directive, EventEmitter, Output } from "@angular/core";
import { ClientService } from "src/app/core/utility/client.service";
import { EventCardComponentInput } from "src/app/yoimo-ui/cards/event/event-card.component";
import { Club } from "../search.model";

@Directive({
  selector: "search-result-tab",
})
export abstract class SearchResultTabDirective {
  @Output() loadMore = new EventEmitter();

  constructor(protected clientService: ClientService) {}

  get isMobileView() {
    return this.clientService.isMobileDevice();
  }

  trackElements(
    index: number,
    element: Club | EventCardComponentInput
  ): string | null {
    return element["docId"] || element["id"] || null;
  }

  loadMoreDocs() {
    this.loadMore.emit();
  }
}
