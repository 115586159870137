import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Directive({
  selector: '[yoLast]'
})
export class LastDirective implements OnInit {

  @Input() isLast: boolean;
  @Output() lastDone: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    if (this.isLast) {
      this.lastDone.emit(true);
    }
  }

}
