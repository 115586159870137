<div *ngIf='!isInWebView; else inApp'
     class='container'
     ngClass.lt-md='handset'
     gdAreas="header-left header header-right | left mid right | footer footer footer"
     gdAreas.lt-md="header-left header header-right | mid | footer"
     gdGap="5px"
     gdRows="auto auto auto" >

  <div gdArea.gt-sm="header-left" gdArea.lt-md="header">
    <a class="app-logo" [routerLink]="['/browse']">
      <object class="logo" data="assets/Joymo_Logo_Green_CMYK.svg" type="image/svg+xml" alt="Joymo logo"></object>
    </a>
  </div>
  <div gdArea.gt-sm="header-right" fxHide.lt-md>
    <!-- <yo-social-direct-links class="text-align-right"></yo-social-direct-links> -->
  </div>
  <div gdArea="mid">
    <div class="login-form-container" [ngSwitch]='mode'>
      <yo-login-form *ngSwitchCase='"LOGIN"' [targetEmail]='targetEmail' (isLoggedIn)="loggedIn()"></yo-login-form>
      <yo-login-form *ngSwitchCase='"REGISTER"' [loginMode]='LoginMode.REGISTER' [targetEmail]='targetEmail'
        (isLoggedIn)="loggedIn()"></yo-login-form>
      <yo-reset-password-form *ngSwitchCase='"RESET_PASSWORD"' mode="PASSWORD_RESET"
        (passwordSuccessfullyReset)="passwordSuccessfullyReset()"></yo-reset-password-form>
      <yo-reset-password-form *ngSwitchCase='"FINISH_PREREGISTRATION"' mode="FINISH_PREREGISTRATION"
        (passwordSuccessfullyReset)="passwordSuccessfullyReset()"></yo-reset-password-form>
    </div>
  </div>
  <div fxLayout='row' fxLayoutAlign="space-between end" gdArea="footer">
    <div fxFlex="1 1" class="footer-copyright">
      &copy; 2016-{{ currentDate | date:'yyyy'}} <strong>JOYMO AS</strong>
    </div>
  </div>
</div>
<ng-template #inApp>
  <div class="container in-app"
       fxLayout="column"
       fxWrap="wrap"
       fxLayoutAlign="center"
       >
    <object class="app-logo" data="assets/Joymo_Logo_Green_CMYK.svg" type="image/svg+xml" alt="Joymo logo" fxFlexAlign="center"></object>
    <div class="login-form-container"
      [ngSwitch]='mode'
      fxLayout="column" fxFlexAlign="center"
      >
      <yo-login-form *ngSwitchCase='"LOGIN"' (isLoggedIn)="loggedIn()"></yo-login-form>
      <yo-reset-password-form *ngSwitchCase='"RESET_PASSWORD"'
        mode="PASSWORD_RESET"
        (passwordSuccessfullyReset)="passwordSuccessfullyReset()"></yo-reset-password-form>
      <yo-reset-password-form *ngSwitchCase='"FINISH_PREREGISTRATION"'
        mode="FINISH_PREREGISTRATION"
        (passwordSuccessfullyReset)="passwordSuccessfullyReset()"></yo-reset-password-form>
    </div>
  </div>
</ng-template>
