<section>
  <mat-grid-list
    [cols]="isMobileView ? 2 : 6"
    rowHeight="191px"
    [gutterSize]="breakpoint.gutterSize + 'rem'"
  >
    <mat-grid-tile
      *ngFor="
        let item of clubs;
        let key = index;
        trackBy: trackElements
      "
    >
      <yo-channel-card
        class="yo-theme card-holder"
        [club]="item"
      ></yo-channel-card>
    </mat-grid-tile>
  </mat-grid-list>
  <!-- load more -->
  <footer>
    <p *ngIf="!canLoadMore">You've reached the end of this page.</p>
    <ng-container *ngIf="canLoadMore">
      <button (click)="loadMoreDocs()" mat-stroked-button type="button">
        <ng-container *ngIf="!isLoading; else spinner">
          Load more
        </ng-container>
        <ng-template #spinner>
          <yo-spinner></yo-spinner>
        </ng-template>
      </button>
    </ng-container>
  </footer>
</section>
