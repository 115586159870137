import { ActivatedRoute } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";

import { filter, map, switchMap, takeUntil, tap } from "rxjs/operators";
import { combineLatest, Subject } from "rxjs";

import { ChannelService } from "../shared/channel.service";
import { ClubPublic } from "src/app/core/model/interfaces/club-public";
import { TeamPublic } from "src/app/core/model/interfaces/teamPublic";

import { Highlight } from "src/app/core/model/interfaces/short";

@Component({
  selector: "yo-channel-layout",
  templateUrl: "./channel-layout.component.html",
  styleUrls: ["./channel-layout.component.scss"],
})
export class ChannelLayoutComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  hasStreams = true;
  hasReplays = true;
  hasPlaylists = true;

  club: Partial<ClubPublic> = {};
  clubDocId: string;
  teams: TeamPublic[];
  selectedTeamId: string = undefined;
  selectedTeam: string;
  highlights: Highlight[] = [];

  constructor(
    private channelService: ChannelService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data
      .pipe(
        tap((data) => this.setInitialData(data.club)),
        switchMap(() => {
          return combineLatest([
            this.channelService.subchannels$.pipe(
              filter((subchannels) => subchannels !== undefined)
            ),
            this.channelService.selectedSubchannel$,
          ]);
        }),
        tap(([teams, selectedTeamId]) => {
          this.teams = teams;
          this.selectedTeamId = selectedTeamId;
          this.updateSelectedTeam();
        }),
        switchMap(() => this.listenToEntities()),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(([streams, replays, playlists, shorts]) => {
        console.log("Listening to entities...");
        this.hasStreams = streams.length > 0;
        this.hasReplays = replays.length > 0;
        this.hasPlaylists = playlists.length > 0;

        // Sort clips according to the order specified in the backoffice
        this.highlights = this.club.clips?.map((docId) =>
          shorts.find((short) => short.docId === docId)
        );

        document
          .getElementsByTagName("mat-sidenav-content")
          .item(0).scrollTop = 0;
      });
  }

  setInitialData(club: ClubPublic) {
    this.club = club;
    this.clubDocId = this.club.docId;
    this.channelService.channelId = this.clubDocId;

    this.channelService.updateSelectedSubchannel(undefined);
    let teamId = this.route.snapshot.queryParamMap.get("team");
    if (teamId) {
      this.channelService.updateSelectedSubchannel(teamId);
      this.selectedTeamId = teamId;
    }

    this.channelService.fetchSubchannels();
    this.channelService.fetchUpcomingEvents();
    this.channelService.fetchPastEvents();
    this.channelService.fetchPlaylists();
    this.channelService.fetchShorts(club.clips);
  }

  onTeamSelected(teamDocId: string) {
    this.channelService.updateSelectedSubchannel(teamDocId);
  }

  listenToEntities() {
    return combineLatest([
      this.channelService.upcomingEvents$.pipe(
        filter((entities) => entities !== undefined),
        map((events) => events.filter((e) => !e.archived))
      ),
      this.channelService.pastEvents$.pipe(
        filter((entities) => entities !== undefined),
        map((events) => events.filter((e) => !e.archived))
      ),
      this.channelService.playlists$.pipe(
        filter((entities) => entities !== undefined)
      ),
      this.channelService.shorts$.pipe(filter((entities) => !!entities)),
    ]);
  }

  updateSelectedTeam() {
    if (this.teams && this.selectedTeamId) {
      this.selectedTeam =
        this.teams.find((t) => t.docId == this.selectedTeamId).name ||
        undefined;
    } else {
      this.selectedTeam = undefined;
    }

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.channelService.unsubscribeAll();
    this.channelService.updateSelectedSubchannel(undefined);
  }
}
