import { RouterModule, Routes } from "@angular/router";

import { ChannelLayoutComponent } from "./channel-layout/channel-layout.component";
import { ChannelPlaylistsComponent } from "./channel-playlists/channel-playlists.component";
import { ChannelStreamsComponent } from "./channel-streams/channel-streams.component";
import { NgModule } from "@angular/core";

const routes: Routes = [
  {
    path: "",
    component: ChannelLayoutComponent
  },
  {
    path: "streams",
    component: ChannelStreamsComponent
  },
  {
    path: "playlists",
    component: ChannelPlaylistsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ChannelRoutingModule { }
