<h2>Welcome to the Joymo Universe!</h2>
<p>{{inviteMessage}}</p>
<h2>Terms and conditions</h2>

<h5>1. Tjenesten</h5>
<p>JOYMO AS («JOYMO») har utviklet og selger en løsning for online overføring av visuell film
(streaming), analyse, redigering og deling av filmopptak «Tjenesten». Tjenesten er rettet mot
bruk i idrettssammenheng. Tjenesten består av opptaksutstyr og en tilknyttet skyløsning
tilgjengelig via en nedlastbar applikasjon eller webløsning. Tjenesten kan brukes av alle
idrettens organisasjonsledd (eks. særforbund, idrettslag m.fl.) tilsluttet NIF (Norges
idrettsforbund og olympiske og paralympiske komité) eller næringsdrivende.<br>
JOYMO ønsker å tilby en tjeneste som forenkler og skaper verdi for sine Kunder og Brukere.<br>
Disse tjenestevilkårene gir informasjon om Tjenesten, samt partenes plikter.</p>

<h5>2. Definisjoner</h5>
<p>Kunden er idrettens organisasjonsledd til NIF eller næringsdrivende som inngår avtale om
bruk av tjenesten.<br>
Kunde er selv ansvarlig for opptaksutstyr som selges i forbindelse med Tjenesten.<br>
Bruker forstås som en som avvender Tjenesten.<br>
Brukerprofil forstås som profiler med forskjellig tilgangs og rettighetsnivå, basert på Kundens
kjøp av produkter i Tjenesten.<br>
«Administrator» er den eller de Kunden utpeker til å kontrollere for eksempel, men ikke
begrenset til, streaming, opptaksfunksjoner, deling av opptak og administrasjon av rettigheter
internt hos Kunden.<br>
«Medlem» forstås som den som gis tilgang av Administrator til å se opptak og streaming i
Kundens lukkede forum i Tjenesten.<br>
«Streamers» (opptakere via app) forstås som personer som tar opp innhold via opptaksapp
på vegne av Kunden.<br>
Fast Installasjon forstås som opptaksutstyr levert av JOYMO som Kunden monterer på en fast,
statisk lokasjon.</p>

<h5>3. Abonnement og tildeling av Brukerprofil</h5>
<p>Kunden har rett til å bruke Tjenesten i henhold til Tjenestevilkårene så lenge Kunden har et
gyldig og betalt abonnement. Kunden gis en begrenset, gjenkallelig, ikke-eksklusiv og ikkeoverførbar rett til å bruke
Tjenesten i samsvar med Tjenestevilkårene.<br>
Kunden må selv styre tilganger til forskjellige Brukerprofiler basert på produktene Kunden har
valgt i Tjenesten. Det er Kunden som inviterer Brukere til å benytte Tjenesten. Kunden kan
også fjerne Brukere. Brukeres tilgang opphører uansett samtidig med at Kundens abonnement
avsluttes.</p>

<h5>4. Bruker</h5>
<p>Bruker gis en begrenset, gjenkallelig, ikke-eksklusiv og ikke-overførbar rett til å bruke
Tjenesten i samsvar med Tjenestevilkårene. Bruker har rett til å benytte Tjenesten i henhold
til Tjenestevilkårene.</p>

<h5>5. Opptaksutstyr</h5>
<p>Bruker er ansvarlig for at opptaksutstyr som er nødvendig for å anvende Tjenesten er plassert
og installert korrekt. Bruker må sørge for å ha alle nødvendige tillatelser til å anvende
Tjenesten.<br>
For å benytte Tjenesten kan det være behov for eksempelvis 4G/5G og datatrafikk. Dette er
Kundens og/eller Brukers ansvar og kostnad.</p>

<h5>6. Pris og betalingsbetingelser</h5>
<p>Priser for Tjenesten med de produkter Kunden og/eller Bruker velger fremkommer i
forbindelse med bestilling. JOYMO kan endre priser på inngåtte avtaler med 30 dagers varsel.<br>
Kunden og/eller Bruker mottar en bekreftelse på inngått avtale til sin oppgitte e-postadresse
etter bestilling.</p>

<h5>7. Kundens inntekter</h5>
<p>Kunde kan ha rett til andel av inntektene fra bruk av Tjenesten.</p>

<h5>8. Opphavsrettigheter</h5>
<p>Kunden har opphavsrett til bilder og filmopptak fra egne arrangementer gjennom Tjenesten.<br>
Kunden må ha oversikt over eventuelle rettigheter Kunden ikke besitter. JOYMO kan likevel
benytte kameraet til å selge begrenset tilgang til streaming fra kameraet til andre arrangement
fra lokasjonen til andre kringkastere.</p>

<h5>9. Markedsrettigheter</h5>
<p>JOYMO har rett til å vise markedsføring både for egne produkter og tjenester eller
tredjeparters produkter og tjenester i alle tjenester JOYMO tilbyr, med mindre JOYMO har
fraskrevet seg denne retten helt eller delvis i skriftlig avtale med Kunden.<br>
Bruker erklærer og garanterer at Bruker har adgang til å inngå herværende avtale, har
nødvendige visningsrettigheter til arrangementet, og om nødvendig at den har innhentet
nødvendige samtykker fra sitt idrettslag, særforbund eller andre parter.</p>

<h5>10. Kundens ansvar for sikkerhet</h5>
<p>Det er Brukers ansvar å sikre seg mot uautorisert tilgang til Tjenestene. Der Tjenester leveres
med personlige koder, herunder passord eller PIN-koder, skal Bruker endre forhåndsoppsatte
koder til personlige koder straks Tjenesten tas i bruk. Koder skal oppbevares på forsvarlig vis
slik at uvedkommende ikke får tilgang til dem. Bruker er ansvarlig for all bruk av Tjenestene.<br>
Dette innebærer også at Bruker er ansvarlig for eventuelt misbruk av Tjenestene, herunder
at Tjenestene benyttes av andre personer enn Bruker selv. Ved mistanke om uautorisert
tilgang til Tjenestene skal Bruker straks informere JOYMO. JOYMO kan om nødvendig
midlertidig stenge Tjenestene for Bruker.<br>
Faste avgifter faktureres som normalt i den perioden Tjenestene er stengt, og Kunden og/eller
Bruker belastes gjeldende gjenåpningsgebyr ved gjenåpning. JOYMO kan ikke holdes
ansvarlig for misbruk av Tjenestene. med mindre slikt misbruk skyldes grov uaktsomhet fra
JOYMOs side.</p>

<h5>11. Avslutning av Tjeneste og plikter ved Avtalens opphør</h5>
<p>Tjenesten, inkludert enkeltprodukter i Tjenesten, fornyes automatisk om det ikke sies opp en
måned før det løper ut. Tidligere betalinger vil ikke refunderes.<br>
Kunden kan ellers si opp Tjenesten med tre måneds varsel. Er Kunden i bindingstid ved
oppsigelse av Tjenesten utløses et bruddgebyr. Dette gjelder også dersom JOYMO hever
avtalen om Tjenesten som følge av mislighold fra Kunden og/eller Brukers side.<br>
Ved opphør av Tjenesten vil Kunden og Brukere miste tilgang til alt Tjenesten inneholder, og
all data som er lastet opp i Tjenesten vil kunne slettes. JOYMO vil beholde dataene i Tjenesten
i 10 dager etter Abonnementsavtalens opphør. Deretter, og senest 30 dager etter
Abonnementsavtalen opphør, vil JOYMO irreversibelt slette alle data i Tjenesten som Kunden
og/eller Bruker har lastet opp.</p>

<h5>12. Tekniske krav</h5>
<p>Tjenesten forutsetter internettilgang, nettleser og eventuell nedlasting av applikasjon.<br>
Ytterligere krav kan være stilles, og fremkommer som vedlegg i avtale mellom JOYMO og
Kunden.<br>
Tekniske krav kan endres av JOYMO uten forvarsel. Endring av tekniske krav vil ikke ha noen
innvirkning på Brukers videre bruk av Tjenesten, men manglende oppfyllelse av de tekniske
kravene kan medføre at senere oppdateringer og utvidelser ikke blir tilgjengelige eller at
Tjenesten er utilgjengelig.</p>

<h5>13. Tjenestenivå, feilretting og mangelskrav</h5>
<p>Tjenesten tilbys som den er og som en standardisert tjeneste tilgjengelig for alle som har et
gyldig og betalt abonnement. Tjenesten skal imidlertid oppfylle de krav som i alminnelighet
stilles til denne type tjenester når det gjelder kvalitet og tilgjengelighet. Et abonnement gir
rettigheter til å bruke Tjenesten slik den er til enhver tid, og Kunden og/eller Brukers bruk er
ikke begrenset til en spesifikk versjon eller funksjonalitet. JOYMO tar ikke ansvar for at
Tjenesten passer for de spesifikke formål Kunden og/eller Bruker har for å bruke Tjenesten.<br>
JOYMO forbeholder seg retten til å gjøre forbedringer, tillegg, endringer eller å fjerne
funksjonalitet. JOYMO kan imidlertid ikke fjerne funksjonalitet som etter JOYMOs oppfatning
må anses som kjernefunksjoner ved Tjenesten.<br>
Bruker er innforstått med at Tjenesten ikke alltid vil være tilgjengelig eller uten feil, og at
forbedring av Tjenesten er en løpende prosess. Bruker er også innforstått med at vellykket
bruk av Tjenesten er avhengig av utstyr og aktører som Bruker selv er ansvarlig for (slik som
for eksempel tilfredsstillende internettilgang) eller som JOYMO ikke svarer for. JOYMO er ikke
ansvarlig for feil eller avbrudd ved bruk av Tjenesten som er en følge av feil hos tredjeparters
eller Brukers forhold som er nødvendige for å kunne benytte Tjenesten, herunder, men ikke
uttømmende, feil ved internettilknytning, nettlesere, operativsystemer eller annen
programvare fra tredjeparter. Tredjeparters oppdatering av programvare eller
operativsystemer kan påvirke bruken av Tjenesten, og JOYMO har ikke ansvar for dette.<br>
JOYMO vil imidlertid etter beste evne tilrettelegge og utvikle Tjenesten på bakgrunn av
oppdateringer o.l. av programvare eller maskinvare som JOYMO støtter iht. Tekniske krav
over. Krav på prisavslag må fremsettes av Kunden og/eller Bruker senest 14 dager etter
Kunden og/eller Bruker varslet om feilen per e-post til info@joymo.tv.<br>
Planlagt nedetid anses ikke som en feil, og gir ikke rett til prisavslag eller andre
misligholdsbeføyelser. Nedetid kan være nødvendig for å oppdatere eller vedlikeholde
hardware eller software, og kan gjøres inntil 10 ganger hvert kalenderår. Planlagt nedetid skal
varsles Kunden senest 7 dager i forkant og kan vare opptil 24 timer.<br>
Tilgjengelighet garanteres med forbehold om Force Majeure. Force Majeure er forhold utenfor
partenes kontroll som gjør det umulig eller urimelig vanskelig/kostbart for partene å oppfylle
parten sine forpliktelser. Etter alminnelig kontraktsrett vil JOYMO bli fritatt for leveringsplikt
så lenge i den forstand at man ikke vil være erstatningsansvarlig for manglende levering i
slike tilfeller.</p>

<h5>14. Personopplysninger</h5>
<p>JOYMO behandler personopplysninger Bruker oppgir eller som Bruker laster opp i Tjenesten
kun for å oppfylle JOYMOs forpliktelser etter Tjenestevilkårene. For nærmere informasjon om
JOYMOs behandling av personopplysninger og de registrertes rettigheter, se JOYMOs
Personvernerklæring som er tilgjengelig her: www.JOYMO.tv/privacy
Bruker er ansvarlig for å overholde lover og regler, herunder krav til informasjon og for å
innhente nødvendige samtykker etter GDPR.<br>
JOYMO er behandlingsansvarlig for de personopplysningene som Kunden gir i forbindelse med
etablering av Tjenestevilkårene, men er ikke å regne som behandlingsansvarlig for de data
Kunden laster opp i Tjenesten.</p>

<h5>15. Kundens ansvar for bruk av Tjenesten og skadesløsholdelse</h5>
<p>Bruker skal ikke bruke Tjenesten på en måte som er i strid med lov, krenker noens rettigheter
eller skader eller påvirker Tjenesten, og forplikter seg til Tjenestevilkårene og særlig
forpliktelsene i dette avsnittet. Bruker skal varsle JOYMO umiddelbart ved uautorisert
utlevering eller bruk av Brukeres innloggingsdetaljer. JOYMO er ikke ansvarlig for tap Kunden
og/eller Bruker påføres som et resultat av uautorisert bruk av Kundens eller Brukeres
innloggingsdetaljer. Kunden og/eller Bruker kan derimot holdes ansvarlig overfor JOYMO for
tap JOYMO pådrar seg på grunn av tredjeparters uautoriserte bruk av Kundens eller Brukeres
innloggingsdetaljer.<br>
JOYMO har ingen plikt til å overvåke Kunden eller Brukers bruk av Tjenesten for å sikre
etterlevelse av Tjenestevilkårene eller norsk lov. JOYMO kan imidlertid endre og slette
informasjon og data, helt eller delvis, ved rimelig mistanke om brudd på Tjenestevilkårene
eller norsk lov, og særlig dette punkt i avtalen.<br>
Kunden eier og er ansvarlig for all data og informasjon som Kunden laster opp eller gjør
tilgjengelig i Tjenesten, inkludert personopplysninger. Kunden er pliktig å besørge for
behandlingsgrunnlag for behandling av personopplysninger der dette er nødvendig. Kunden
og/eller Bruker er også pliktig å innhente samtykke fra involverte, herunder de avbildete der
det er aktuelt, og klarere alle rettigheter for de personene som avbildes. Kunden har det fulle
ansvar for at dataene lovlig kan lastes opp i Tjenesten og eventuelt deles, at dataene er
lovlige, og at dataene ikke strider mot tredjeparters rettigheter som for eksempel personvern,
publiseringsrettigheter, opphavsrett, kontraktuelle rettigheter, immaterielle rettigheter eller
andre rettigheter.<br>
Kunden og Bruker forplikter seg til å holde JOYMO skadesløs for krav fra tredjepart som
oppstår eller anføres på grunn av Kunden og/eller Brukers bruk av Tjenesten i strid med
Tjenestevilkårene.</p>

<h5>16. Mislighold</h5>
<p>JOYMO kan heve avtalen med umiddelbar virkning dersom det foreligger vesentlig mislighold
fra Kunden og/eller Brukers side. Som vesentlig mislighold regnes uten begrensning (i) bruk
av Tjenesten i forbindelse med kriminelle forhold eller ulovlig opptreden, (ii) at Kunden eller
Brukere bruker Tjenesten på en måte som kan resultere i tap eller risiko for tap for JOYMO
eller tredjeparter, eller (iii) betalingsmislighold som varer mer enn 20 dager forutsatt at
JOYMO har gitt minst en betalingspåminnelse (som kan gis per e-post).<br>
Ved manglende betaling fra Kunden og/eller Bruker, herunder at betalingstrekk mislykkes,
kan JOYMO suspendere Kunden og/eller Brukers tilgang til Tjenesten forutsatt at betaling er
mer enn 5 dager forsinket.</p>

<h5>17. Ansvarsbegrensning</h5>
<p>JOYMO er kun ansvarlig for direkte tap knyttet til mislighold, og har ikke ansvar for indirekte
tap med mindre det foreligger grov uaktsomhet eller forsett. Med indirekte tap menes tapt
omsetning, tapt omdømme, tap av data, og annet tap som i henhold til norsk rett anses som
indirekte tap.<br>
JOYMOs samlede erstatningsansvar i henhold til Tjenestevilkårene er under enhver
omstendighet begrenset til et beløp tilsvarende det Kunden eller Bruker i hvert enkelt tilfelle
har betalt.</p>

<h5>18. Angrerett</h5>
<p>Tjenesten anses som en digital innholdstjeneste hvor angreretten faller bort straks tjenesten
tas i bruk.</p>

<h5>19. Varsler</h5>
<p>Alle varsler i henhold til Tjenestevilkårene kan sendes den andre part som e-post. Varsler til
JOYMO sendes til info@joymo.tv. Varsler til Bruker sendes til den e-postadresse som er
oppgitt ved registrering.</p>

<h5>20. Reklamasjoner og tvister</h5>
<p>Hvis Kunden og/eller Bruker har spørsmål knyttet til Tjenesten eller Tjenestevilkårene, eller
ønsker å fremsette en reklamasjon, kan JOYMO kontaktes på e-post info@joymo.tv.<br>
Tjenestevilkårene er underlagt norsk rett. Oslo tingrett er rett verneting, med mindre
ufravikelig lovgivning tilsier annet verneting.<br>
</p>
