import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { Subscription } from 'src/app/core/model/interfaces/subscription';
import { SubscriptionPlan } from 'src/app/core/model/interfaces/subscription-plan';
import { User } from 'src/app/core/model/interfaces/user';
import { LivestreamsService } from 'src/app/core/model/services/livestreams.service';
import { SubscriptionPlanService } from 'src/app/core/model/services/subscription-plan.service';
import { ConcurrentLivestream, ConcurrentLivestreamSet, isHttpErrorResponse, isUpdateSubscriptionCheckSuccessResponse, isUpdateSubscriptionDeniedResponse, SubscriptionService, UpdateSubscriptionCheckResponse, UpdateSubscriptionCheckSuccessResponse, UpdateSubscriptionDeniedResponse, UpdateSubscriptionRequest, UpdateSubscriptionResponse, UpdateSubscriptionSuccessResponse } from 'src/app/core/model/services/subscription.service';
import { FunctionsError, FunctionsErrorCode } from '@angular/fire/functions';
export interface UpgradeDowngradeDialogData {
  subscription: Subscription;
  user: User;
}

@Component({
  selector: 'yo-upgrade-downgrade',
  templateUrl: './upgrade-downgrade.component.html',
  styleUrls: ['./upgrade-downgrade.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpgradeDowngradeDialogComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();

  public static STEPS = ["PLAN_SELECTION", "ACTIONS_NEEDED", "CONFIRMATION", "SUCCESS"];
  currentStep: "planSelection" | "actionNeeded" | "contactSupport" | "confirmation" | "spinner" | "success" = "planSelection";

  @ViewChild("planSelection", { static: true }) planSelection: TemplateRef<any>;
  @ViewChild("contactSupport", { static: true }) contactSupport: TemplateRef<any>;
  @ViewChild("actionNeeded", { static: true }) actionNeeded: TemplateRef<any>;
  @ViewChild("confirmation") confirmation: TemplateRef<any>;
  @ViewChild("confirmationButton") confirmationButton: TemplateRef<any>;
  @ViewChild("success", { static: true }) success: TemplateRef<any>;
  @ViewChild("spinner") spinner: TemplateRef<any>;

  concurrentStreamsArr: ConcurrentLivestream[];
  error: FunctionsError;
  isDowngrade: boolean = false;
  plans: SubscriptionPlan[] = [];
  reasons: FormControl = new FormControl('', Validators.required);
  reasonsForm: FormGroup = this.formBuilder.group({
    reasons: this.reasons
  });
  selectedPlan: SubscriptionPlan;
  selectedPlanOption: { planName: string, currency: string, periodicity: string };
  subscriptionUpdateType: "upgrade" | "downgrade";
  updateCheckResponse: UpdateSubscriptionCheckResponse;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UpgradeDowngradeDialogData,
    public dialogRef: MatDialogRef<UpgradeDowngradeDialogComponent>,
    private livestreamService: LivestreamsService,
    private subscriptionService: SubscriptionService,
    private subscriptionPlanService: SubscriptionPlanService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {

    // Get available plans for upgrade (only same currency)
    this.subscriptionPlanService.getAll()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        subscriptionPlans => this.onSubscriptionPlansFetched(subscriptionPlans),
        err => console.log(err)
      );

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getLang() {
    const lang = window.navigator.languages != undefined ?
      window.navigator.languages[0]
      : window.navigator.language;
    return lang !== undefined ? lang : 'en';
  }

  getPairs(concurrentStreams: ConcurrentLivestreamSet): ConcurrentLivestream[] {
    const concurrentStreamsArr = Object.values(concurrentStreams);
    this.concurrentStreamsArr = concurrentStreamsArr;
    // console.log('concurrentStreamsArr', this.concurrentStreamsArr);
    return concurrentStreamsArr;
  }

  getMonthlyPrice(plan: SubscriptionPlan) {
    // console.group('Getting Monthly Price');
    // console.log("Plan", plan);
    const subscription = this.data.subscription;
    const [planName, currency, periodicity] = subscription.selectedPlanOption.split('_');
    // console.log("Currency", currency);
    // console.log('Periodicity', periodicity);
    const monthlyPrice = this.subscriptionPlanService.getMonthlyPrice(plan, { currency, periodicity }, currency == "nok");
    // console.log("Monthly Price", monthlyPrice);
    // console.groupEnd();

    return monthlyPrice;
  }

  getTax(plan: SubscriptionPlan) {
    const subscription = this.data.subscription;
    const [planName, currency, periodicity] = subscription.selectedPlanOption.split('_');
    const tax = this.subscriptionPlanService.getTaxIfApplicable(plan, { currency, periodicity }, "NO", true);

    return tax;
  }

  /**
   * 
   * @param plan Selected subscription plan
   * @returns an Object reflecting the selected plan billing option matching the currency and periodicity of current subscription's plan
   */
  getSelectedPlanOption(plan: SubscriptionPlan) {
    const currentPlanOption = this.data.subscription.selectedPlanOption;
    const [currentPlanName, currentCurrency, currentPeriodicity] = currentPlanOption.split('_');
    return { planName: plan.docId, currency: currentCurrency, periodicity: currentPeriodicity };
  }

  getWindowDate(timestamp: number): string {
    const formatOptions = { year: "numeric", month: "short", day: "numeric" } as const;
    return new Date(timestamp).toLocaleDateString(this.getLang(), formatOptions);
  }

  handleUpdateSubscriptionCheck(response: UpdateSubscriptionCheckResponse): void {

    console.group('Update Subcription Check Response');
    console.log('response', response);
    console.groupEnd();

    if (isUpdateSubscriptionCheckSuccessResponse(response)) {
      this.subscriptionUpdateType = (response as UpdateSubscriptionCheckSuccessResponse).subscriptionUpdateType;
      this.currentStep = 'confirmation';
    }

    // Response is not true and the user needs to do things before we can move on
    if (isUpdateSubscriptionDeniedResponse(response)) {
      this.updateCheckResponse = response;
      this.currentStep = "actionNeeded";
    }


    // HttpErrorResponse
    if (isHttpErrorResponse(response)) {
      // TODO: Handle and log error while check subscription update checks
      console.group('Error With Subscription Check');
      console.error(response);
      if (response.error == 'invalid-argument') { console.error('Error with input', response); }
      if (response.error == 'unknown') { console.error('Error with stripe', response); }
      console.groupEnd();
    }

    return;
  }

  isCurrentPlan(plan: SubscriptionPlan): boolean {
    return plan.docId == this.data.subscription.planId;
  }

  onChangePlan() {
    this.currentStep = "spinner";
    // User has confirmed change of plan
    const requestData: UpdateSubscriptionRequest = {
      userId: this.data.user.docId,
      clubId: this.data.subscription.clubDocId,
      subscriptionId: this.data.subscription.docId,
      newSubscriptionPlanId: this.selectedPlan.docId,
      checkRestrictionOnly: false,
      reason: this.subscriptionUpdateType == "downgrade" ? this.reasons.value : undefined
    };
    // console.group('Changing Plan');
    // console.log('request Data', requestData);
    // console.groupEnd();

    this.subscriptionService.updateSubscription(requestData)
      .pipe(
        catchError((err, caught) => {
          console.group("Catching update errors");
          console.log("Error", err);
          console.log("caught", caught);
          console.groupEnd();

          return caught;
        }),
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (updateResponse) => this.onPlanChanged(updateResponse),
        err => console.log('An error occured while updating subscription', err)
      );
  }

  onPlanChanged(response: UpdateSubscriptionResponse): void {
    console.group('Plan Changed');
    console.log('Response', response);

    if (isUpdateSubscriptionDeniedResponse(response)) {
      this.currentStep = "confirmation";
      console.error('An error occured during the subscription update', response);
    }



    this.currentStep = "success";

    console.groupEnd();
  }

  /**
   * When a plan is selected we send a request to check if the user is allowed to change their subscription plan to the selected one.
   * @param plan Selected subscription plan.
   */
  onPlanSelected(plan: SubscriptionPlan) {
    // console.group('Plan Selected');
    // console.log("plan", plan);

    this.currentStep = "spinner";

    this.selectedPlan = plan;
    this.selectedPlanOption = this.getSelectedPlanOption(this.selectedPlan);
    const currentPlan = this.plans.find(p => p.docId == this.data.subscription.planId);
    this.isDowngrade = currentPlan.order > this.selectedPlan.order;
    // console.log('isDowngrade', this.isDowngrade);


    // Check if update is possible
    const requestData: UpdateSubscriptionRequest = {
      userId: this.data.user.docId,
      clubId: this.data.subscription.clubDocId,
      subscriptionId: this.data.subscription.docId,
      newSubscriptionPlanId: plan.docId,
    };
    // console.log('Sending request to updateSubscription', requestData);

    this.subscriptionService.updateSubscription(requestData, true)
      .pipe(
        catchError((err: FunctionsError, caught) => {
          console.group("Catching update errors");
          console.log("Error code", err.code);
          console.log("Error name", err.name);
          console.log("Error message", err.message);
          console.log("Error details", err.details);
          console.groupEnd();
          switch (err.code) {
            // Seems to be a discrepancy between the type and the thrown exception
            // #TODO: Understand where the error is thrown or if it's normal behavior
            case 'functions/failed-precondition' as FunctionsErrorCode:
            case 'failed-precondition':
              delete requestData.checkRestrictionOnly;
              this.error = { ...err, message: err.message, ...requestData };
              this.currentStep = "contactSupport";
              break;
            default:
              break;
          }

          throw err;
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        (response: UpdateSubscriptionCheckResponse) => this.handleUpdateSubscriptionCheck(response),
        err => console.log('An error occured while checking subscription update permissions', err)
      );

    // console.groupEnd();
  }

  onSubscriptionPlansFetched(plans: SubscriptionPlan[]) {
    console.group('Subscription Plans Fetched');
    // console.log("Plans – unsorted", plans);
    this.plans = plans.sort((p1, p2) => p1.order - p2.order);
    console.log("Plans – sorted", this.plans);
    console.groupEnd();
  }

  toUCFirst(value: string): string {
    return value.substr(0, 1).toUpperCase() + value.substr(1);
  }

}
