import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private headerConfigSource = new BehaviorSubject<HeaderConfig>({text: 'Joymo', navigateBackTo: null});
  headerConfig$: Observable<HeaderConfig> = this.headerConfigSource.asObservable();

  constructor() { }

  updateHeaderConfig(headerConfig: HeaderConfig) {
    this.headerConfigSource.next(headerConfig);
  }

}

export interface HeaderConfig {
  backLabel?: string;
  logo?: string;
  navigateBackTo: string;
  text: string;
}
