<section class="streams">
  <div class="header" [ngClass]="{'mobile': isMobileView}">
    <a class="backward"
      [routerLink]="['/browse', 'clubs', club.docId]"
      [queryParams]="{team: selectedTeamId}">
      <div class="arrow"></div>
    </a>
    <h1 class="title">{{ club.name }}</h1>
  </div>

  <ng-container *ngIf="isMobileView">
    <yo-subchannel-selector
    [subchannels]="teams"
    (onSubchannelSelected)="subchannelSelected($event)"
    [selectedSubchannel]="selectedTeamId"
    class="full-width"></yo-subchannel-selector>
  </ng-container>

  <div class="navigation">

    <ng-container *ngIf="isMobileView">
      <nav mat-tab-nav-bar
        mat-align-tabs="center" data-tab-label="stretch">
        <div mat-tab-link
          (click)="navigateTo('time', 'upcoming')"
          [active]="activeLink == 'upcoming'">
          Live and Upcoming
        </div>
        <div mat-tab-link
          (click)="navigateTo('time', 'past')"
          [active]="activeLink == 'past'">
          Replays
        </div>
      </nav>
    </ng-container>

    <nav *ngIf="!isMobileView"
      mat-tab-nav-bar>
      <div mat-tab-link
        (click)="navigateTo('time', 'upcoming')"
        [active]="activeLink == 'upcoming'">
        Live and Upcoming
      </div>
      <div mat-tab-link
        (click)="navigateTo('time', 'past')"
        [active]="activeLink == 'past'">
        Replays
      </div>

      <!-- Insert subchannel selector -->
      <yo-subchannel-selector *ngIf="!isMobileView"
        [subchannels]="teams"
        (onSubchannelSelected)="subchannelSelected($event)"
        [selectedSubchannel]="selectedTeamId"
        class="mat-tab-link push-left"></yo-subchannel-selector>
    </nav>
  </div>

  

  <div class="content"
    *ngIf="!isMobileView; else mobileList"
    [style.margin]="- 1/2 + 'rem'">

    <yo-event-card *ngFor="let element of elements; let key = index; trackBy: trackElements"
      class="yo-theme card-holder"
      [style.margin]="1/2 + 'rem'"
      [style.flexBasis]="'calc(' + 100 / 4 + '% - ' + '1' + 'rem)'"
      [style.maxWidth]="'calc(' + 100 / 4 + '% - ' + '1' + 'rem)'"
      [event]="element"
      [appearance]="cardConfig.appearance"
      [showCountdown]="cardConfig.showCountdown"
      [showLogo]="false"
      [trackEventStatus]="true"
      (eventFinished)="handleFinishedEvent(element.id)">
    </yo-event-card>

  </div>

  <ng-template #mobileList>
    <yo-event-card *ngFor="let element of elements; let key = index; trackBy: trackElements"
      class="yo-theme card-holder mobile"
      [style.margin]="1/2 + 'rem'"
      [event]="element"
      [useMobileTemplate]="true"
      [appearance]="cardConfig.appearance"
      [showCountdown]="cardConfig.showCountdown"
      [showLogo]="false"
      [trackEventStatus]="true"
      (eventFinished)="handleFinishedEvent(element.id)">
    </yo-event-card>
  </ng-template>

  <footer>
    <p *ngIf="!canLoadMore">You've reached the end of this page.</p>
    <ng-container *ngIf="canLoadMore">
      <button (click)="loadMore.next('next')"
        mat-stroked-button
        type="button">
        <ng-container *ngIf="!isLoading; else spinner">Load more</ng-container>
      </button>

      <ng-template #spinner>
        <yo-spinner></yo-spinner>
      </ng-template>
    </ng-container>
  </footer>
</section>