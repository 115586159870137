<section>
  <ng-container *ngIf="processed; else processing">
      <h1>Something wrong happened, the payment has been cancelled</h1>
  <p>You will be redirected to the product page in a few seconds</p>
  <div class="spinner"><yo-spinner></yo-spinner></div>
  <p>if you are not redirected automatically, you can click this <a [routerLink]="['/browse', payment.productType == 'livestreamGroup' ? 'collections' : 'streams', productId]">link</a></p>
  </ng-container>
  <ng-template #processing>
      <h1>we are processing your purchase, please wait a few moments</h1>
  </ng-template>
</section>