import { Component, ElementRef, Input, ViewChild } from "@angular/core";

import { ClientService } from "src/app/core/utility/client.service";
import { Highlight } from "src/app/core/model/interfaces/short";

@Component({
  selector: "yo-highlights",
  templateUrl: "./highlights.component.html",
  styleUrls: ["./highlights.component.scss"],
})
export class HighlightsComponent {
  @Input() highlights: Highlight[] = [];
  isMobile?: boolean;
  videoPlayer = document.createElement("video");

  private _activeIndex = 0;

  constructor(private clientService: ClientService) {
    this.isMobile = this.clientService.isMobileDevice();
  }

  get highlight(): Partial<Highlight> {
    return this.highlights[this._activeIndex] || {};
  }

  get activeIndex(): number {
    return this._activeIndex;
  }

  get videoUrl() {
    /**
     * Returns active highlight's video URL at first millisecond,
     * this is a workaround to display a poster image on iOS.
     * Other browsers behave just as usual
     */
    return this.highlight.shortsUrl + "#t=0.001";
  }

  set activeIndex(newIndex: number) {
    this.videoPlayer.autoplay = true;
    if (newIndex === this.highlights.length) {
      this._activeIndex = 0;
      return;
    } else if (newIndex < 0) {
      this._activeIndex = this.highlights.length - 1;
      return;
    }
    this._activeIndex = newIndex;
  }

  @ViewChild("video")
  set _videoPlayer(el: ElementRef) {
    this.videoPlayer = el.nativeElement as HTMLVideoElement;
    this.videoPlayer.controls = true;
    this.videoPlayer.addEventListener("timeupdate", () => {});
  }

  pauseOrPlay(): void {
    const { paused } = this.videoPlayer;
    this.videoPlayer[paused ? "play" : "pause"]();
  }
}
