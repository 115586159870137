<h2 *ngIf="header && showCountDown">{{header}}</h2>
<div class="count-down" *ngIf="showCountDown" [ngClass]="{'inline':inline, 'event': event}">
  <div class="segment days" *ngIf='showDays'>
    <span class="value">{{d}}</span>
    <span class="unit-label">Days</span>
  </div>
  <div class="segment hours" *ngIf='showHours'>
    <span class="value">{{h}}</span>
    <span class="unit-label">Hours</span>
  </div>
  <div class="segment minutes" *ngIf='showMinutes'>
    <span class="value">{{m}}</span>
    <span class="unit-label">{{event ? 'Min': 'Minutes'}}</span>
  </div>
  <div class="segment seconds" *ngIf='showSeconds'>
    <span class="value">{{s}}</span>
    <span class="unit-label">{{event ? 'Sec' : 'Seconds'}}</span>
  </div>
</div>