export const environment = {
  production: false,
  authAppUrl: "https://auth.staging.joymo.no",
  firebase: {
    apiKey: "AIzaSyAJyUKKe9MzNJpHEHRU7P-PU2E9_1ye10w",
    authDomain: "auth.staging.joymo.no",
    databaseURL: "https://yoimo-live-staging.firebaseio.com",
    projectId: "yoimo-live-staging",
    storageBucket: "yoimo-live-staging.appspot.com",
    // messagingSenderId: '662686805404',     Removing request for notification permission, not used atm.
    appId: "1:662686805404:web:8e3c5e29b68fa2b02aec59",
    measurementId: "G-7LLFWN9R3K",
  },
  /*cloudFunctions: {
    region: ''
  },*/
  config: {
    chromecastReceiver: "C02BA3BC",
  },
  stripe: {
    publicKey: "pk_test_azwtYko430F5kO6YBx4Bm9AY",
  },
  google: {
    gtmId: "GTM-K4NR2WR",
  },
  hubspot: {
    trackerId: "7409284",
  },
  search: {
    domain : 'https://us-central1-yoimo-live-staging.cloudfunctions.net',
  }
};
