<mat-tab-group #usersTabGroup dynamicHeight mat-stretch-tabs mat-align-tabs="center"
  [selectedIndex]="mapActiveTabToIndex()" [class]="'mobile'" [ngClass]="klass"
  (selectedTabChange)="onSelectedTabChange($event)">

  <mat-tab #admins *ngIf="showingTabs.admin">
    <!-- Label template -->
    <ng-template mat-tab-label>
      <div>Admins</div>
    </ng-template>

    <!-- Content template -->
    <ng-template matTabContent>
      <ng-container *ngTemplateOutlet="users"></ng-container>
    </ng-template>
  </mat-tab>

  <mat-tab #trainers *ngIf="showingTabs.trainer">
    <!-- Label template -->
    <ng-template mat-tab-label>
      <div>Trainers</div>
    </ng-template>

    <!-- Content template -->
    <ng-template matTabContent>
      <ng-container *ngTemplateOutlet="users"></ng-container>
      
    </ng-template>
  </mat-tab>

  <mat-tab #streamer *ngIf="showingTabs.streamer">
    <!-- Label template -->
    <ng-template mat-tab-label>
      <div>Streamers</div>
    </ng-template>

    <!-- Content template -->
    <ng-template matTabContent>
      <ng-container *ngTemplateOutlet="users"></ng-container>
    </ng-template>
  </mat-tab>

  <mat-tab #members *ngIf="showingTabs.member">
    <!-- Label template -->
    <ng-template mat-tab-label>
      <div>Members</div>
    </ng-template>

    <!-- Content template -->
    <ng-template matTabContent>
      <ng-container *ngTemplateOutlet="users"></ng-container>
    </ng-template>
  </mat-tab>

  <mat-tab #invites *ngIf="showingTabs.invite">
    <!-- Label template -->
    <ng-template mat-tab-label>
      <div>Invites</div>
    </ng-template>

    <!-- Content template -->
    <ng-template matTabContent>
      <ng-container *ngTemplateOutlet="invites"></ng-container>
    </ng-template>
  </mat-tab>

</mat-tab-group>

<ng-template #users>
  <div class="loader-overlay" *ngIf="isLoadingPast">
    <yo-loading></yo-loading>
  </div>
  <table class="users" mat-table [dataSource]="userDataSource">
    <ng-container matColumnDef='users'>
      <td mat-cell class="user" *matCellDef="let user" (click)="selectRow(user)">
        <div class="card" fxLayout="row" fxLayoutAlign="center center">
          <div class="details" fxFlex="1 0 auto" fxLayout="column">
            <div class="info" fxLayout="column">
              <h2 class="name" [class.authenticated-user]="authenticatedUser?.email == user.email">{{ user.email }}
              </h2>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <mat-menu #userMenu>
      <button mat-menu-item i18n="user list remove button label" (click)="onRemoveClick()">
        <mat-icon>delete</mat-icon>
        <span mat-button-wrapper>Remove the user</span>
      </button>
    </mat-menu>

    <tr mat-row [matMenuTriggerFor]="userMenu" *matRowDef="let row; columns: ['users']" class="user-row"></tr>
  </table>
</ng-template>

<ng-template #invites>
  <div class="loader-overlay" *ngIf="isLoadingPast">
    <yo-loading></yo-loading>
  </div>
  <table class="users" mat-table [dataSource]="userDataSource">
    <ng-container matColumnDef='users'>
      <td mat-cell class="user" *matCellDef="let user">
        <div class="card" fxLayout="row" fxLayoutAlign="center center">
          <div class="details" fxFlex="1 0 auto" fxLayout="column">
            <div class="info" fxLayout="row" fxLayoutAlign="start center">
              <h2 class="name">{{ user.email }}</h2><span class="pull-right" [class.expired]="user.status ==='EXPIRED'"
              [class.pending]="user.status ==='PENDING'" [class.accepted]="user.status ==='ACCEPTED'">{{ user.status }}</span>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: ['users']" class="user-row"></tr>
  </table>
</ng-template>
