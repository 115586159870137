import { BehaviorSubject, Subscription } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { Livestream } from "src/app/core/model/interfaces/livestream";
import { LivestreamGroup } from "src/app/core/model/interfaces/livestream-group";
import { LivestreamGroupsService } from "src/app/core/model/services/livestream-groups.service";
import { LivestreamsService } from "src/app/core/model/services/livestreams.service";
import { QueryWhereParameter } from "src/app/core/model/collection";
import { TeamPublic } from "src/app/core/model/interfaces/teamPublic";
import { TeamsPublicService } from "src/app/core/model/services/teams-public.service";
import { Highlight } from "src/app/core/model/interfaces/short";
import { ShortsService } from "src/app/core/model/services/shorts.service";

@Injectable({ providedIn: "root" })
export class ChannelService {
  selectedSubchannel$ = new BehaviorSubject<string | undefined>(undefined);
  subchannels$ = new BehaviorSubject<TeamPublic[]>(undefined);
  upcomingEvents$ = new BehaviorSubject<Livestream[]>(undefined);
  pastEvents$ = new BehaviorSubject<Livestream[]>(undefined);
  playlists$ = new BehaviorSubject<LivestreamGroup[]>(undefined);
  shorts$ = new BehaviorSubject<Highlight[]>(null);

  fetchingSubchannels = false;
  fetchingEvents = false;

  private _channelId: string;
  public get channelId(): string {
    return this._channelId;
  }
  public set channelId(value: string) {
    this._channelId = value || undefined;
  }

  private _subscriptions: Map<string, Subscription>;
  public get subscriptions(): Map<string, Subscription> {
    if (!this._subscriptions) {
      this._subscriptions = new Map();
    }

    return this._subscriptions;
  }

  constructor(
    private publicTeamService: TeamsPublicService,
    private streamService: LivestreamsService,
    private livestreamGroupService: LivestreamGroupsService,
    private shortsService: ShortsService
  ) {}

  fetchSubchannels() {
    this.fetchingSubchannels = true;
    let subscription = this.publicTeamService
      .getTeamsByClubDocId(this.channelId)
      .subscribe((subchannels) => {
        this.subchannels$.next(subchannels);
        this.fetchingSubchannels = false;
      });

    if (this.subscriptions.has("subchannels")) {
      this.subscriptions.get("subchannels").unsubscribe();
      this.subscriptions.delete("subchannels");
    }

    this.subscriptions.set("subchannels", subscription);
  }

  fetchUpcomingEvents() {
    this.fetchingEvents = true;

    if (this.subscriptions.has("upcomingEvents")) {
      this.subscriptions.get("upcomingEvents").unsubscribe();
      this.subscriptions.delete("upcomingEvents");
    }

    let upcomingEvents$ = this.selectedSubchannel$
      .pipe(
        map((subchannel) => this.generateUpcomingQueryClauses(subchannel)),
        switchMap((qWheres) => {
          return this.streamService.query(qWheres, 50, [
            { field: "scheduledStopTime", sort: "asc" },
          ]);
        })
      )
      .subscribe((events) => {
        this.upcomingEvents$.next(events);
        this.fetchingEvents = false;
      });

    this.subscriptions.set("upcomingEvents", upcomingEvents$);
  }

  fetchPastEvents() {
    this.fetchingEvents = true;

    if (this.subscriptions.has("pastEvents")) {
      this.subscriptions.get("pastEvents").unsubscribe();
      this.subscriptions.delete("pastEvents");
    }

    let pastEvents$ = this.selectedSubchannel$
      .pipe(
        map((subchannel) => this.generatePastQueryClauses(subchannel)),
        switchMap((qWheres) => {
          return this.streamService.query(qWheres, 50, [
            { field: "scheduledStopTime", sort: "desc" },
          ]);
        })
      )
      .subscribe((events) => {
        this.pastEvents$.next(events);
        this.fetchingEvents = false;
      });

    this.subscriptions.set("pastEvents", pastEvents$);
  }

  fetchPlaylists() {
    let subscription = this.livestreamGroupService
      .query(
        [
          ["isPublic", "==", true],
          ["clubDocId", "==", this.channelId],
        ],
        20,
        [{ field: "createdTime", sort: "desc" }]
      )
      .subscribe(
        (playlists) => this.playlists$.next(playlists),
        (err) => console.error(err)
      );

    if (this.subscriptions.has("playlists")) {
      this.subscriptions.get("playlists").unsubscribe();
      this.subscriptions.delete("playlists");
    }

    this.subscriptions.set("playlists", subscription);
  }

  fetchShorts(docIds: string[]) {
    console.log("Fetching shorts...", docIds.length);

    const subscription = this.shortsService
      .getByDocIds(docIds)
      .subscribe((shorts) => this.shorts$.next(shorts));

    this.subscriptions.set("shorts", subscription)
  }

  generateUpcomingQueryClauses(subchannel: string) {
    let qWheres = this.generateQueryClauses(subchannel);
    qWheres.push(["scheduledStopTime", ">", new Date()]);

    return qWheres;
  }

  generatePastQueryClauses(subchannel: string) {
    let qWheres = this.generateQueryClauses(subchannel);
    qWheres.push(["scheduledStopTime", "<", new Date()]);

    return qWheres;
  }

  generateQueryClauses(subchannel: string): QueryWhereParameter[] {
    let qWheres: QueryWhereParameter[] = [
      ["clubDocId", "==", this.channelId],
      ["isPublic", "==", true],
    ];

    if (subchannel) {
      qWheres.push(["teamDocId", "==", subchannel]);
    }

    return qWheres;
  }

  registerSubscription(key: string, subscription: Subscription) {
    this.subscriptions.set(key, subscription);
  }

  destroySubscription(key: string) {
    if (this.subscriptions.has(key)) {
      this.subscriptions.get(key).unsubscribe();
      this.subscriptions.delete(key);
    }
  }

  updateSelectedSubchannel(subchannelId: string) {
    this.selectedSubchannel$.next(subchannelId);
  }

  unsubscribeAll() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions.clear();
  }
}
