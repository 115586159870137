import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';

export const ICONS = [
  faFacebookF, faTwitter, faWhatsapp, faFacebookMessenger, faLink, faPrint, faCheck
];
