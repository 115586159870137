<div class="grid-container"
  *ngIf="!isMobileView; else mobile"
  [ngClass]="{ 'wrap': multiline }"
  [style.margin]="- breakpoint.gutterSize/2 + 'rem'">
  <yo-event-card *ngFor="let element of elements; let key = index; trackBy: trackElements"
    class="yo-theme card-holder"
    [style.margin]="breakpoint.gutterSize/2 + 'rem'"
    [style.flexBasis]="'calc(' + 100 / breakpoint.cols.main + '% - ' + breakpoint.gutterSize + 'rem)'"
    [event]="element"
    [appearance]="cardConfig.appearance"
    [showCountdown]="cardConfig.showCountdown"
    [showLogo]="cardConfig.showLogo"
    (eventFinished)="handleFinishedEvent(element.id)">
  </yo-event-card>
</div>


<ng-template #mobile>
  <div class="list-container">
    <yo-event-card *ngFor="let element of elements; let key = index; trackBy: trackElements"
      class="yo-theme card-holder mobile"
      [style.margin]="breakpoint.gutterSize/2 + 'rem'"
      [useMobileTemplate]="true"
      [event]="element"
      [appearance]="cardConfig.appearance"
      [showLogo]="cardConfig.showLogo"
      [showCountdown]="cardConfig.showCountdown">
    </yo-event-card>
  </div>
</ng-template>