import {Directive, ElementRef, HostBinding, Input, OnInit, Renderer2} from '@angular/core';
import {darkTheme} from '../theme/dark-theme';

@Directive({
  selector: '[yoFlatButton]'
})
export class FlatButtonDirective implements OnInit {
  @Input() color = 'primary'; // Can be 'primary' or 'accent'
  @Input() fontSize = '14px'; // Can be 'primary' or 'accent'
  @Input() padding = '6px 18px'; // Can be 'primary' or 'accent'
  @HostBinding('style.color') textColor;

  colors = {
    primary: darkTheme.properties.first,
    accent: darkTheme.properties.second,
    onFirst: darkTheme.properties.onFirst,
  };

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.textColor = this.colors[this.color];
    this.renderer.setStyle(this.elementRef.nativeElement, 'font-size', this.fontSize);
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', 'transparent');
    this.renderer.setStyle(this.elementRef.nativeElement, 'border-radius', '5px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'pointer');
    this.renderer.setStyle(this.elementRef.nativeElement, 'color', this.textColor);
    this.renderer.setStyle(this.elementRef.nativeElement, 'letter-spacing', '0px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'text-align', 'center');
    this.renderer.setStyle(this.elementRef.nativeElement, 'border', '0 none');
    this.renderer.setStyle(this.elementRef.nativeElement, 'font-weight', '600');
    this.renderer.setStyle(this.elementRef.nativeElement, 'padding', this.padding);
    this.renderer.setStyle(this.elementRef.nativeElement, 'box-sizing', 'border-box');
    this.renderer.setStyle(this.elementRef.nativeElement, 'outline', 'none');
  }
}
