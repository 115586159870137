<ng-container *ngIf="livestreamGroup">
  <img *ngIf='livestreamGroup && livestreamGroup.imageUrls' class="logo" src="{{ livestreamGroup.imageUrls.main }}" />
  <section
    class="livestream-checkout yo-theme mat-typography" [style.height]="'calc(100%)'">
    
    <div class="content" [style.minHeight]="'100%'" [style.maxWidth]="'100%'"
      gdAreas="header header header header | left l-mid mid right | footer-left footer footer footer-right"
      gdColumns="30% auto auto 30%"
      gdRows="20% auto 20%"
      gdAlignRows="center stretch"

      gdAreas.lt-md="header | card-container | mid | footer"
      gdColumns.lt-md="100%"
      gdRows.lt-md="auto"
      >
      <div class="header" gdArea="header" fxLayout="column" fxLayoutAlign="center center">
        <h1 style="text-align:center"><strong>{{livestreamGroup.clubName}}</strong></h1>
        <share-buttons *ngIf="shareUrl" [include]="['copy', 'facebook', 'whatsapp' , 'messenger']" [url]="shareUrl"></share-buttons>
      </div>
      <ng-container [ngSwitch]='paymentStep'>
        <ng-container *ngSwitchCase='"init"'>
          <div class="ticket-area"
            gdArea="l-mid" fxLayout="row" fxLayoutAlign="center center"
            gdArea.lt-md="card-container"
            >
            <div class="ticket-body" ngClass.lt-md="compact">
              <div class="ticket-logo-area">
                <img [src]="livestreamGroup.imageUrls.main">
              </div>
              <div class="ticket-text-area">
                <h1 class="title">{{livestreamGroup.name}}</h1>
                <h2 *ngFor='let d of livestreamGroup.description.split("\n")' class="description">{{d}}</h2>
              </div>
            </div>
          </div>
          <div class="package-options"
            gdArea="mid" fxLayout="row" fxLayoutAlign="start center"
            gdArea.lt-md="mid" fxLayout.lt-md="row wrap" fxLayoutAlign.lt-md="center center"
            >
            <ng-template ngFor let-package [ngForOf]="availablePackages" let-i="index">
              <ng-container *ngTemplateOutlet='packageOption;
                context:package'></ng-container>
            </ng-template>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase='"login"'>
          <div class="ticket-area" fxLayoutAlign="end" gdArea="l-mid" fxHide.lt-md>
            <div class="package-options" fxLayout="row wrap" fxLayoutAlign="end">
              <ng-container *ngTemplateOutlet='packageOption;
                    context:selectedPackage'>
              </ng-container>
            </div>
          </div>
          <div class="ticket-area" fxLayoutAlign="start" gdArea="mid" gdArea.lt-md="mid">
            <div class="package-options" fxLayout="row wrap" fxLayoutAlign="center">
              <mat-card class="package-card" fxLayout="column" fxLayoutAlign="center">
                <mat-card-content>
                  <yo-login-form  [loginMode]='LoginMode.REGISTER'></yo-login-form>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </ng-container>
        <!--<div *ngSwitchCase='"login"' class="ticket-area" fxLayoutAlign="center"
          gdArea="l-mid mid"
          >
        </div>-->
        <div *ngSwitchCase='"already_has_access"' class="ticket-area already-has-access"
          gdArea="2 / 2 / 2 / 4"
          gdArea.lt-md="mid"
          fxLayoutAlign="center">
          <div class="package-options">
            <mat-card class="package-card" fxLayout="column" fxLayoutAlign="center">
              <mat-card-content>
                <mat-icon>thumb_up</mat-icon>
                <h1>You have access to this content!</h1>
                <button mat-stroked-button color='primary' class="go-back-checkout" (click)='goBack()'>
                  <mat-icon>arrow_back</mat-icon> Back to content
                </button>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div *ngSwitchCase='"waiting_for_payment"' class="ticket-area waiting-for-payment"
          gdArea="2 / 2 / 2 / 4"
          gdArea.lt-md="mid"
          fxLayoutAlign="center">
          <div class="package-options">
            <mat-card class="package-card" fxLayout="column" fxLayoutAlign="center">
              <mat-card-content fxLayout="column" fxLayoutAlign="justify">
                <!-- <h1>One last thing...</h1>
                <p>Please let us know which team should get the revenue from your purchase:</p>
                <mat-form-field>
                  <input matInput type="text" [(ngModel)]="paymentMessage">
                </mat-form-field>
                <small>The income will be divided through the teams streaming their matches. For us to do this as accurately as possible, we would appreciate you letting us know which team you want to support!</small> -->
              </mat-card-content>
              <button mat-stroked-button color='primary' class="go-to-checkout" (click)='paymentMessage$.next(paymentMessage)'>
                <mat-icon>add_shopping_cart</mat-icon> Continue to checkout
              </button>
            </mat-card>
          </div>
        </div>
        <div *ngSwitchCase='"redirecting_to_stripe"' class="ticket-area redirecting-to-stripe"
          gdArea="2 / 2 / 2 / 4"
          gdArea.lt-md="mid"
          fxLayoutAlign="center">
          <div class="package-options">
            <mat-card class="package-card" fxLayout="column" fxLayoutAlign="center">
              <mat-card-content>
                <yo-loading [compact]='true'></yo-loading>
                <h1>Redirecting to payment service</h1>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </ng-container>
      <div class="footer" gdArea="footer">
        <p><!--Kontakt klubben for mer informasjon om hvilke treninger, kamper og arrangementer som kommer til å bli sendt med Joymo
          når sesongen starter. Kontakt klubben eller gå til Joymo Arena for å se hvilke sendinger fra årets sesong som
          er tilgjengelige på sesongkortet og som du kan se allerede nå.-->
        </p>
      </div>
    </div>
  </section>
</ng-container>

<ng-template #packageOption
  let-type='type'
  let-packageLabel='label'
  let-packageValue='value'
  let-packagePrice='formattedPrice'
  let-currency='currency'
  let-packageDescription='description'
  let-available='available'
  let-selected='selected'
  let-features='features'>

  <mat-card color="primary" fxLayout="column" *ngIf='available && packagePrice!==undefined' class="package-card"
    fxFlex='30'>
    <mat-card-header fxLayout="column">
      <mat-card-title>
        
        <mat-card-subtitle>
          {{packageLabel}}
        </mat-card-subtitle>

        <strong *ngIf='packagePrice'>{{ packagePrice }}</strong>
        
        <strong *ngIf='packagePrice === undefined'>UNAVAILABLE</strong>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout='column' fxFlex="grow">
      <div fxLayout='column' fxFlex="grow">
        <p class="packageDescription">{{packageDescription}}</p>
        <!--<mat-list class="features-list" dense>
          <ng-template ngFor let-feature [ngForOf]="features">
            <mat-divider></mat-divider>
            <h1 matSubheader><mat-icon>{{feature.icon}}</mat-icon>&nbsp;{{feature.heading}}</h1>
            <p mat-line *ngFor="let fi of feature.items;">{{fi}}</p>
          </ng-template>
        </mat-list>-->
        <mat-accordion class="features-list">
          <ng-template ngFor let-feature [ngForOf]="features">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>{{feature.icon}}</mat-icon> {{feature.heading}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let fi of feature.items;">{{fi}}</p>
            </mat-expansion-panel>
          </ng-template>
        </mat-accordion>
      </div>
      <button *ngIf='available && !selected' fxFlex="noshrink" class="buy-button" mat-stroked-button color='primary'
        (click)='buy(type)'>
        <mat-icon>shop_two</mat-icon> Buy
      </button>
    </mat-card-content>
  </mat-card>
</ng-template>