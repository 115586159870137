import { Component, OnInit, Input, OnDestroy, ViewChild, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Livestream } from 'src/app/core/model/interfaces/livestream';
import { Subject, Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LivestreamsService } from 'src/app/core/model/services/livestreams.service';
import { takeUntil } from 'rxjs/operators';

interface StreamData {
  description: string;
  docId: string;
  duration: string;
  imgUrl: string;
  name: string;
  price: string;
  shareUrl: string;
  startTime: string;
  stopTime: string;
  teamName: string;
}

@Component({
  selector: 'yo-streams-table',
  templateUrl: './streams-table.component.html',
  styleUrls: ['./streams-table.component.scss'],
})
export class StreamsTableComponent implements OnInit, AfterViewInit, OnDestroy {

  private ngUnsubscribe = new Subject();


  @Input() streamSource: Observable<Livestream[]>;
  @Input() activeTab: string = 'future';
  @Input() datasourceLoading: boolean = false;

  @Output() onTabSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() onStreamSelected: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('streamsPaginator', { static: true }) streamsPaginator: MatPaginator;

  displayedColumns: string[] = ['time', 'name', 'team-name', 'duration', 'description', 'price'];
  selectedStream: Livestream;
  selectedTab: number = 0;
  streams: Livestream[] = [];
  streamsLength: number = 0;

  streamDataSource: MatTableDataSource<StreamData>;

  constructor(private livestreamService: LivestreamsService) { }

  ngOnInit() {
    console.group('Streams table init');

    this.streamDataSource = new MatTableDataSource(this.streams.map(stream => this.getStreamData(stream)));
    if (this.streamsPaginator) { console.log('Streams Paginator length', this.streamsPaginator.length); }

    this.streamSource.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      streams => {
        console.group('Streams table - streams source trigerred');
        console.log('Streams', this.streams);
        console.log('Received streams', streams);
        this.streams = streams || [];
        this.streamDataSource.data = this.streams.map(stream => this.getStreamData(stream));
        console.groupEnd();
      },
      err => console.error(err)
    );

    console.log('Streams', this.streams);
    console.groupEnd();
  }

  ngAfterViewInit() {
    console.group('AfterViewInit');
    console.log('Streams Paginator', this.streamsPaginator);
    if (this.streamsPaginator) { console.log('Past Streams Paginator length', this.streamsPaginator.length); }
    this.streamDataSource.paginator = this.streamsPaginator;
    console.log('Streams DataSource Paginator', this.streamDataSource.paginator);
    console.groupEnd();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  applyFilter(filterValue: string): void {
    this.streamDataSource.filter = filterValue.trim().toLowerCase();
  }

  getStreamData(stream: Livestream): StreamData {
    return {
      description: stream.description,
      docId: stream.docId,
      duration: this.livestreamService.getDuration(stream),
      imgUrl: stream.imageUrls.main,
      name: stream.name,
      price: (stream.price && stream.price.toString()) || "-",
      shareUrl: LivestreamsService.getLivestreamShareUrl(stream.docId),
      startTime: this.livestreamService.getStartTime(stream),
      stopTime: this.livestreamService.getStopTime(stream),
      teamName: stream.teamName,
    };
  }

  onStreamSelect(clickedStream: StreamData) {
    console.group('Selected stream:' + clickedStream.docId);
    console.log(clickedStream);
    this.selectedStream = this.streams.find(stream => stream.docId == clickedStream.docId);
    this.onStreamSelected.emit(this.selectedStream.docId);
    console.groupEnd();
  }

  onTabNavSelect(selectedTabIndex: number, activeTab: string): void {
    console.group('Selecting tab');
    console.log('Selected tab index', selectedTabIndex);
    console.log('Selected active tab', activeTab);
    console.log('Current active tab', this.activeTab);
    console.groupEnd();
    this.onTabSelected.emit(activeTab);
  }

  mapActiveTabToIndex(): number {
    switch (this.activeTab) {
      case 'future':
        return 0;
      case 'past':
        return 1;
      default:
        return 0;
    }
  }

}
