import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';

import { ClientService } from 'src/app/core/utility/client.service';
import { EventCardComponentInput } from '../cards/event/event-card.component';
import { Livestream } from 'src/app/core/model/interfaces/livestream';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'yo-streams-list-view',
  templateUrl: './streams-list-view.component.html',
  styleUrls: ['./streams-list-view.component.scss']
})
export class StreamsListViewComponent implements OnInit {

  private ngUnsubscribe = new Subject();

  // @Input() futureStreamSource: Observable<Livestream[]>;
  // @Input() pastStreamSource: Observable<Livestream[]>;
  @Input() streamSource: Observable<Livestream[]>;
  @Input() activeTab: string = 'future';
  @Input() datasourceLoading = false;

  @Output() onTabSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() onStreamSelected: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('streamsPaginator', { static: true }) streamsPaginator: MatPaginator;

  isMobileView = false;
  selectedStream: Livestream;
  selectedTab: number = 0;
  streams: Livestream[] = [];
  streamsLength: number = 0;

  streamDataSource: MatTableDataSource<EventCardComponentInput>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private clientService: ClientService,
  ) { }

  ngOnInit() {
    console.group('Streams list init');
    this.isMobileView = this.clientService.isMobileDevice();
    this.streamsLength = this.streams.length;
    this.streamDataSource = new MatTableDataSource(this.streams.map(this.formatStream));

    if (this.streamsPaginator) { console.log('Streams Paginator length', this.streamsPaginator.length); }

    this.streamSource.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      streams => {
        console.group('Streams list - streams source trigerred');
        console.log('Streams', this.streams);
        console.log('Received streams', streams);
        this.streams = streams || [];
        this.streamDataSource.data = this.streams.map(this.formatStream);
        this.changeDetectorRef.markForCheck();
        console.groupEnd();
      },
      err => console.error(err)
    );

    console.log('Streams', this.streams);
    console.groupEnd();
  }

  formatStream(stream: Livestream): EventCardComponentInput {
    return {
      id: stream.docId,
      title: stream.name,
      subtitle: stream.clubName,
      thumbnail: (stream.imageUrls && stream.imageUrls.main) ? stream.imageUrls.main : '',
      logo: (stream.imageUrls && stream.imageUrls.secondary) ? stream.imageUrls.secondary : '',
      routerLink: ['/browse', 'streams', stream.docId],
      startDate: stream.scheduledStartTime.toDate(),
      stopDate: stream.scheduledStopTime.toDate(),
      price: of('')
    };
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSelectedTabChange(event) {
    console.log(event);
    switch (event.index) {
      case 0:
        this.onSelectFuture();
        break;
      case 1:
        this.onSelectPast();
        break;
    }
  }

  onSelectPast() {
    console.group('Past tab selected');
    this.selectedTab = 1;
    this.onTabSelected.emit('past');
    console.groupEnd();
  }

  onSelectFuture() {
    console.group('Future tab selected');
    this.selectedTab = 0;
    this.onTabSelected.emit('future');
    console.groupEnd();
  }

  mapActiveTabToIndex(): number {
    switch (this.activeTab) {
      case 'future':
        return 0;
      case 'past':
        return 1;
      default:
        return 0;
    }
  }
}
