import { AccessOption, PaymentsService } from 'src/app/core/model/services/payments.service';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { Breakpoints } from '@angular/cdk/layout';
import { EventCardComponentInput } from 'src/app/yoimo-ui/cards/event/event-card.component';
import { Livestream } from 'src/app/core/model/interfaces/livestream';
import { LivestreamGroup } from 'src/app/core/model/interfaces/livestream-group';

@Component({
  selector: 'yo-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss']
})
export class PlaylistsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  @Input("collections") collections$: Observable<LivestreamGroup[]>;
  @Input("isMobileView") isMobileView: boolean = false;
  @Input("showHeader") showHeader = true;

  refreshTrigger: BehaviorSubject<boolean> = new BehaviorSubject(true);
  elements$: Observable<EventCardComponentInput[]>;

  constructor(
    private paymentService: PaymentsService,
  ) { }

  ngOnInit() {
    this.elements$ = this.refreshTrigger.pipe(
      switchMap(_ => this.collections$),
      map(collections => collections.map(s => this.paymentService.fromCollectionToEventCardInput(s))),
      // takeUntil(this.ngUnsubscribe)
    );
  }

  // formatCollection(collection: LivestreamGroup): EventCardComponentInput {
  //   return {
  //     id: collection.docId,
  //     title: collection.name,
  //     subtitle: collection.clubName,
  //     thumbnail: (collection.imageUrls && collection.imageUrls.main) ? collection.imageUrls.main : '',
  //     logo: (collection.imageUrls && collection.imageUrls.secondary) ? collection.imageUrls.secondary : '',
  //     routerLink: ['/browse', 'collections', collection.docId],
  //     startDate: collection.startTime.toDate(),
  //     stopDate: collection.stopTime.toDate(),
  //     price: of(this.paymentService.createProxyPaymentOption(collection).formattedPrice)
  //   };
  // }

  getCheapestOption(options: AccessOption[]): AccessOption {
    options.sort((o1, o2) => o2.price - o1.price);
    return options[0];
  }

  sortStreams(streams: Livestream[]): Livestream[] {
    return streams.sort((s1, s2) => s1.scheduledStartTime.toDate().getTime() - s2.scheduledStartTime.toDate().getTime());
  }

  handleFinishedEvent(docId: string) {
    // this.streams = this.streams.filter(s => s.docId !== docId);
    this.refreshTrigger.next(true);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
