import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { EventCardComponentInput } from "src/app/yoimo-ui/cards/event/event-card.component";
import {
  AutoComplete,
  Completion,
  TermSearchResult,
  TextSearchResult,
  TextSearchBody,
  EntitySearchResults,
  Club,
  Stream,
  Playlist,
} from "../../../search/search.model";
@Injectable({
  providedIn: "root",
})
export class SearchService {
  suggestionUrl = environment.search.domain + "/search/auto-complete";
  searchTermUrl = environment.search.domain + "/search/term";
  textSearchUrl = environment.search.domain + "/search/text";

  constructor(private http: HttpClient) {}

  getSuggestions(text: string): Observable<AutoComplete[]> {
    const channelCall = this.http.get(`${this.suggestionUrl}/club`, {
      params: { text },
    }) as Observable<AutoComplete>;
    const eventCall = this.http.get(`${this.suggestionUrl}/livestream-group`, {
      params: { text },
    }) as Observable<AutoComplete>;
    return combineLatest([eventCall, channelCall]);
  }

  searchExactTerm(searchObj: Completion): Observable<TermSearchResult> {
    const reqBody = {
      query: {
        field: "name",
        value: searchObj.text,
      },
    };
    return this.http.post(
      `${this.searchTermUrl}/${searchObj.type}`,
      reqBody
    ) as Observable<TermSearchResult>;
  }

  findtext(text: string): Observable<Map<string, number>> {
    const body = {
      options: {
        aggregation: {
          groupBy: {
            aggType: "countIndex",
            field: "docId",
          },
        },
      },
    };
    return this.http
      .post(`${this.textSearchUrl}/*`, body, {
        params: { text },
      })
      .pipe(
        map(
          (results: TextSearchResult) =>
            results.aggregation.by_index_count.buckets
        )
      );
  }

  loadSearchResults(
    type: string,
    text: string,
    body
  ): Observable<TermSearchResult> {
    return this.http.post(`${this.textSearchUrl}/${type}`, body, {
      params: { text },
    }) as Observable<TermSearchResult>;
  }

  channelsResults(
    searchText: string,
    body: TextSearchBody
  ): Observable<EntitySearchResults> {
    return this.loadSearchResults("club", searchText, body).pipe(
      map((data: TermSearchResult) => {
        return {
          totalResults: data.total,
          docs: data.results.map((res) => res.doc) as Club[],
        };
      })
    );
  }

  playlistsResults(
    searchText: string,
    body: TextSearchBody
  ): Observable<EntitySearchResults> {
    return this.loadSearchResults("livestream-group", searchText, body).pipe(
      map((data: TermSearchResult) => {
        return {
          totalResults: data.total,
          docs: (data.results.map((res) => res.doc) as Playlist[]).map(
            (collection) => this.getEventCardInput(collection)
          ),
        };
      })
    );
  }

  streamsResults(
    searchText: string,
    body: TextSearchBody
  ): Observable<EntitySearchResults> {
    return this.loadSearchResults("livestream", searchText, body).pipe(
      map((data) => {
        return {
          totalResults: data.total,
          docs: (data.results.map((res) => res.doc) as Stream[]).map((stream) =>
            this.getEventCardInput(stream, true)
          ),
        };
      })
    );
  }

  getEventCardInput(
    entity: Stream | Playlist,
    isStream = false
  ): EventCardComponentInput {
    return {
      id: entity.docId,
      title: entity.name,
      subtitle: entity.clubName,
      thumbnail: entity.imageUrls?.main || "",
      logo: entity.imageUrls?.secondary || "",
      routerLink: [
        "/browse",
        isStream ? "streams" : "collections",
        entity.docId,
      ],
      startDate: isStream
        ? new Date((entity as Stream).scheduledStartTime._seconds * 1000)
        : new Date((entity as Playlist).startTime._seconds * 1000),
      stopDate: isStream
        ? new Date((entity as Stream).scheduledStopTime._seconds * 1000)
        : new Date((entity as Playlist).stopTime._seconds * 1000),
      price: null,
    };
  }
}
