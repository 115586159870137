import { Component, Input } from "@angular/core";

@Component({
  selector: "yo-cover",
  templateUrl: "./cover.component.html",
  styleUrls: ["./cover.component.scss"],
})
export class CoverComponent {
  @Input() avatar?: string;
  @Input() description?: string;
  @Input() image?: string;
  @Input() isExclusive?: boolean;
  @Input() name?: string;
  @Input() video?: string;
  @Input() clubId: string;
}
