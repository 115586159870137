import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { filter, map, takeUntil } from 'rxjs/operators';

const SLICE = 1000;
const SECONDS = Math.floor(1000 / SLICE);
const MINUTES = 60 * SECONDS;


export interface EventCardComponentInput {
  routerLink: string[];
  id: string;
  title: string;
  subtitle: string;
  logo: string;
  thumbnail: string;
  startDate: Date;
  stopDate: Date;
  price: Observable<string>;
  subEvents?: { [key: string]: number }
}

export interface EventCardConfig {
  appearance: "stream" | "playlist";
  size?: "small" | "medium" | "large";
  showCountdown: boolean;
  showDate?: boolean;
  showLogo?: boolean;
  showSubevents?: boolean;
  showSubtitle?: boolean;
  useMobileTemplate?: boolean;
  trackEventStatus?: boolean;
}

@Component({
  selector: 'yo-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit, OnDestroy, OnChanges {
  private ngUnsubscribe = new Subject();

  @Input("appearance") appearance: "stream" | "playlist";
  @Input("event") event: EventCardComponentInput;
  @Input("size") size: "small" | "medium" | "large" = "medium";
  @Input("showCountdown") showCountdown: boolean;
  @Input("showDate") showDate = true;
  @Input("showLogo") showLogo = true;
  @Input("showSubevents") showSubevents = false;
  @Input("showSubtitle") showSubtitle = true;
  @Input("useMobileTemplate") useMobileTemplate: boolean = false;
  @Input("trackEventStatus") trackEventStatus = false;

  @Output("eventFinished") eventFinished: EventEmitter<boolean> = new EventEmitter();

  _classes: string[] = [];

  hasThumbnail: boolean = false;
  checkTimer$: Subscription;
  routingMap = {
    livestream: "streams",
    livestreamGroup: "collections"
  };
  titleMaxLength = {
    small: 36,
    medium: 56,
    large: 79
  }
  countDownConfig: {
    resolution: "minute" | "second",
    placesToShow: number
  } = {
      resolution: "minute",
      placesToShow: 2
    };
  isStartingSoon = false;
  routerLink: string[] = [];
  now: Date = new Date();

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {

    this._classes = [
      this.appearance,
      this.size
    ];
    this.showCountdown = this.showCountdown != undefined ? this.showCountdown : this.appearance == 'stream';

    if (this.event.stopDate < new Date()) {
      this.showCountdown = false;
      this.trackEventStatus = false;
    }

    if (this.showCountdown) { this._classes.push("with-countdown"); }
    if (this.useMobileTemplate) { this._classes.push("mobile"); }

    this.hasThumbnail = this.eventHasThumbnail();
    const initialDelta = Math.ceil((this.event.startDate.getTime() - new Date().getTime()) / (MINUTES * SLICE));
    if (initialDelta <= 1) {
      this.isStartingSoon = true;
    }

    if (this.trackEventStatus) {
      this.updateCountDown();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.event 
    && changes.event.currentValue
    && changes.event.previousValue
    && this.eventHasChanged(changes.event.currentValue, changes.event.previousValue)) {
      this.hasThumbnail = this.eventHasThumbnail();
    }
  }

  isToday(): boolean {
    const startDate: Date = this.event.startDate;
    return (startDate.getDate() == this.now.getDate())
      && (startDate.getMonth() == this.now.getMonth())
      && (startDate.getFullYear() == this.now.getFullYear());
  }

  eventHasChanged(current: EventCardComponentInput, previous: EventCardComponentInput): boolean {
    let dateChanged = current.stopDate !== previous.stopDate || current.startDate !== previous.startDate;
    // For now keep it simple and only compare dates
    return dateChanged;
  }


  eventHasThumbnail(): boolean {
    return !this.event.thumbnail ? false : true;
  }

  updateCountDown() {
    const startTime = this.event.startDate;
    const stopTime = this.event.stopDate;
    const duration = Math.ceil((stopTime.getTime() - startTime.getTime()) / (MINUTES * SLICE));

    this.checkTimer$ = timer(0, (MINUTES * SLICE) / 2).pipe(
      map(interval => Math.ceil((startTime.getTime() - new Date().getTime()) / (MINUTES * SLICE))),
      filter(delta => delta <= 1),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      delta => {
        // console.log(delta);
        // console.log(duration);

        if (Math.abs(delta) < duration) {
          if (delta >= 1) {
            this.isStartingSoon = true;
            this.countDownConfig = { resolution: "second", placesToShow: 1 };
          } else {
            this.showCountdown = false;
            this.isStartingSoon = false;
          }
          this.cdr.detectChanges();
        } else {
          this.isStartingSoon = false;
          this.eventFinished.emit(true);
          this.checkTimer$.unsubscribe();
        }
      },
      err => console.error(err)
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
