<div class="container" fxLayout="column">
  <ng-container *ngFor="let navIconData of navIconDatas">
    <div *ngIf="!navIconData.positionBottom" class="clickable-area" fxLayout="column" fxLayoutAlign="center center" [routerLink]="navIconData.routerLink" routerLinkActive="active">
      <mat-icon>{{navIconData.iconName}}</mat-icon>
      <div class="text">{{navIconData.text}}</div>
    </div>
  </ng-container>
  <div fxFlex></div>
  <ng-container *ngFor="let navIconData of navIconDatas">
    <div *ngIf="navIconData.positionBottom" class="clickable-area" fxLayout="column" fxLayoutAlign="center center" [routerLink]="navIconData.routerLink" routerLinkActive="active">
      <mat-icon>{{navIconData.iconName}}</mat-icon>
      <div class="text">{{navIconData.text}}</div>
    </div>
  </ng-container>
</div>
