import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, of } from 'rxjs';
import { combineLatest, switchMap, take, takeUntil } from 'rxjs/operators';

import { Club } from 'src/app/core/model/interfaces/club';
import { ClubsService } from 'src/app/core/model/services/clubs.service';
import { Invite } from 'src/app/core/model/interfaces/invite';
import { InvitesService } from 'src/app/core/model/services/invites.service';

@Component({
  selector: "yo-accept-invite",
  templateUrl: "./accept-invite.component.html",
  styleUrls: ["./accept-invite.component.scss"]
})
export class AcceptInviteComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<any>();
  isLoading: boolean;
  invite: Invite;
  club: Club;
  acceptInviteResult: 'UNKNOWN' | 'SUCCESS' | 'SUCCESS-STREAMER' | 'ERROR' = 'UNKNOWN';
  acceptInviteResultErrorMessage: any;
  acceptInviteIntent: 'CHANGE_OWNERSHIP' | 'PRE_REGISTER' | 'OTHER' = 'OTHER';
  currentDate: Date;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invitesService: InvitesService,
    private clubsService: ClubsService) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.currentDate = new Date();
    this.isLoading = true;
    const inviteId = this.route.snapshot.queryParamMap.get('iid');

    this.invitesService.getByDocId(inviteId).pipe(
      take(1),
      switchMap(invite => {
        this.invite = invite;
        if (this.invite.intentType === 'CHANGE_OWNERSHIP') {
          this.acceptInviteIntent = 'CHANGE_OWNERSHIP';
        }

        if (this.invite.intentType === 'PRE_REGISTER') {
          this.acceptInviteIntent = 'PRE_REGISTER';
          return of(null);
        }

        if (this.invite instanceof Error) {
          this.acceptInviteResult = 'ERROR';
          this.acceptInviteResultErrorMessage = 'Unable to access the invite';
        } else if (this.invite.status !== 'PENDING' && this.invite.status !== 'PROCESSING') {
          this.acceptInviteResult = 'ERROR';
          this.acceptInviteResultErrorMessage = 'This invite has already been processed';
        }

        return this.clubsService.getByDocId(this.invite.clubDocId);
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(club => {
      this.club = club;

      if (this.club === undefined) {
        this.acceptInviteResult = 'ERROR';
        this.acceptInviteResultErrorMessage = "We couldn't find the club associated to this invite";
      }

      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  acceptInvite() {
    this.isLoading = true;
    const inviteId = this.route.snapshot.queryParamMap.get('iid');
    const token = this.route.snapshot.queryParamMap.get('token');
    console.log(this.route.snapshot.queryParamMap.get('token'));
    this.invitesService.acceptUserInvite(inviteId, token).pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ success, message }) => {
      this.isLoading = false;

      if (!success) {
        this.acceptInviteResult = 'ERROR';
        this.acceptInviteResultErrorMessage = message;
        return;
      }

      this.acceptInviteResult = 'SUCCESS';
      let navigationTarget;
      switch (this.invite.intentType) {
        case 'ADMIN':
          navigationTarget = ['/manage', 'clubs', this.invite.clubDocId];
          break;
        case 'STREAMER':
          this.acceptInviteResult = "SUCCESS-STREAMER";
          break;
        case 'TRAINER':
          navigationTarget = ['/manage', 'clubs', this.invite.clubDocId, 'teams', this.invite.teamDocId];
          break;
        case 'MEMBER':
          navigationTarget = ['/browse', 'clubs', this.invite.clubDocId];
          break;
        case 'PRE_REGISTER':
          navigationTarget = ['/browse'];
          break;
      }
      setTimeout(_ => navigationTarget && this.router.navigate(navigationTarget), 2500);

    });
  }

}
