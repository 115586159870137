<ng-container *ngIf='!isLoading; else loadingAnimation' [ngSwitch]='loginMode'>
  <form *ngSwitchCase='LoginMode.SIGN_IN'
        class="form-login yo-theme mat-typography"
        [class.compact]="compact"
        fxLayout="column" fxLayoutAlign="stretch"
        [formGroup]='loginForm' (ngSubmit)='login()'>
    <h1>Sign in</h1>
    <span class="mat-subheading-1">Sign into your account</span>
    <mat-form-field>
      <input matInput required placeholder="Email" autocapitalize="off" formControlName="email"
        [disabled]="!!targetEmail">
      <mat-label><mat-icon matPrefix>account_circle</mat-icon> Email</mat-label>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" required formControlName="password" autocapitalize="off" />
      <mat-label><mat-icon matPrefix>lock_open</mat-icon> Password</mat-label>
    </mat-form-field>
    <div *ngIf="loginErrorMessage" class="error-message">{{loginErrorMessage}}</div>
    <div fxLayout="row"
         fxWrap="wrap"
         fxLayoutAlign="center"
         fxLayout.lt-sm="column"
         fxLayoutGap="5px">
      <button fxFlex="1 1 0" mat-flat-button color="primary">SIGN IN</button>
      <ng-container *ngTemplateOutlet="socialLogin; context: {label: 'Google'}"></ng-container>
    </div>
    <br>
    <footer fxLayout="column"
         fxLayoutAlign="center center"
         fxLayoutGap="3px">
      <button mat-button
              type="button"
              class="forgot-password"
              fxFlexAlign="center"
              (click)="switchMode(LoginMode.RESET_PASSWORD)">Forgot password?</button>
    </footer>
  </form>

  <form *ngSwitchCase='LoginMode.REGISTER'
        class="register-form yo-theme mat-typography"
        [class.compact]="compact"
        fxLayout="column" fxLayoutAlign="center" fxLayoutGap="1em"
        [formGroup]='registerForm' (ngSubmit)='register()'>

    <h1>Sign up</h1>
    <span class="mat-subheading-2">or <a mat-stroked-button href='javascript:void()' (click)='switchMode(LoginMode.SIGN_IN)'>sign in</a> to your account</span>
    <mat-form-field>
      <input matInput placeholder="Email" autocapitalize="off" type="email" formControlName="email" [disabled]="!!targetEmail">
    </mat-form-field>
    <mat-form-field fxFlex="1 1 0">
      <input matInput
            type="password"
            placeholder="Password"
            formControlName="password">
    </mat-form-field>
    <mat-form-field fxFlex="1 1 0">
      <input matInput
            type="password"
            placeholder="Confirm password"
            formControlName="confirmPassword">
    </mat-form-field>
    <mat-checkbox class="conditions" formControlName="conditions" color="primary">
      <div mat-checkbox-label class="label">Accept <a href="https://www.joymo.tv/terms-of-service" target="_blank">terms of service</a></div>
    </mat-checkbox>
    <div *ngIf="registerErrorMessage" class="error-message" ngClass.xs="xs">{{registerErrorMessage}}</div>

    <div fxLayout="row"
         fxWrap="wrap"
         fxLayoutAlign="center"
         fxLayout.lt-sm="column"
         fxLayoutGap="5px">
      <button type="button"
              mat-flat-button
              fxFlex="1 1 0"
              color="primary" (click)="register()">REGISTER</button>
      <ng-container *ngTemplateOutlet="socialLogin; context: {label: 'Use Google'}"></ng-container>
    </div>
  </form>

  <form *ngSwitchCase='LoginMode.RESET_PASSWORD'
        class="password-reset-form yo-theme mat-typography"
        [class.compact]="compact"
        fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px"
        [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()"
        >
    <h1>Reset password</h1>
    <span class="mat-subheading-1">or <a mat-stroked-button href='javascript:void()' (click)='switchMode(LoginMode.SIGN_IN)'>sign in</a> to your account</span>
    <mat-form-field>
      <input matInput placeholder="Email" formControlName="email" autocapitalize="off" [disabled]="!!targetEmail">
    </mat-form-field>
    <div *ngIf="forgotPasswordErrorMessage" class="error-message" ngClass.xs="xs">
      {{forgotPasswordErrorMessage}}
    </div>
    <button mat-flat-button class="center-button" color="primary">Send password reset email</button>
    <div class="message">{{forgotPasswordEmailSentMessage}}</div>
  </form>

</ng-container>

<ng-template #loadingAnimation>
  <yo-loading [compact]='true'></yo-loading>
</ng-template>

<ng-template #socialLogin let-label="label">
  <button type="button"
          mat-raised-button
          fxFlex="1 1 0"
          (click)="useGoogle()">
    <img src="assets/icons/google.svg"
         alt="Log in using Google"> {{label}}
  </button>
</ng-template>
