import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeError,
  Token,
} from '@stripe/stripe-js';

import { AccessOption } from '../../core/model/services/payments.service';

@Component({
  selector: 'yo-confirm-payment-dialog',
  templateUrl: './confirm-payment-dialog.component.html',
  styleUrls: ['./confirm-payment-dialog.component.scss']
})
export class ConfirmPaymentDialogComponent implements OnInit {
  @ViewChild(StripeCardComponent, { static: true }) card: StripeCardComponent;
  @Input() ticketOption: AccessOption;
  @Output() tokenResult: EventEmitter<{ token?: Token, error?: StripeError }>;
  processingCard = false;
  constructor(
    private stripeService: StripeService
  ) {
    this.tokenResult = new EventEmitter();
  }

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#FFF',
        color: '#FFF',
        fontWeight: '300',
        fontFamily: 'sans-serif',
        fontSize: '14px',
        '::placeholder': {
          color: '#DDD',
        },
      },
    },
  };

  createToken(): void {
    this.processingCard = true;
    this.stripeService
      .createToken(this.card.element)
      .subscribe(token => {
        this.tokenResult.emit(token);
      });
  }

  ngOnInit() {
  }

}
