import { Component, Input, OnInit } from "@angular/core";

import { ClubsService } from "src/app/core/model/services/clubs.service";
import { LivestreamsService } from "src/app/core/model/services/livestreams.service";
import { Shareable } from "src/app/core/model/interfaces/shareable";
import { SnackBarService } from "src/app/core/utility/snack-bar.service";

interface SocialTarget {
  icon: string;
  label: string;
  iconUrl?: string;
  iconAlt?: string;
}

@Component({
  selector: "yo-social-icons",
  templateUrl: "./social-icons.component.html",
  styleUrls: ["./social-icons.component.scss"],
})
export class SocialIconsComponent implements OnInit {
  socialTargets: SocialTarget[];
  serviceProvider: {[key: string]: Shareable};

  @Input() type: "stream" | "club";
  @Input() docId: string;

  constructor(
    private clubService: ClubsService,
    private livestreamService: LivestreamsService,
    private snackbar: SnackBarService
  ) {
    this.socialTargets = [
      { label: "Facebook", icon: "facebook" },
      { label: "WhatsApp", icon: "whatsapp" },
      { label: "Messenger", icon: "messenger" },
    ];
    
    this.serviceProvider = {
      club: this.clubService,
      stream: this.livestreamService
    }
  }

  get shareableUrl() {
    return this.serviceProvider[this.type].getShareableURL(this.docId);
  }

  onCopiedUrl(): void {
    this.snackbar.openSnackBar(
      "URL copied to clipboard",
      "INFO",
      "check",
      5000,
      false
    );
  }

  getIconUrl(platform = ""): string {
    return `assets/icons/${platform.toLowerCase()}.svg`;
  }

  getIconAlt(platform = "") {
    return `Share this page to ${platform}`;
  }

  ngOnInit() {
    this.socialTargets = this.socialTargets.map((target) => ({
      ...target,
      iconAlt: this.getIconAlt(target.label),
      iconUrl: this.getIconUrl(target.label),
    }));
  }
}
