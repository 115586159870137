import { Component, Input } from "@angular/core";

@Component({
  selector: "yo-empty-section",
  templateUrl: "./empty-section.component.html",
  styleUrls: ["./empty-section.component.scss"],
})
export class EmptySectionComponent {
  @Input() title: string;
  @Input() description: string;
}
