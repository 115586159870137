import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AuthService } from 'src/app/core/auth/auth.service';
import { LivestreamTechnical } from 'src/app/core/model/interfaces/livestream';
import { SnackBarService } from 'src/app/core/utility/snack-bar.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'yo-rtmp-endpoint',
  templateUrl: './rtmp-endpoint.component.html',
  styleUrls: ['./rtmp-endpoint.component.scss']
})
export class RtmpEndpointComponent implements OnInit, OnDestroy {
  @Input() livestreamTechnicals: Observable<LivestreamTechnical>;
  private ngUnsubscribe = new Subject();
  authenticatedUserEmail: string;

  availableToStream: boolean;

  AvailableTimeBeforeStart = "one hour";
  
  constructor(
    private snackBar: SnackBarService,
    private authService: AuthService,
  ) {
  }

  rtmpEndpoint: string;
  rtmpStreamKey: string;

  selectAndCopy(event) {
    event.target.select();
    document.execCommand('copy');
    this.snackBar.openSnackBar("Entry has been copied to the clipboard.", 'SUCCESS', undefined, 1500);
  }

  ngOnInit() {
    this.livestreamTechnicals
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(lst => {
        if (lst && lst.rtmpUrl) {
          const split = lst.rtmpUrl.lastIndexOf('/');
          this.rtmpEndpoint = lst.rtmpUrl.substr(0, split+1);
          this.rtmpStreamKey = lst.rtmpUrl.substr(split+1);
        }
        this.availableToStream =
          !lst ||
          ![
            "STACK_STOPPING",
            "STACK_CREATION_FAILED",
            "STACK_STOPPED",
            "ARCHIVED",
          ].includes(lst.schedulingState);
      });
    this.authService.authenticatedUser$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => (this.authenticatedUserEmail = user.email));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
