<div class="yo-theme subchannel-selector">
  <mat-form-field fxLayoutAlign="center" floatLabel="never">
    <!-- <mat-label>Subchannels</mat-label> -->
    <mat-select
      [formControl]="subchannelFormControl">
      <mat-option [value]="DEFAULT_VALUE">All subchannels</mat-option>
      <mat-option *ngFor="let subchannel of subchannels"
        [value]="subchannel.docId">
        {{ subchannel.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>