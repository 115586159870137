import { ComponentFactoryResolver, Directive, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AuthService } from '../../auth/auth.service';
import { Collection } from '../collection';
import { ConfirmPaymentDialogComponent } from '../../../yoimo-ui/confirm-payment-dialog/confirm-payment-dialog.component';
import { EventCardComponentInput } from 'src/app/yoimo-ui/cards/event/event-card.component';
import { Injectable } from '@angular/core';
import { Livestream } from '../interfaces/livestream';
import { LivestreamGroup } from '../interfaces/livestream-group';
import { LivestreamGroupsService } from './livestream-groups.service';
import { Payment } from '../interfaces/payment';
import { StripeError } from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';
import { combineLatest } from 'rxjs';

/**
 * A payment giving direct access to the stream.
 */
export interface DirectAccess {
  type: 'DIRECT';
  provider: 'STRIPE';
  paymentPayload: Partial<Payment>;
  formattedPrice: string;
  price: number;
  currency: string;
  label: string;
  description: string;
  productId: string;
  productName: string;
  icon: 'vod' | 'ppv' | 'bundle' | string;
  //RETURN RELEVANT PAYMENT DETAIL HERE THAT CAN BE USED BY THE VIEW.
}
/**
 * A proxy access is through a season pass / league pass /
 * playlist ticket.
 */
export interface ProxyAccess {
  type: 'PROXY';
  formattedPrice: string;
  price: number;
  currency: string;
  label: string;
  description: string;
  paymentPayload: Partial<Payment>;
  url: string;
  productId: string;
  productName: string;
  icon: 'vod' | 'ppv' | 'bundle' | string;
}

export type AccessOption = DirectAccess | ProxyAccess;

const DEFAULT_TEXTS_FOR_TICKETS = {
  'ppv': ['Live Ticket', 'Watch this stream live'],
  'bundle': ['Live and replay', 'Watch this stream live and on demand for the next 7 days.'],
  'vod': ['Replay Ticket', 'Watch a replay of this stream for up to 7 days.'],
};

@Directive({
  selector: '[paymentCaptureHost]',
})
export class PaymentCaptureHostDirective implements OnInit, OnDestroy {
  @Input() paymentOption: AccessOption;
  @Output() paymentResult: EventEmitter<Payment>;
  @Output() paymentError: EventEmitter<Error>;
  @Output() paymentMethodCaptured: EventEmitter<boolean>;
  ngUnsubscribe$ = new Subject();

  constructor(
    public viewContainerRef: ViewContainerRef,
    public paymentsService: PaymentsService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.paymentResult = new EventEmitter();
    this.paymentError = new EventEmitter();
    this.paymentMethodCaptured = new EventEmitter();
  }
  ngOnInit() {
    const factory = this.componentFactoryResolver.resolveComponentFactory<ConfirmPaymentDialogComponent>(ConfirmPaymentDialogComponent);
    this.viewContainerRef.clear();
    const compRef = this.viewContainerRef.createComponent<ConfirmPaymentDialogComponent>(factory);
    compRef.instance.ticketOption = this.paymentOption;
    compRef.instance.tokenResult.subscribe(tr => {
      this.paymentMethodCaptured.next(tr.error ? false : true);
    });
    this.paymentsService
      .handlePayment(this.paymentOption, this.paymentOption.price !== 0 ? compRef.instance.tokenResult : undefined)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((p) => {
        this.paymentResult.emit(p);
      }, error => {
        this.paymentError.emit(error);
        this.paymentResult.complete();
      });
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}

@Injectable({
  providedIn: 'root',
})
export class PaymentsService extends Collection<Payment> {
  /*stripe = Stripe(environment.stripe.publicKey);
  elements = this.stripe.elements();*/

  constructor(
    injector: Injector,
    private stripeService: StripeService,
    private authService: AuthService,
    private livestreamGroupsService: LivestreamGroupsService,
  ) {
    super(injector, "payments", true);
    console.warn('Using the default converter for PaymentsService');
  }

  createDirectPaymentOptionForLivestream(
    livestream: Livestream,
    transactionType: 'ppv' | 'vod' | 'bundle',
    productRedirect?: string
  ): DirectAccess {
    const redirectUrl = new URL(
      productRedirect ? productRedirect : '/browse/streams/' + livestream.docId,
      document.location.href
    ).toString();

    let selectedKeys: (keyof Livestream)[];

    switch (transactionType) {
      case 'ppv':
        selectedKeys = ['price', 'priceLabel', 'priceDescription'];
        break;
      case 'vod':
        selectedKeys = ['vodPrice', 'vodLabel', 'vodDescription'];
        break;
      case 'bundle':
        selectedKeys = ['bundlePrice', 'bundleLabel', 'bundleDescription'];
        break;
    }
    const defaultLabels = DEFAULT_TEXTS_FOR_TICKETS[transactionType];

    const priceLabels = {
      formattedPrice: this.formatPrice(
        livestream[selectedKeys[0]],
        livestream.force_currency
      ),
      price: livestream[selectedKeys[0]],
      currency: livestream.force_currency || 'nok',
      label: livestream[selectedKeys[1]] || defaultLabels[0],
      description: livestream[selectedKeys[2]] || defaultLabels[1],
      icon: transactionType,
    };

    const payment: Partial<Payment> = {
      integration_version: 1,
      paymentType: 'stripe',
      productDocId: livestream.docId,
      productType: 'livestream',
      transactionType: transactionType,
      redirectUrl,
      referral: null,
    };
    return {
      ...priceLabels,
      paymentPayload: payment,
      provider: 'STRIPE',
      type: 'DIRECT',
      productName: livestream.name,
      productId: livestream.docId
    };
  }

  createProxyPaymentOption(group: LivestreamGroup, productRedirect?: string): ProxyAccess {
    const [price, transactionType]: [number, Payment['transactionType']] =
      group.stopTime.toMillis() < Date.now()
        ? [group.vodPrice, 'vod']
        : [group.bundlePrice, 'bundle'];
    const payment: Partial<Payment> = {
      integration_version: 1,
      paymentType: 'stripe',
      productDocId: group.docId,
      productType: 'livestreamGroup',
      transactionType: transactionType,
      redirectUrl: productRedirect || "/browse/streams/' + livestream.docId",
      referral: null,
    };
    return {
      type: 'PROXY',
      label: group.name,
      description: `${group.clubName} • ${group.subGroups ? group.description : group.livestreamDocIds.length + ' streams'}`,
      formattedPrice: this.formatPrice(price, group.force_currency),
      price: price,
      currency: group.force_currency || 'nok',
      paymentPayload: payment,
      url: `/checkout/collections/${group.docId}${productRedirect ? '?redir=' + encodeURIComponent(productRedirect) : ''}`,
      icon: group.imageUrls?.main || '',
      productName: group.name,
      productId: group.docId
    };
  }

  // Stores the payment in Firestore, then returns the payment as an observable.
  // If the 'confirmed' property on Payment gets set to true, that means the payment was successful
  createAndReturnPayment(payment: Partial<Payment>, clientSideConfirmation: boolean = false): Observable<Payment | any> {
    return this.authService.authenticatedUser$.pipe(
      filter((user) => user !== null),
      take(1),
      switchMap(
        user => this.createAndListen({
          ...payment as Payment, // We force the compiler to accept this. #TODO: make sure we receive the appropriate type
          clientSideConfirmation,
          userDocId: user.docId,
        })
      ),
      tap(e => console.log(e))
    );
  }

  redirectToCheckout(
    sessionId: string
  ): Observable<{
    error: StripeError;
  }> {
    return this.stripeService.redirectToCheckout({ sessionId });
  }

  /**
   * Handles the payment and returning it as an observable.
   * As opposed to createAndReturned payment, this function
   * is responsible for redirecting the user to the payment
   * processing service, Or ask the user for card details
   * directly.
   */
  handlePayment(accessOption: AccessOption, tokenObservable?: Observable<any>): Observable<Payment> {
    const paymentPayload: Partial<Payment> = accessOption.paymentPayload;
    if (tokenObservable) {
      return combineLatest([
        tokenObservable,
        this.createAndReturnPayment(paymentPayload, true)
      ]).pipe(
        switchMap(([cardToken, payment]) => {
          if (cardToken === null) {
            throw new Error('No payment method was provided');
          }
          if (cardToken.error) {
            throw new Error(cardToken.error.message);
          }
          if (payment.stripeSession && !payment.stripeResponse) {
            return this.stripeService
              .confirmCardPayment(payment.stripeSession.client_secret, {
                payment_method: {
                  card: {
                    token: cardToken.token.id,
                  },
                },
              }).pipe(
                map(result => {
                  if (result.error) {
                    throw new Error(result.error.message);
                  } else {
                    return of(payment);
                  }
                })
              );
          } else {
            return of(payment);
          }
        }),
      );
    } else {
      return this.createAndReturnPayment(paymentPayload, false).pipe(
        tap(payment => {
          if (!payment.stripeSession) {
            return;
          }
          this.redirectToCheckout(payment.stripeSession.id).subscribe(redirectionError => {
            throw new Error(redirectionError.error.message);
          });
        })
      );
    }
  }

  formatPrice(price: number, currency: string) {
    // console.log([price, currency]);
    if (price === 0) {
      return 'Free';
    }
    if (Intl) {
      return Intl.NumberFormat('en-US', { style: 'currency', currency: currency || 'NOK', currencyDisplay: 'symbol' }).format(price);
    } else {
      return `${currency || 'NOK'}${price.toFixed(2)}`;
    }
  }

  /**
   * Return the list of ticket option giving access to this stream
   * @param livestream Livesteam object
   */
  getTicketOptionsForStream(
    livestream: Livestream,
    productRedirect: string,
    excludeProxyOptions = false
  ): Observable<AccessOption[]> {
    if (livestream.archived) {
      return of([]);
    }
    const options: ('ppv' | 'vod' | 'bundle')[] = [];
    const streamEnd = livestream.scheduledStopTime.toMillis();
    const now = Date.now();
    // Live ticket
    if (livestream.price !== undefined && streamEnd > now) {
      options.push('ppv');
    }
    // VOD, only when the stream is supposed to be finished
    if (livestream.availableForVod && streamEnd < now && livestream.vodPrice !== undefined) {
      options.push('vod');
    }
    if (livestream.availableForBundle && streamEnd > now && livestream.bundlePrice !== undefined) {
      options.push('bundle');
    }
    return combineLatest([
      of(options),
      excludeProxyOptions ? of([]) : this.livestreamGroupsService.getGroupsForLivestream(livestream.docId)
    ]).pipe(
      take(1),
      map(([opts, groups]) => {
        return [
          ...opts.map(o => this.createDirectPaymentOptionForLivestream(livestream, o, productRedirect)),
          ...groups.map(g => this.createProxyPaymentOption(g, productRedirect))
        ];
      })
    );
  }

  getPaymentsForUser(userDocId: String): Observable<Payment[]> {
    return this.query(['userDocId', '==', userDocId]);
  }

  getCheapestOption(options: AccessOption[]): AccessOption {
    return options.sort((o1, o2) => o2.price < o1.price ? 1 : -1)[0];
  }

  fromStreamToEventCardInput(stream: Livestream): EventCardComponentInput {
    return {
      id: stream.docId,
      title: stream.name,
      subtitle: stream.clubName,
      thumbnail: (stream.imageUrls && stream.imageUrls.main) ? stream.imageUrls.main : '',
      logo: (stream.imageUrls && stream.imageUrls.secondary) ? stream.imageUrls.secondary : '',
      routerLink: ['/browse', 'streams', stream.docId],
      startDate: stream.scheduledStartTime.toDate(),
      stopDate: stream.scheduledStopTime.toDate(),
      price: this.getTicketOptionsForStream(stream, undefined, false)
        .pipe(
          switchMap(options => {
            const cheapestOption: AccessOption = this.getCheapestOption(options);
            return of(cheapestOption.formattedPrice || "");
          })
        )
    };
  }

  fromCollectionToEventCardInput(collection: LivestreamGroup) {
    return {
      id: collection.docId,
      title: collection.name,
      subtitle: collection.clubName,
      thumbnail: (collection.imageUrls && collection.imageUrls.main) ? collection.imageUrls.main : '',
      logo: (collection.imageUrls && collection.imageUrls.secondary) ? collection.imageUrls.secondary : '',
      routerLink: ['/browse', 'collections', collection.docId],
      startDate: collection.startTime.toDate(),
      stopDate: collection.stopTime.toDate(),
      price: of(this.createProxyPaymentOption(collection).formattedPrice)
    };
  }
}
