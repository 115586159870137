import {Component, Input, OnInit} from '@angular/core';
import {NavIconData} from '../nav-icon-data';

@Component({
  selector: 'yo-bottomnav',
  templateUrl: './bottomnav.component.html',
  styleUrls: ['./bottomnav.component.css']
})
export class BottomnavComponent implements OnInit {
  @Input() navIconDatas: NavIconData[];

  constructor() { }

  ngOnInit() {
  }

}
