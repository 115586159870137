import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SNACK_BAR_TYPE } from 'src/app/core/utility/snack-bar.service';

export interface SnackBarData {
  message: string,
  snackType: SNACK_BAR_TYPE,
  snackIcon?: string
  dismissable?: boolean
}

@Component({
  selector: 'yo-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SnackBarComponent implements OnInit {

  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData,
  ) {
    this.data = data;
  }

  ngOnInit() {
  }

  get getIcon() {
    if (this.data.snackIcon) { return this.data.snackIcon; }
    
    switch (this.data.snackType) {
      case "SUCCESS":
        return "done";
      case "ERROR":
        return "error";
      case "INFO":
        return "info";
    }
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
