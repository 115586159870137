<div style="display:flex;flex-direction:row;align-items: center;">
  <a class="icon" href="https://www.joymo.tv/" target="blank">
    <yo-logo [variants]="['white']"></yo-logo>
  </a>
  <a href="https://www.instagram.com/joymonorway/" target="blank">
    <i class="icon-instagram icon"></i>
  </a>
  <a href="https://www.facebook.com/joymotv" target="blank">
    <i class="icon-facebook icon"></i>
  </a>
</div>

