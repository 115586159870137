import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AuthService} from '../../core/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {take} from 'rxjs/operators';

@Component({
  selector: 'yo-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.css']
})
export class ResetPasswordFormComponent implements OnInit {
  @Output() passwordSuccessfullyReset = new EventEmitter<boolean>();
  @Input() mode: 'PASSWORD_RESET' | 'FINISH_PREREGISTRATION' = 'PASSWORD_RESET';
  errorMessage: string;
  actionCode: string;
  loading = false;

  resetPasswordForm = new FormGroup({
    newPassword: new FormControl(''),
    confirmPassword: new FormControl(''),
  });
  inviteMode: boolean;
  resetButtonText: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.resetButtonText = this.mode === 'FINISH_PREREGISTRATION' ? 'Finalize account' : 'Reset password';
    this.route.queryParams.pipe(take(1)).subscribe(queryParams => {
      // If the mode is not resetPassword, reroutes the user
      if (queryParams['mode'] !== 'resetPassword') {
      }
      this.actionCode = queryParams['oobCode'];
    });
  }

  resetPassword() {
    if (!this.resetPasswordForm.valid) {
      this.errorMessage = 'Please give valid values';
      return;
    }

    if (this.resetPasswordForm.value.newPassword !== this.resetPasswordForm.value.confirmPassword) {
      this.errorMessage = 'The two passwords did not match.';
      return;
    }
    this.loading = true;

    this.authService.resetPassword(this.actionCode, this.resetPasswordForm.value.newPassword)
      .subscribe(() => {
        this.passwordSuccessfullyReset.emit(true);
        this.loading = false;
      },
      error => {
        this.errorMessage = error.message;
        this.loading = false;
      }
    );
  }

}
