import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';

import { ClientService } from '../../core/utility/client.service';
import { LoginMode } from '../LoginMode.enum';
import { take } from 'rxjs/operators';

@Component({
  selector: "yo-login-view",
  templateUrl: "./login-view.component.html",
  styleUrls: ["./login-view.component.scss"],
})
export class LoginViewComponent implements OnInit {
  readonly LoginMode = LoginMode;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private clientService: ClientService
  ) { }

  isInWebView: boolean;
  layoutType = "landscape";
  layoutOrientation = "web";
  mode = "LOGIN";
  targetEmail?: string;
  returnUrl: string;
  currentDate: Date;
  returnRouteState: any;

  ngOnInit() {
    this.currentDate = new Date();
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web
    ]).subscribe(res => {
      this.setupLayout(res);
    });

    this.isInWebView = this.clientService.isClientInApp();

    if (this.route.snapshot.queryParams.bu) {
      this.targetEmail = this.route.snapshot.queryParams.bu;
    }

    this.route.data.pipe(take(1)).subscribe((data) => {
      if (
        data.mode === "RESET_PASSWORD" &&
        this.route.snapshot.queryParams["preregistration"]
      ) {
        this.mode = "FINISH_PREREGISTRATION";
      } else {
        this.mode =
          data.mode || this.route.snapshot.queryParams["mode"] || "LOGIN";
        //console.log("Will use this mode", this.mode);
      }
    });

    this.returnRouteState = window.history.state;

    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] ||
      this.route.snapshot.queryParams["continueUrl"] ||
      "/";
    console.log("Return URL is ", this.returnUrl);
  }

  setupLayout({ breakpoints }) {
    if (
      breakpoints[Breakpoints.HandsetLandscape] ||
      breakpoints[Breakpoints.TabletLandscape] ||
      breakpoints[Breakpoints.WebLandscape]
    ) {
      this.layoutOrientation = "landscape";
    } else {
      this.layoutOrientation = "portrait";
    }

    if (
      breakpoints[Breakpoints.Handset] ||
      breakpoints[Breakpoints.HandsetLandscape] ||
      breakpoints[Breakpoints.HandsetPortrait]
    ) {
      this.layoutType = "handset";
    } else if (
      breakpoints[Breakpoints.Tablet] ||
      breakpoints[Breakpoints.TabletLandscape] ||
      breakpoints[Breakpoints.TabletPortrait]
    ) {
      this.layoutType = "tablet";
    } else {
      this.layoutType = "web";
    }
  }

  loggedIn() {
    // console.log(this.returnUrl);
    const returnUrl = decodeURIComponent(this.returnUrl || "/browse");
    // console.log(returnUrl);
    const [path, queryParams] = returnUrl.split("?");
    // console.log("values", [path, queryParams]);
    const options = queryParams ? {
      state: this.returnRouteState,
      queryParams: Object.fromEntries(this.getQueryParamMap(queryParams).entries())
    } : { state: this.returnRouteState };
    console.log("Return url is", path, options);
    this.router.navigate([path], options).catch((err) => console.error(err));
  }

  getQueryParamMap(queryParamString: string): Map<string, string> {
    const keyValues = queryParamString.split("&");
    const queryParamMap = keyValues.reduce((paramMap, keyValue) => {
      const [key, value] = keyValue.split("=");
      paramMap.set(key, value);
      return paramMap;
    }, new Map());
    
    console.log("Query param map is", queryParamMap, this.router);
    return queryParamMap;
  }

  passwordSuccessfullyReset() {
    this.loggedIn();
  }
}
