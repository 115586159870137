import { LivestreamGroup } from '../../core/model/interfaces/livestream-group';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'yo-livestream-group-card',
  templateUrl: './livestream-group-card.component.html',
  styleUrls: ['./livestream-group-card.component.css']
})
export class LivestreamGroupCardComponent implements OnInit {
  @Input() livestreamGroup: LivestreamGroup;
  @Input() authenticatedUserDocId: string;
  livestreamStatus: {status: string, icon: string, bgColor: string};

  constructor() { }

  ngOnInit() {
    const startTime: Date = this.livestreamGroup.startTime.toDate();
    const stopTime: Date = this.livestreamGroup.stopTime.toDate();

    // TODO: Add internationalization to get these strings from a localized dictionary
      stopTime >= new Date() ?
        startTime > new Date() ?
          this.livestreamStatus = { status: 'On Schedule', icon: 'event_available', bgColor: 'rgb(68, 171, 255)' }
        : this.livestreamStatus = { status: 'Live', icon: 'play_circle_filled_white', bgColor: 'rgb(255, 81, 81)' }
      : this.livestreamStatus = { status: 'Finished', icon: 'tv_off_filled_white', bgColor: 'rgb(255, 81, 81)' };
  }

}
