import {ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot} from '@angular/router';
import { first, map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private afAuth: AuthService,
    private router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.afAuth.authenticatedUser$.pipe(
      first(),
      map(authUser => {
          if (authUser === null) {
            const queryParams: Params = {returnUrl: state.url};
            if (next.data.preferedLoginMode !== undefined) {
              queryParams.mode = next.data.preferedLoginMode;
            }
            this.router.navigate(['/login'], {queryParams});
            return false;
          }
          return true;
        }
      )
    );
  }
}
