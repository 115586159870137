import {
  Auth,
  GoogleAuthProvider,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { AnalyticsService } from '../model/services/analytics.service';
import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { User } from '../model/interfaces/user';
import { UsersService } from '../model/services/users.service';
import { authState } from 'rxfire/auth';
import { environment } from 'src/environments/environment';

export const AUTH_ERROR_CODES = {
  'auth/invalid-email': 'This email address is invalid, please verify the email address is correct',
  'auth/user-not-found': 'We could not find a user with this email address, please verify you have entered a valid email address',
  'auth/user-disabled': 'The user has been disabled',
  'auth/wrong-password': 'Wrong password',
  'auth/email-already-exists': 'The provided email is already in use by an existing user. Each user must have a unique email.',
  'auth/email-already-in-use': 'The provided email is already in use by an existing user. Each user must have a unique email.',
  'auth/weak-password': 'Your password is not secure, please use a complex combination of uppercase lowercase letters, numbers, and symbols.',
  'auth/invalid-verification-code': 'The verification code is invalid.',
  'auth/invalid-verification-id': 'The verification id is invalid.',
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authenticatedUserSource: BehaviorSubject<User>;

  private _cachedUser: User;
  private _authenticationReady = false;
  public get authenticatedUser$(): Observable<User> {
    return this.authenticatedUserSource.asObservable().pipe(filter(user => user !== undefined));
  }

  stabilizedUser(expectedUserId): Observable<User> {
    return this.authenticatedUser$.pipe(filter(u => u !== null && u.docId === expectedUserId));
  }

  constructor(
    public afAuth: Auth,
    private analytics: AnalyticsService,
    private usersService: UsersService,
    private http: HttpClient
  ) {
    authState(this.afAuth)
      .pipe(
        switchMap((firebaseUser) => {
          if (firebaseUser) {
            return this.usersService.getByDocId(firebaseUser.uid);
          } else {
            return of(null);
          }
        })
      )
      .subscribe((user: User) => {
        this.updateAuthenticatedUser(user);
      });
    this.authenticatedUserSource = new BehaviorSubject<User>(undefined);
    this.authenticatedUserSource.subscribe(x => x);
  }

  login(credentials: { email: string, password: string }): Observable<User> {
    const login$ = from(signInWithEmailAndPassword(this.afAuth, credentials.email.toLowerCase().trim(), credentials.password))
      .pipe(
        switchMap(result => {
          if (result.user) {
            this.analytics.userLoggedIn('email');
            return this.stabilizedUser(result.user.uid);
          } else {
            throw new Error("Not able to login");
          }
        })
      );
    return login$;
  }

  logInWithGoogle(): Observable<User> {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    return from(signInWithPopup(this.afAuth, provider))
      .pipe(
        switchMap( result => {
          if (result.user) {
            this.analytics.userLoggedIn('google');
            return this.stabilizedUser(result.user.uid);
          } else {
            throw new Error('Not able to login');
          }
        })
      );
    //return await signInWithPopup(this.afAuth, provider);
  }

  logout() {
    return this.http
      .post<void>(
        environment.authAppUrl + "/authentication",
        { action: "logout" },
        { withCredentials: true }
      )
      .toPromise()
      .finally(() => this.afAuth.signOut());
  }

  async logInWithToken(token: string) {
    const response = await signInWithCustomToken(this.afAuth, token);
    this.analytics.userLoggedIn("token");
  }

  // Todo: Make sure that errors are returned properly
  register(credentials: {email: string, password: string}): Observable<any> {
    return from(createUserWithEmailAndPassword(this.afAuth, credentials.email.toLowerCase().trim(), credentials.password))
      .pipe(
        switchMap( result => {
          if (result.user) {
            return this.stabilizedUser(result.user.uid);
          } else {
            return of(null);
          }
        })
      );
      /*.pipe(
        tap(res => console.log(res)),
        switchMap(() => {
          const callable = this.fns.httpsCallable('users-createOrUpdatePassword');
          return callable({ password: credentials.password });
        }),
        take(1),
        map(result => {
          console.log(result);
          if (result.success) {
            this.analytics.userSignedUp('SPONTANEOUS');
          }
          return result.success;
        })
      );*/
  }

  resetPassword(actionCode: string, newPassword: string): Observable<any> {
    let accountEmail;

    return from(verifyPasswordResetCode(this.afAuth, actionCode)).pipe(
      switchMap(email => {
        accountEmail = email;
        return confirmPasswordReset(this.afAuth, actionCode, newPassword);
      }),
      switchMap(() => {
        return signInWithEmailAndPassword(this.afAuth, accountEmail, newPassword);
      }),
      take(1),
      map(result => {
        return true;
      }),
      catchError(error => {
        console.log("Error caught", error);
        throw error;
      })
    );
  }

  forgotPassword(email: string): Observable<void> {
    return from(sendPasswordResetEmail(this.afAuth, email));
  }

  updateAuthenticatedUser(user: User) {
    if (!this._authenticationReady) {
      this._cachedUser = user;
    } else {
      this.authenticatedUserSource.next(user);
    }
  }

  getUserSigningMethods(email: string): Observable<string[]> {
    return from(fetchSignInMethodsForEmail(this.afAuth, email));
  }

  formatErrorMessage(error): string {
    return AUTH_ERROR_CODES[error.code] || 'Unexpected error occurred.';
  }

  /**
   * Retrieve custom token if user has a session cookie already.
   */
  private getCustomToken() {
    return fetch(environment.authAppUrl + "/authentication", {
      credentials: "include",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ action: "login" }),
    });
  }

  goToAuthApp(additionalQueryParams?: Record<string, string>): void {
    const url = new URL(environment.authAppUrl);
    url.pathname = "arena";
    url.searchParams.set("redirect_url", location.href);

    if (additionalQueryParams) {
      Object
        .entries(additionalQueryParams)
        .forEach(([paramkey, paramValue]) => url.searchParams.set(paramkey, paramValue));
    }

    location.assign(url.href);
  }

  /**
   * Logs in the user if the "__session" cookie is available
   * @param redirectIfUnauthorized Triggers a redirection to auth app
   */
  async useSessionCookie(redirectIfUnauthorized: boolean): Promise<boolean> {
    const res = await this.getCustomToken();
    const badCookie = res.status > 204;

    if (badCookie) {
      redirectIfUnauthorized && this.goToAuthApp();
      return false;
    }

    await this.logInWithToken(await res.text());
    return true;
  }

  async finalizeInitialization() {
    if (!this.afAuth.currentUser) {
      const loggedIn = await this.useSessionCookie(false);
      this._authenticationReady = true;
      if (!loggedIn) {
        this.updateAuthenticatedUser(this._cachedUser);
      }
    } else {
      this._authenticationReady = true;
      this.updateAuthenticatedUser(this._cachedUser);
    }
  }

}
