<mat-tab-group #streamsTabGroup mat-stretch-tabs mat-align-tabs="center" style="padding: 0.5em"
  [selectedIndex]="mapActiveTabToIndex()"
  [class.mobile]="isMobileView"
  (selectedTabChange)="onSelectedTabChange($event)">

  <mat-tab #upcomingStreams>
    <!-- Label template -->
    <ng-template mat-tab-label>
      <div (click)="onSelectFuture()">
        <mat-icon style="vertical-align: middle;">fast_forward</mat-icon> Upcoming
      </div>
    </ng-template>

    <!-- Content template -->
    <ng-template matTabContent>
      <ng-container *ngTemplateOutlet="streamsTabTemplate"></ng-container>
    </ng-template>
  </mat-tab>
  
  <mat-tab #pastStreams>
    <!-- Label template -->
    <ng-template mat-tab-label>
      <div (click)="onSelectPast()">
        <mat-icon style="vertical-align: middle;">history</mat-icon> Replay
      </div>
    </ng-template>

    <!-- Content template -->
    <ng-template matTabContent>
      <ng-container *ngTemplateOutlet="streamsTabTemplate"></ng-container>
    </ng-template>
  </mat-tab>

  <ng-template #streamsTabTemplate>
    <table class="streams" mat-table *ngIf='streamDataSource.filteredData.length>0 && !datasourceLoading' [dataSource]="streamDataSource">
      <ng-container matColumnDef='streams'>
        <td mat-cell class="stream" *matCellDef="let stream">
          <div class="card">
            <yo-event-card class="flex-grow"
              [showCountdown]="false"
              [event]="stream"
              [appearance]="'stream'"
              [useMobileTemplate]="true"></yo-event-card>
          </div>
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: ['streams']" class="stream-row"></tr>
    </table>
    <div class="table-empty yo-theme mat-typography"
      *ngIf='streamDataSource.filteredData.length===0 && !datasourceLoading'>
      <h1>Ooops! It's empty</h1>
      <h3>No streams matching the selected filter were found.</h3>
    </div>
    <div *ngIf='datasourceLoading'>
      <yo-loading></yo-loading>
    </div>
  </ng-template>


</mat-tab-group>