import {
  AcceptInviteAdminComponent,
  AcceptInviteStreamerComponent,
  AcceptInviteTrainerComponent,
  AcceptInviteViewerComponent
} from './accept-invite/accept-invite-templates';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AcceptInviteComponent } from './accept-invite/accept-invite.component';
import { AcceptOwnershipComponent } from './accept-ownership/accept-ownership.component';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginViewComponent } from './login-view/login-view.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgModule } from '@angular/core';
import { NgxStripeModule } from 'ngx-stripe';
import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component';
import { RouterModule } from '@angular/router';
import { YoimoUiModule } from '../yoimo-ui/yoimo-ui.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    YoimoUiModule,
    MatCardModule,
    RouterModule,
    MatExpansionModule,
    NgxStripeModule,
    MatSidenavModule
  ],
  exports: [
    LoginViewComponent,
    LoginFormComponent
  ],
  declarations: [
    LoginViewComponent,
    LoginFormComponent,
    ResetPasswordFormComponent,
    AcceptInviteComponent,
    AcceptInviteAdminComponent,
    AcceptInviteTrainerComponent,
    AcceptInviteStreamerComponent,
    AcceptInviteViewerComponent,
    AcceptOwnershipComponent
  ]
})
export class AuthModule { }
