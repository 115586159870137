<div class="container">
  <button class="close-button" mat-icon-button color="primary" (click)="closeDialog()">
    <mat-icon aria-label="Close icon">close</mat-icon>
  </button>
  <div class="header">
    <span>{{title}}</span>
  </div>
  <div class="content">
    <ng-content select=".content"></ng-content>
  </div>
</div>
