<article class="highlights">
  <ul class="highlights--player">
    <li *ngIf="!isMobile">
      <button
        type="button"
        data-toggle="prev"
        (click)="activeIndex = activeIndex - 1"
      >
        <img alt="See previous highlight" src="assets/icons/arrow-left.svg" />
      </button>
    </li>
    <li role="presentation">
      <video
        (ended)="activeIndex = activeIndex + 1"
        [ngClass]="{ dimmed: videoPlayer.paused }"
        [src]="videoUrl"
        #video
        playsinline
        preload="auto"
        controlslist="nodownload"
      ></video>
      <div
        *ngIf="videoPlayer?.paused"
        aria-label="Play or pause the current highlight"
        role="dialog"
      >
        <button (click)="pauseOrPlay()" role="play-video">
          <img alt="Play cover video" src="assets/icons/play.svg" />
        </button>
      </div>
    </li>
    <li *ngIf="!isMobile">
      <button
        type="button"
        data-toggle="next"
        (click)="activeIndex = activeIndex + 1"
      >
        <img alt="See next highlight" src="assets/icons/arrow-right.svg" />
      </button>
    </li>
  </ul>

  <ul #trackContainer class="highlights--track">
    <li *ngFor="let _ of highlights; let i = index" (click)="activeIndex = i">
      <progress
        *ngIf="activeIndex === i"
        [attr.value]="videoPlayer.currentTime"
        [attr.max]="videoPlayer.duration"
      ></progress>
      <progress *ngIf="activeIndex !== i" max="0" value="0"></progress>
    </li>
  </ul>

  <section class="highlights--about">
    <header>
      <h3>{{ highlight.name }}</h3>
      <p>{{ highlight.description }}</p>
    </header>
  </section>

  <section class="highlights--watch-full-video">
    <a
      [href]="highlight.eventUrl"
      *ngIf="highlight.eventUrl"
      role="primary"
      target="_blank"
      >Watch full video</a
    >
    <div
      aria-label="See previous or next slides"
      role="toolbar"
      *ngIf="isMobile"
    >
      <button
        type="button"
        data-toggle="prev"
        (click)="activeIndex = activeIndex - 1"
      >
        <img alt="See previous highlight" src="assets/icons/arrow-left.svg" />
      </button>
      <button
        type="button"
        data-toggle="next"
        (click)="activeIndex = activeIndex + 1"
      >
        <img alt="See previous highlight" src="assets/icons/arrow-right.svg" />
      </button>
    </div>
  </section>
</article>

<hr class="highlights--divider" />
