<div class="yo-theme">
  <mat-form-field class="search-form" appearance="fill">
    <mat-icon
      *ngIf="isMobileView"
      tabindex="0"
      class="back-button"
      matPreffix
      mat-icon-button
      (click)="handleBackClick()"
      (keyup.enter)="handleBackClick()"
    >
      keyboard_arrow_left
    </mat-icon>
    <!-- (keyup.enter) not triggered on some mobile devices that may have "Auto-Capitalization" keyboard settings enabled. 
      Workaround: Listening to (keyup.shift.enter). -->
    <input
      matInput
      type="text"
      [formControl]="searchControl"
      (keyup.enter)="onSearch()"
      (keyup.shift.enter)="onSearch()"
      [matAutocomplete]="auto"
      placeholder="Search"
      [ngClass]="{ 'mobile-input': isMobileView }"
    />
    <mat-icon
      matSuffix
      mat-icon-button
      *ngIf="!searchText.length"
    >
      search
    </mat-icon>
    <mat-icon
      tabindex="0"
      class="search-clear"
      matSuffix
      mat-icon-button
      *ngIf="searchText.length"
      (click)="clearSearch()"
      (keyup.enter)="clearSearch()"
    >
      close
    </mat-icon>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplayText">
      <ng-container *ngIf="!searchText.length">
        <mat-option *ngFor="let item of prevSearches" [value]="item">
          {{ item }}
        </mat-option>
      </ng-container>

      <ng-container *ngIf="showSuggestions">
        <mat-option
          *ngIf="prompt"
          [value]="{ text: prompt }"
          class="grey-text"
          (click)="onSearch()"
        >
          Did you mean <span class="prompt-text">{{ prompt }}</span> ?
        </mat-option>
        <mat-optgroup
          *ngFor="let category of suggestions"
          [label]="category.name"
          class="search-options"
        >
          <mat-option
            *ngFor="let item of category.items"
            [value]="item"
            (click)="onSearch()"
          >
            {{ item.text }}
          </mat-option>
        </mat-optgroup>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</div>
