import { Observable, Subscriber, TeardownLogic, from } from 'rxjs';
import {
  Storage,
  StorageReference,
  UploadMetadata,
} from '@angular/fire/storage';

import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

export type UploadType =
  | 'posters'
  | 'logos'
  | 'imports'
  | 'avatars'
  ;

export type UploadStatus =
  | 'STARTED'
  | 'PAUSED'
  | 'PROGRESS'
  | 'CANCELED'
  | 'FINISHED'
  | 'FAILED'
  ;
interface UploadTaskBase {
  status: UploadStatus;
  storageReference: StorageReference;
}
interface UploadTaskInit extends UploadTaskBase {
  status: 'STARTED';
}
interface UploadTaskPaused extends UploadTaskBase {
  status: 'PAUSED';
}

interface UploadTaskCanceled extends UploadTaskBase {
  status: 'CANCELED';
}
interface UploadTaskProgress extends UploadTaskBase {
  status: 'PROGRESS';
  progress: number;
  totalBytes: number;
  bytesTransferred: number;
}
interface UploadTaskFinished extends UploadTaskBase {
  status: 'FINISHED';
  url: string;
}
interface UploadTaskFailed extends UploadTaskBase {
  status: 'FAILED';
}

export type UploadTaskStatus =
  | UploadTaskInit
  | UploadTaskPaused
  | UploadTaskProgress
  | UploadTaskCanceled
  | UploadTaskFinished
  | UploadTaskFailed
  ;

// ####TODO: Change the upload
@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private basePath = '/uploads';

  constructor(
    private storage: Storage
  ) { }

  private sanitizeName(name: string): string {
    return name.toLowerCase().split(' ').join('-');
  }
  upload(file: File | Blob, name: string, path: string, metadata?: UploadMetadata): Observable<UploadTaskStatus> {
    return from(import('@angular/fire/storage')).pipe(
      switchMap(({ref, uploadBytesResumable, getDownloadURL}) => {
        const storageRef = ref(this.storage, [this.basePath, path, this.sanitizeName(name)].join('/'));
        return new Observable<UploadTaskStatus>(
          (subscriber: Subscriber<UploadTaskStatus>): TeardownLogic => {
            const task = uploadBytesResumable(storageRef, file, metadata);
            subscriber.next({status: 'STARTED', storageReference: storageRef});
            task.on('state_changed', {
              next: (snapshot) => {
                switch (snapshot.state) {
                  case 'running': return subscriber.next({
                    status: 'PROGRESS',
                    storageReference: storageRef,
                    progress: (snapshot.bytesTransferred * 100 / snapshot.totalBytes),
                    totalBytes: snapshot.totalBytes,
                    bytesTransferred: snapshot.bytesTransferred,
                  });
                  case 'paused': return subscriber.next({status: 'PAUSED', storageReference: storageRef});
                  case 'canceled': return subscriber.next({status: 'CANCELED', storageReference: storageRef});
                  case 'error': return subscriber.next({status: 'FAILED', storageReference: storageRef});
                }
              },
              error: (error) => {
                subscriber.error(error);
              },
              complete: () => {
                getDownloadURL(storageRef).then( result => {
                  subscriber.next({status: 'FINISHED', url: result, storageReference: storageRef});
                  subscriber.complete();
                }
                ).catch(error => subscriber.error(error));
              }
            });
            return () => null;
          }
        );
      })
    );
  }
  uploadBase64Uri(base64Uri: string, name: string, path: string, metadata?: UploadMetadata): Observable<UploadTaskStatus> {
    return from(fetch(base64Uri)).pipe(
      switchMap(res => from(res.blob())),
      switchMap(blob => this.upload(blob, name, path, metadata))
    );
  }
}
