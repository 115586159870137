import { Callable, declareCallable } from './declare-callables';
import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FieldPath,
  Firestore,
  FirestoreDataConverter,
  OrderByDirection,
  PartialWithFieldValue,
  Query,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  Transaction,
  WhereFilterOp,
  WithFieldValue,
} from '@angular/fire/firestore';
import {Observable, combineLatest, from, of} from 'rxjs';
import {map, mergeScan, switchMap, tap} from 'rxjs/operators';

import { Injector } from '@angular/core';

export type QueryWhereParameter = [FieldPath | string, WhereFilterOp, any];
export type IdOrRef<T> = string | DocumentReference<T>;
export { Callable };
interface PagedQueryState<T> {
  nextRef: DocumentSnapshot<T>;
  prevRef: DocumentSnapshot<T>;
  buffer: T[];
}

export interface PagingState {
  canLoadNext: boolean;
  canLoadPrevious: boolean;
}

export type PagedQueryCommand =
 | 'next'
 | 'previous'
 | 'reset'
 | {type: 'prune', ids: string[]}
 ;

/**
 * Transform an array of elements into multiple
 * arrays of "size" except for the last one that might contain
 * fewer elements.
 * @param a the array to chunk into "size" elements array
 * @param size the size of each chunk
 * @returns Returns an array of arrays with at most "size" elements
 */
export function chunk<T>(a: T[], size: number): T[][] {
  return a.reduce<T[][]>((acc: T[][], e, i) => {
    i % size ? acc[acc.length - 1].push(e) : acc.push([e]);
    return acc;
  }, []);
}
/**
 * Will remove duplicate objects (that have the same "key")
 * This implementation keeps the first encountered element.
 * @param l list of elements to filter
 * @param key the key used to filter duplicate
 * @returns the list without duplicates
 */
export function uniqBy<T>(l: T[], key: string): T[] {
  const m = new Map<string, T>();
  for (const el of l) {
    if (!m.has(el[key])) {
      m.set(el[key], el);
    }
  }
  return Array.from(m, ([_k, e]) => e);
}

export class Collection<T extends {docId?: string}> implements FirestoreDataConverter<T> {
  // The injected Firestore instance
  private fs: Firestore;
  // The collection path of this collection;
  private _cp: string;
  /**
   * Thin wrapper around a firebase collection providing basic functionality and
   * reducing boilerplate code.
   * This class is not intended to be instantiated directly but should
   * be used as a base class to implement services dealing with objects from
   * a firestore collection.
   * The Subclasses can choose to provide specific boxing/unboxing/validating data to/from
   * firebase by override `toFirestore` and `fromFirestore` functions or alternatively use
   * the default implementation.
   *
   * Note that the collection takes a generic type that extends {docId?: string}. Interface
   * can therefor expect the field to be present in all instances of the objects even if
   * the objects in the database themselves don't have that field.
   *
   * @param injector Injector to get dependencies at runtime
   * @param collectionPath The collection path for this collection
   * @param useDefaultConverter If the default simple converter should be used
   */
  constructor(
    private injector: Injector,
    collectionPath: string,
    private useDefaultConverter = false
  ) {
    this.fs = injector.get(Firestore);
    this._cp = collectionPath;
  }

  toFirestore(modelObject: WithFieldValue<T>): DocumentData;
  toFirestore(modelObject: PartialWithFieldValue<T>, options: SetOptions): DocumentData;
  public toFirestore(modelObject: any, options?: any): DocumentData {
    if (!this.useDefaultConverter) {
      throw new Error('Method not implemented.');
    }
    return this._defaultToFirestore(modelObject, options);
  }
  public fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): T {
    if (!this.useDefaultConverter) {
      throw new Error('Method not implemented.');
    }
    return this._defaultFromFirestore(snapshot, options);
  }

  protected _defaultToFirestore(modelObject: any, options?: any): DocumentData {
    const {docId, ...entity} = modelObject;
    return entity;
  }

  protected _defaultFromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): T {
    return snapshot.data() as T;
  }

  protected declareCallable<F extends Callable<any, any>>(functionName: string) {
    return declareCallable<F>(functionName, this.injector);
  }

  public get collectionPath() {
    return this._cp;
  }

  public async docRef(idOrRef?: IdOrRef<T>): Promise<DocumentReference<T>> {
    if ( typeof idOrRef !== 'string') {
      return Promise.resolve(idOrRef);
    }
    const [{doc}, collectionRef] = await Promise.all([import('@angular/fire/firestore'), this.collectionRef]);
    return doc<T>(collectionRef, idOrRef);
  }

  public get collectionRef() {
    return import('@angular/fire/firestore').then(({collection})=>collection(this.fs, this.collectionPath).withConverter<T>(this));
  }

  getSubCollection<ST extends Object>(docId: string, collectionName: string): Collection<ST> {
    return new Collection<ST>(this.injector, [this.collectionPath, docId, collectionName].join('/'), true);
  }

  /**
   * Inserts a document into the current collection
   *
   * @param document
   * @return Promise<DocumentReference>
   */
  async create(document: T): Promise<DocumentReference<T>> {
    // console.log(document);
    const [{addDoc}, collectionRef] = await Promise.all([import('@angular/fire/firestore'), this.collectionRef]);
    return addDoc<T>(collectionRef, document);
  }

  createAndListen(document: T): Observable<T> {
    return from(this.create(document)).pipe(
      switchMap(ref => this.getDocData(ref))
    );
  }

  /**
   * Updates a document for a given document id or ref with the provided data
   *
   * @param id
   * @param updateData
   * @return Promise
   */
  async update(id: IdOrRef<T>, updateData: {[key: string]: any}): Promise<void> {
    const {updateDoc} = await import('@angular/fire/firestore');
    return this.docRef(id).then(ref => updateDoc(ref , updateData));
  }

  /**
   * Returns a typed Object for given FirestoreDocument id which type corresponds to this class type parameter.
   * @param docId
   */
  getByDocId(docId: string | DocumentReference<T>): Observable<T> {
    if (!docId) {
      throw new Error('a valid Document ID should be specified');
    }
    return this.getDocData(docId);
  }

  transaction(transactionFun: (transaction: Transaction) => Promise<any> ): Promise<any> {
    return import('@angular/fire/firestore').then(({runTransaction}) => runTransaction(this.fs, transactionFun));
  }

  getDocData(docIdOrRef: string | DocumentReference<T>): Observable<T> {
    return combineLatest([
      from(import('@angular/fire/firestore').then(({docData}) => docData)),
      from(this.docRef(docIdOrRef))
    ]).pipe(switchMap(([docData, ref]) => docData<T>(ref, {idField: 'docId'})));
  }

  /**
   * Returns an array of typed objects which types corresponding to this class type parameter.
   * @deprecated Should be used only for small collections. You should always use a query for
   * collection having a lot of documents.
   */
  getAll(): Observable<T[]> {
    const collectionData$ = from(import('@angular/fire/firestore').then(({collectionData}) => collectionData));
    const collectionRef$ = from(this.collectionRef);
    console.warn('getAll is deprecated as it might load all the data from the collection');
    return combineLatest([collectionData$, collectionRef$])
    .pipe(
      switchMap( ([collectionData, collectionRef]) =>  collectionData(collectionRef, {idField: 'docId'}))
    );
  }

  protected async _queryBuilder(
    qWhere?: QueryWhereParameter | QueryWhereParameter[],
    qLimit?: number,
    qOrderBy?: {field: FieldPath | string, sort: OrderByDirection}[]
  ): Promise<Query<T>> {
    const [{query}, collectionRef] = await Promise.all([
      import('@angular/fire/firestore'),
      this.collectionRef
    ]);
    let q = query(collectionRef);

    if (qWhere !== undefined) {
      const {where} = await import('@angular/fire/firestore');
      const thereIsOnlyOneWhere = qWhere.length === 3 && typeof qWhere[1] === 'string';
      const wheres = (thereIsOnlyOneWhere ? [qWhere] : qWhere).map(
        ([f, op, val]) => {
          if (val === undefined) {
            throw new Error(`undefined was passed as a value for a where clause: in ${f} ${op} ${val}`);
          }
          return where(f, op, val);
        }
      );
      q = query(q, ...wheres);
    }

    if (qLimit !== undefined) {
      const {limit} = await import('@angular/fire/firestore');
      q = query(q, limit(qLimit));
    }

    if (qOrderBy !== undefined) {
      const {orderBy} = await import('@angular/fire/firestore');
      q = query(q, ... qOrderBy.map(ob => orderBy(ob.field, ob.sort)));
    }
    return q;
  }

  private _queryDocsToPagedQueryState(direction: PagedQueryCommand, state: PagedQueryState<T>) {
    switch (direction) {
      case 'reset': return ({docs, empty}) => {
        return (empty
          ? { nextRef: undefined, prevRef: undefined, buffer: []}
          : { nextRef: docs[docs.length - 1], prevRef: docs[0], buffer: docs.map( d => ({...d.data(), docId: d.id})) }
        );
      };
      case 'next': return ({docs, empty}) =>
        ({
          nextRef: empty ? undefined : docs[docs.length - 1],
          prevRef: state.prevRef,
          buffer: [...state.buffer, ...docs.map( d => ({...d.data(), docId: d.id}))]
        });
      case 'previous' : return ({docs, empty}) =>
        ({
          nextRef: state.nextRef,
          prevRef: empty ? undefined : docs[0],
          buffer: [...docs.map( d => ({...d.data(), docId: d.id})), ...state.buffer]
        });
    }
  }

  /**
   * Makes a paged query on a collection. Saving a buffer of the currently loaded
   * documents and providing a way to load the next or previous page.
   *
   * Unlike {@link Collection#query}, the observable doesn't react to updates
   * of the documents returned by the query. It only reacts to new commands.
   *
   * @param qLimit Limit of documents to load
   * @param loadNext$ An observable that controls the buffer and when to load the next page
   * @param qWhere The where clauses to use for the query
   * @param qOrderBy Ordering of the query
   * @returns Returns an observable with the buffered objects and the current state of the paged query.
   */
  public pagedQuery(
    qLimit: number,
    loadNext$: Observable<PagedQueryCommand>,
    qWhere?: QueryWhereParameter | QueryWhereParameter[],
    qOrderBy?: {field: FieldPath | string, sort: OrderByDirection}[],
  ): Observable<{data: T[], paging: PagingState}> {

    return combineLatest([
      from(this._queryBuilder(qWhere, qLimit, qOrderBy)),
      from(import('@angular/fire/firestore'))
    ]).pipe(
      switchMap(([q, {endBefore, startAfter, query, getDocs}]) => loadNext$.pipe(
          // We need to map queries from getDocs() to a state that contains
          // the latest cursor data and a buffer containing data.
          // Note that getDocs returns a promise.
          mergeScan<PagedQueryCommand, PagedQueryState<T>>((state, command) => {
            if (state === undefined && command !== 'reset') {
              throw new Error('Invalid paging direction requested. The query should be initialized by calling "reset" first');
            }
            if ((command === 'next' && !state.nextRef) || (command === 'previous' && !state.prevRef)) {
              // No more data to load, return the same buffer
              return of(state);
            }
            if (state === undefined || command === 'reset') {
              // Initial load
              return from(getDocs(query(q))).pipe(
                map(this._queryDocsToPagedQueryState(command, state))
              );
            }
            if (typeof command === 'object' && command.type === 'prune') {
              // Pruning deleted ids from the cache
              return of({...state, buffer: state.buffer.filter(s => !command.ids.includes(s.docId))});
            }
            const newQ = query(q, command === 'next' ? startAfter(state.nextRef) : endBefore(state.prevRef));
            // Get the next page
            return from(getDocs(newQ)).pipe(
              map(this._queryDocsToPagedQueryState(command, state))
            );
          }, undefined, 1),
          map(state =>  ({data: state.buffer, paging: { canLoadNext: !!state.nextRef, canLoadPrevious: !!state.prevRef}}))
        )
      )
    );
  }

  public query(
    qWhere?: QueryWhereParameter | QueryWhereParameter[],
    qLimit?: number,
    qOrderBy?: {field: FieldPath | string, sort: OrderByDirection}[]
  ): Observable<T[]> {
    return combineLatest([
      from(import('@angular/fire/firestore').then(({collectionData}) => collectionData)),
      from(this._queryBuilder(qWhere, qLimit, qOrderBy))
    ]).pipe(
      switchMap( ([collectionData, q]) => collectionData(q, {idField: 'docId'}))
    );
  }

  /**
   * Returns a list of documents given their Ids.
   * More efficient than loading every document separately as it batches the queries using the 'in' operator.
   * @param docIds The list of documents to load
   * @returns An observable containing the list of documents provided
   */
  getByDocIds(docIds: string[]): Observable<T[]> {
    const slices: string[][] = chunk(docIds, 10);
    return from(import('@angular/fire/firestore')).pipe(
      switchMap(({documentId}) =>
        combineLatest(
          slices.map((slice) =>
            this.query([documentId(), 'in', slice])
          )
        ).pipe(
          map((docSlices) =>
            docSlices.reduce(
              (acc, docs) => {
                Array.prototype.push.apply(acc, docs);
                return acc;
              },
              []
            )
          )
        )
      )
    );
  }
}
