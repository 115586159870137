<yo-grid *ngIf="isSection; else raw"
  [elements]="elements$"
  [appearance]="'stream'"
  [useHeader]="showHeader"
  (refreshTriger)="handleFinishedEvent($event)"></yo-grid>
<ng-template #raw>
  <yo-grid2
    [elements]="elements$"
    [cardConfig]="cardConfiguration"
    [multiline]="true"
    (refreshTriger)="handleFinishedEvent($event)"></yo-grid2>
</ng-template>