<section class="tile" fxFlex="1 0 auto">
  <div class="card" fxLayout="column">
    <div class="card-header" fxFlex="1 0 auto" [style.backgroundImage]="'url(' + livestreamGroup?.imageUrls.main + ')'">
      <div class="card-status" [style.backgroundColor]="livestreamStatus.bgColor"><div>{{ livestreamStatus.status }}</div><mat-icon>{{ livestreamStatus.icon }}</mat-icon></div>
      <div class="shadow"></div>
      <div class="card-title">
        <h3 class="title">{{ livestreamGroup.name }}</h3>
        <p class="card-description">{{ livestreamGroup.description }}</p>
      </div>
    </div>
    <div class="card-bottom" fxLayout="row">
      <div class="details" fxLayout="column" fxFlex="1 0 auto">
        <div class="date">{{livestreamGroup.startTime.toDate() | date: 'dd MMM yyyy, HH:mm'}}</div>
        <div class="price" fxLayout="row" *ngIf="livestreamGroup.ownerDocId !== authenticatedUserDocId">
          <div class="status status-paid" fxLayout="row" fxFlex="1 0 auto">
            <mat-icon>check_circle</mat-icon><div class="status-label">VIEWER</div>
          </div>
        </div>
        <div class="price" fxLayout="row" *ngIf="livestreamGroup.ownerDocId === authenticatedUserDocId">
          <div class="status status-owner" fxLayout="row" fxFlex="1 0 auto">
            <mat-icon>check_circle</mat-icon><div class="status-label">CREATOR</div>
          </div>
        </div>
      </div>
      <div class="actions" fxLayout="column" fxLayoutAlign="center" *ngIf="livestreamStatus.status !== 'Finished'">
        <div class="action-button"fxLayout="row" fxLayoutAlign="center">
          <button yoFlatButton color="black" [routerLink]="'/arena/home/livestream-group/' + livestreamGroup.docId">VIEW ALL</button>
        </div>
      </div>
    </div>
  </div>
</section>
