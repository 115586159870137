import { Breakpoints } from "@angular/cdk/layout";

export function sortArrayOfObjects(arr, key, order: "asc" | "desc" = "asc") {
  arr.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });

  return order === "asc" ? arr : arr.reverse();
}

export const viewports = {
  [Breakpoints.XSmall]: {
    cols: {
      header: 3,
      main: 10,
    },
    gutterSize: "1",
  },
  [Breakpoints.Small]: {
    cols: {
      header: 1,
      main: 2,
    },
    gutterSize: "1",
  },
  [Breakpoints.Medium]: {
    cols: {
      header: 2,
      main: 4,
    },
    gutterSize: "1",
  },
  [Breakpoints.Large]: {
    cols: {
      header: 3,
      main: 4,
    },
    gutterSize: "1",
  },
  [Breakpoints.XLarge]: {
    cols: {
      header: 5,
      main: 6,
    },
    gutterSize: "1",
  },
};

export const defaultBreakpoint = viewports[Breakpoints.Large];
