<div class="table-container table">
    <nav mat-tab-nav-bar class="table-nav">
        <div mat-tab-link [active]="activeTab == 'past'" (click)="onTabNavSelect(1, 'past')">
            <mat-icon>history</mat-icon> Replays
        </div>
        <div mat-tab-link [active]="activeTab == 'future'" (click)="onTabNavSelect(0, 'future')">
            <mat-icon>fast_forward</mat-icon> Upcoming
        </div>
        <div class="pull-right yo-theme">
            <mat-form-field appearance="outlined">
                <mat-icon color="primary" matPrefix>filter_alt</mat-icon>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
    </nav>
    <div *ngIf="datasourceLoading" class="data-loading">
        <yo-loading></yo-loading>
    </div>
    <table *ngIf="streamDataSource.filteredData.length > 0 && !datasourceLoading" mat-table
      [dataSource]="streamDataSource" multiTemplateDataRows>

        <!-- Time column -->
        <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef> Date & Time </th>
            <td mat-cell *matCellDef="let stream"> {{stream.startTime}} </td>
        </ng-container>

        <!-- Name column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let stream"> {{stream.name}} </td>
        </ng-container>

        <!-- Team column -->
        <ng-container matColumnDef="team-name">
            <th mat-header-cell *matHeaderCellDef> Team </th>
            <td mat-cell *matCellDef="let stream"> {{stream.teamName}} </td>
        </ng-container>

        <!-- Duration column -->
        <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef> Duration </th>
            <td mat-cell *matCellDef="let stream"> {{stream.duration}} </td>
        </ng-container>

        <!-- Description column -->
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let stream"> {{stream.description}} </td>
        </ng-container>

        <!-- Price column -->
        <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Price </th>
            <td mat-cell *matCellDef="let stream"> {{stream.price}} </td>
        </ng-container>

        <tr mat-header-row class="row-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row class="row clickable" *matRowDef="let stream; columns: displayedColumns;"
            [class.selected]="selectedStream?.docId === stream.docId"
            (click)="selectedStream = selectedStream?.docId === stream.docId ? null : onStreamSelect(stream)">
        </tr>
        <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr> -->
    </table>
    <div class="table-empty mat-typography"
      *ngIf="streamDataSource.filteredData.length==0 && !datasourceLoading">
        <h1>Ooops! It's empty</h1>
        <h3>No streams matching the selected filter were found.</h3>
    </div>
    <mat-paginator #streamsPaginator [class.hidden]="streamDataSource.filteredData.length==0 && !datasourceLoading"
      class="paginator"
    [length]="streamsLength" [pageSize]="15">
    </mat-paginator>
</div>
