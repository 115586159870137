import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ClientService } from "src/app/core/utility/client.service";
import { defaultBreakpoint, viewports } from "src/app/core/utility/utils";
import { SearchResultTabDirective } from "../search-results/search-result-tab";

@Component({
  selector: "yo-channel-results",
  templateUrl: "./channels.component.html",
})
export class ChannelResultsComponent extends SearchResultTabDirective {
  @Input() clubs;
  @Input() canLoadMore = false;
  @Input() isLoading = false;

  breakpoint = defaultBreakpoint;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(clientService: ClientService) {
    super(clientService);
    this.setBreakpoint();
  }

  setBreakpoint() {
    this.clientService.breakpoint.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (bpStateKey) => {
        this.breakpoint = bpStateKey
          ? viewports[bpStateKey]
          : defaultBreakpoint;
      },
      (err) =>
        console.error("An error occured while determining breakpoint", err)
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
