import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { EventCardComponentInput, EventCardConfig } from "../../cards/event/event-card.component";
import { Observable, Subject } from "rxjs";
import { catchError, map, takeUntil, tap } from "rxjs/operators";

import { ClientService } from "src/app/core/utility/client.service";

@Component({
  selector: 'yo-grid2',
  templateUrl: './grid2.component.html',
  styleUrls: ['./grid2.component.scss']
})
export class Grid2Component implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  @Input("elements") elements$: Observable<EventCardComponentInput[]>;
  @Input('cardConfig') cardConfig: Partial<EventCardConfig>;
  @Input('multiline') multiline: boolean = true;
  @Output("refreshTrigger") refreshTrigger: EventEmitter<string> = new EventEmitter();
  isMobileView: boolean = false;

  breakpoint = {
    cols: {
      header: 3,
      main: 5
    },
    gutterSize: "1"
  };
  viewports = {
    [Breakpoints.XSmall]: {
      cols: {
        header: 3,
        main: 10
      },
      gutterSize: "1"
    },
    [Breakpoints.Small]: {
      cols: {
        header: 1,
        main: 2
      },
      gutterSize: "1"
    },
    [Breakpoints.Medium]: {
      cols: {
        header: 2,
        main: 4
      },
      gutterSize: "1"
    },
    [Breakpoints.Large]: {
      cols: {
        header: 3,
        main: 4
      },
      gutterSize: "1"
    },
    [Breakpoints.XLarge]: {
      cols: {
        header: 5,
        main: 6
      },
      gutterSize: "1"
    },
    // 2440: {
    //   cols: {
    //     header: 4,
    //     main: 5
    //   },
    //   gutterSize: "1.5"
    // }
  };

  elements: EventCardComponentInput[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private clientService: ClientService,
    private breakpointObserver: BreakpointObserver
  ) { }


  ngOnInit(): void {
    this.isMobileView = this.clientService.isMobileDevice();
    this.determineBreakpoint();

    // Load streams
    this.elements$
      .pipe(
        // tap(el => console.log("Grid2", el)),
        // takeUntil(this.ngUnsubscribe)
      ).subscribe(
        elements => this.handleRows(elements),
        err => console.error(err)
      );
  }

  trackElement(index: number, element: Object & { docId: string }): string | null {
    return element.docId || null;
  }

  determineBreakpoint() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).pipe(
      map(bpState => {
        const stateEntries = Object.entries(bpState.breakpoints);
        const index = stateEntries.findIndex(([key, value]) => value == true);
        return stateEntries[index][0];
      }),
      catchError((err, bpStateKey) => {
        console.error("An error occured while determining breakpoint", err);
        return bpStateKey;
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      bpStateKey => {

        switch (bpStateKey) {
          case Breakpoints.XSmall:
            // case Breakpoints.Small:
            this.isMobileView = true;
            break;
          default:
        }

        this.breakpoint = bpStateKey ? this.viewports[bpStateKey] : this.viewports[Breakpoints.Large];

        // if (!this.useHeader) {
        this.breakpoint.cols.header = 0;
        // }

        console.log([bpStateKey, this.breakpoint]);
        this.handleRows(this.elements);
        this.cdr.detectChanges();
      },
      err => console.error("An error occured while determining breakpoint", err)
    );
  }

  handleRows(elements: any[]) {
    let headerCols = this.breakpoint.cols.header;
    let mainCols = this.breakpoint.cols.main;
    let maxRows = mainCols * 2;

    elements.slice(0, headerCols);
    const remainingStreams = elements.length - headerCols;
    // if (remainingStreams < maxRows) { maxRows = mainCols; }
    // if (remainingStreams < maxRows) { maxRows = 0; }

    this.elements = elements.slice(headerCols, headerCols + maxRows);
  }

  handleElements(elements: EventCardComponentInput[]) {
    this.elements = elements;
    this.handleRows(elements);
  }

  handleFinishedEvent(docId: string) {
    this.elements = this.elements.filter(s => s.id !== docId);
    this.refreshTrigger.next(docId);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}