import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, interval, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { Output } from '@angular/core';

interface CountDown {
  s: number;
  m: number;
  h: number;
  d: number;
  f: number; // first significant place 0 - 3
}

/**
 * Constants for the desired target slice.
 * The SLICE split is the number of miliseconds a slice takes.
*/

const SLICE = 1000;
const SECONDS = Math.floor(1000 / SLICE);
const MINUTES = 60 * SECONDS;
const HOURS = MINUTES * 60;
const DAYS = HOURS * 24;

export type CountDownResolution =
  | 'hour'
  | 'minute'
  | 'second'
  ;

const RESOLUTION_INTERVAL = {
  'hour' : HOURS,
  'minute' : MINUTES,
  'second': SECONDS,
};

const RESOLUTION_PLACE = {
  'hour': 1,
  'minute': 2,
  'second': 3,
};

@Component({
  selector: "yo-countdown",
  templateUrl: "./countdown.component.html",
  styleUrls: ["./countdown.component.scss"]
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Input() targetDate: Date;
  @Input() placesToShow: 1 | 2 | 3 | 4 = 4;
  @Input() resolution: CountDownResolution = 'second';
  @Input() inline: false;
  @Input() header: string;
  @Input() event = false;
  @Output() countdownFinished: EventEmitter<boolean>;

  private unsubscribe$ = new Subject();

  private targetDateUnits: number;
  private timer$: Observable<CountDown>;
  showCountDown: boolean;
  d: string;
  showDays: boolean;
  h: string;
  showHours: boolean;
  m: string;
  showMinutes: boolean;
  s: string;
  showSeconds: boolean;
  f: number;

  constructor() {
    this.countdownFinished = new EventEmitter<boolean>();
  }


  ngOnInit() {
    this.targetDateUnits = Math.floor(this.targetDate.getTime() / SLICE);
    const checkInterval = RESOLUTION_INTERVAL[this.resolution] * SLICE;
    this.timer$ = timer(0, checkInterval).pipe(
      takeUntil(this.unsubscribe$),
      map((_seq: number) => {
        const now = Math.floor(new Date().getTime() / SLICE);
        let firstSignificantPlace = -1;
        let r = Math.max(0, this.targetDateUnits - now);
        if (r === 0) {
          return null;
        }

        const d = Math.floor(r / DAYS);
        firstSignificantPlace = d > 0 ? 0 : -1;
        r -= d * DAYS;

        const h = Math.floor(r / HOURS);
        firstSignificantPlace = firstSignificantPlace > -1 ? firstSignificantPlace : (h > 0 ? 1 : -1);
        r -= h * HOURS;

        const m = Math.floor(r / MINUTES);
        firstSignificantPlace =
          firstSignificantPlace > -1 ? firstSignificantPlace : (m > 0 ? 2 : -1);
        r -= m * MINUTES;

        const s = Math.floor(r / SECONDS);
        firstSignificantPlace =
          firstSignificantPlace > -1 ? firstSignificantPlace : 3;
        return { s, m, h, d, f: firstSignificantPlace};
      })
    );

    this.timer$.subscribe(cd => {
      if (!cd) {
        this.countdownFinished.emit(true);
        return this.showCountDown = false;
      }
      this.s = this.zeroPad(cd.s);
      this.m = this.zeroPad(cd.m);
      this.h = this.zeroPad(cd.h);
      this.d = cd.d.toString();
      this.f = cd.f;

      const lastOffset = Math.min(RESOLUTION_PLACE[this.resolution], this.f + this.placesToShow - 1);
      this.showDays = cd.f <= 0;
      this.showHours = cd.f <= 1 && lastOffset >= 1;
      this.showMinutes = cd.f <= 2 && lastOffset >= 2;
      this.showSeconds = cd.f <= 3 && lastOffset >= 3;
      this.showCountDown = true;
    });
  }
  zeroPad(n: number): string {
    return `${n < 10 ? '0' : ''}${n}`;
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
