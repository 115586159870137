import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { combineLatest, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { SearchService } from "../../core/model/services/search.service";
import { TermFilter, TextSearchOptions } from "../search.model";
import { SearchResult } from "./search-result";

@Component({
  selector: "yo-search-results",
  templateUrl: "./search-results.component.html",
  styleUrls: ["./search-results.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SearchResultsComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  searchText = "";
  encodedSearchText = "";
  resultsLoaded = false;

  showTabMap = {
    club: false,
    "livestream-group": false,
    livestream: false,
  };

  channelsTab: SearchResult;
  playlistsTab: SearchResult;
  streamsTab: SearchResult;

  get isResultPresent() {
    return (
      this.showTabMap.club ||
      this.showTabMap.livestream ||
      this.showTabMap["livestream-group"]
    );
  }

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.route.queryParams
      .pipe(
        switchMap((params) => {
          this.encodedSearchText = params.searchText
          this.searchText = decodeURIComponent(params.searchText);
          this.resetData();
          return combineLatest([
            this.searchService.channelsResults(
              this.encodedSearchText,
              this.channelsTab.body
            ),
            this.searchService.playlistsResults(
              this.encodedSearchText,
              this.playlistsTab.body
            ),
            this.searchService.streamsResults(
              this.encodedSearchText,
              this.streamsTab.body
            ),
          ]);
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((res) => {
        this.setInitialResults(res);
      });
  }

  get streamSearchOptions() {
    // Search result displays only non-archived streams
    const streamFilters = [];
    const nonArchived: TermFilter = {
      term: {
        archived: false,
      },
    };
    streamFilters.push(nonArchived);
    return {
      filters: streamFilters,
    } as TextSearchOptions;
  }

  resetData() {
    this.showTabMap = {
      club: false,
      "livestream-group": false,
      livestream: false,
    };
    this.resultsLoaded = false;
    this.channelsTab = new SearchResult();
    this.playlistsTab = new SearchResult();
    this.streamsTab = new SearchResult(this.streamSearchOptions);
  }

  setInitialResults([channels, playlists, streams]) {
    this.channelsTab.totalResults = channels.totalResults;
    this.channelsTab.addUniqueDocs(channels.docs);

    this.playlistsTab.totalResults = playlists.totalResults;
    this.playlistsTab.addUniqueDocs(playlists.docs);

    this.streamsTab.totalResults = streams.totalResults;
    this.streamsTab.addUniqueDocs(streams.docs);

    this.showTabMap = {
      club: this.channelsTab.totalResults > 0,
      "livestream-group": this.playlistsTab.totalResults > 0,
      livestream: this.streamsTab.totalResults > 0,
    };

    this.resultsLoaded = true;
  }

  loadMoreChannels() {
    this.channelsTab.isLoading = true;
    this.channelsTab.updateLoadDocsFrom();
    this.searchService
      .channelsResults(this.encodedSearchText, this.channelsTab.body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((clubs) => {
        this.channelsTab.addUniqueDocs(clubs.docs);
        this.channelsTab.isLoading = false;
      });
  }

  loadMorePlaylists() {
    this.playlistsTab.isLoading = true;
    this.playlistsTab.updateLoadDocsFrom();
    this.searchService
      .playlistsResults(this.encodedSearchText, this.playlistsTab.body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((playlists) => {
        this.playlistsTab.addUniqueDocs(playlists.docs);
        this.playlistsTab.isLoading = false;
      });
  }

  loadMoreStreams() {
    this.streamsTab.isLoading = true;
    this.streamsTab.updateLoadDocsFrom();
    this.searchService
      .streamsResults(this.encodedSearchText, this.streamsTab.body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((streams) => {
        this.streamsTab.addUniqueDocs(streams.docs);
        this.streamsTab.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
