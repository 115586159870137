import { Component, OnInit } from '@angular/core';
import {OperationsService} from '../../core/model/services/operations.service';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {delay, tap} from 'rxjs/operators';

@Component({
  selector: 'yo-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.css']
})
export class OperationComponent implements OnInit {

  callableResult$: Observable<any>;

  constructor(
    private operationService: OperationsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.callableResult$ = this.operationService.handleNextStep(this.route.snapshot.params['operationDocId']).pipe(
      tap(val => {
        if (val.err) {
          console.log(val);
        }
      }),
      delay(4000)
    );
  }

}
