<h2>Welcome to the Joymo Universe!</h2>
<p>{{inviteMessage}}</p>
<h2>Terms and conditions</h2>

<h5>1. ACCEPTANCE THE USE OF LOREM IPSUM TERMS AND CONDITIONS</h5>
<p>Your access to and use of Lorem Ipsum (the app) is subject exclusively to these Terms and Conditions. You will not
  use
  the app for any purpose that is unlawful or prohibited by these Terms and Conditions. By using the app you are fully
  accepting the terms, conditions and disclaimers contained in this notice. If you do not accept these Terms and
  Conditions you must immediately stop using the app.</p>


<h5>2. CREDIT CARD DETAILS</h5>
<p>All Lorem Ipsum purchases are managed by the individual App Stores (Apple, Google Windows) and Lorem Ipsum will never
  store your credit card information or make it available to any third parties. Any purchasing information provided will
  be provided directly from you to the respective App Store and you will be subject to their credit card policies.
</p>

<h5>3. LEGAL ADVICE</h5>
<p>The contents of Lorem Ipsum app do not constitute advice and should not be relied upon in making or refraining from
  making, any decision.
  All material contained on Lorem Ipsum is provided without any or warranty of any kind. You use the material on Lorem
  Ipsum at your own discretion
</p>

<h5>4. CHANGE OF USE</h5>
<p>Lorem Ipsum reserves the right to:</p>
<p>4.1 change or remove (temporarily or permanently) the app or any part of it without notice and you confirm that Lorem
  Ipsum shall not be liable to you for any such change or removal and.</p>
<p>4.2 change these Terms and Conditions at any time, and your continued use of the app following any changes shall be
  deemed to be your acceptance of such change.</p>

<h5>8. DISCLAIMERS AND LIMITATION OF LIABILITY</h5>
<p>8.1 The app is provided on an AS IS and AS AVAILABLE basis without any representation or endorsement made and without
  warranty of any kind whether express or implied, including but not limited to the implied warranties of satisfactory
  quality, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.</p>
<p>8.2 To the extent permitted by law, Lorem Ipsum will not be liable for any indirect or consequential loss or damage
  whatever (including without limitation loss of business, opportunity, data, profits) arising out of or in connection
  with the use of the app.</p>
<p>8.3 Lorem Ipsum makes no warranty that the functionality of the app will be uninterrupted or error free, that defects
  will be corrected or that the app or the server that makes it available are free of viruses or anything else which may
  be harmful or destructive.</p>
<p>8.4 Nothing in these Terms and Conditions shall be construed so as to exclude or limit the liability of Lorem Ipsum
  for
  death or personal injury as a result of the negligence of Lorem Ipsum or that of its employees or agents.</p>

<h5>9. INDEMNITY</h5>
<p>You agree to indemnify and hold Lorem Ipsum and its employees and agents harmless from and against all liabilities,
  legal fees, damages, losses, costs and other expenses in relation to any claims or actions brought against Lorem Ipsum
  arising out of any breach by you of these Terms and Conditions or other liabilities arising out of your use of this
  app.</p>


<h5>10. SEVERANCE</h5>
<p>If any of these Terms and Conditions should be determined to be invalid, illegal or unenforceable for any reason by
  any
  court of competent jurisdiction then such Term or Condition shall be severed and the remaining Terms and Conditions
  shall survive and remain in full force and effect and continue to be binding and enforceable.</p>