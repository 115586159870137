<!--<h2>TERMS OF SERVICE FOR CONTENT CREATORS</h2>-->
<p>
  Before you can continue, you must agree to Joymo's <b>Terms of Service</b> and <b>Data Processing agreements</b> found below.
  Please read the agreements carefully. To confirm your understanding and acceptance of the <b>agreements</b>, click “Accept Invite.”
</p>

<p><a mat-stroked-button href="https://www.joymo.tv/terms-of-service-content-creator" target="_blank" style='display: block;'>
  Read the Terms of Service For Content Creators
</a></p>

<p><a mat-stroked-button href="https://www.joymo.tv/data-processing-agreement" target="_blank" style='display: block;'>
  Read the Data Processing Agreement
</a></p>