import { Component, Input } from "@angular/core";
import { ClientService } from "src/app/core/utility/client.service";
import {
  EventCardConfig,
} from "src/app/yoimo-ui/cards/event/event-card.component";
import { SearchResultTabDirective } from "../search-results/search-result-tab";

@Component({
  selector: "yo-stream-results",
  templateUrl: "./streams.component.html",
})
export class StreamResultsComponent extends SearchResultTabDirective {
  @Input() streams = [];
  @Input() canLoadMore = false;
  @Input() isLoading = false;

  streamCardConfig: Partial<EventCardConfig> = {
    appearance: "stream",
    showCountdown: true,
    trackEventStatus: true,
  };

  constructor(clientService: ClientService) {
    super(clientService);
  }

  handleFinishedEvent(eventId: string) {
    console.group("Stream finished");
    console.log(eventId);
    console.groupEnd();
  }
}
