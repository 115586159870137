import { Injectable, Injector } from '@angular/core';
import { SubscriptionPlan, SubscriptionPlanBillingOption } from '../interfaces/subscription-plan';

import { Collection } from '../collection';
import { formatCurrency } from './subscription-plan-currency-mappings';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlanService extends Collection<SubscriptionPlan>{

  constructor(
    injector: Injector,
  ) {
    super(injector, 'subscriptionPlans', true);
  }


  getMonthlyPrice(plan: SubscriptionPlan, options: {currency: string, periodicity: string}, afterTax = false, countryCode: string = 'NO') {
    let tax = 0;
    const option = this.getBillingOption(plan, options.currency, options.periodicity);

    if (afterTax) {
      tax = this.getTaxIfApplicable(plan, options, countryCode, false) as number;
    }

    const total = option.price + tax;

    return formatCurrency(option.periodicity === 'yearly' ? total / 12 : total, option.currency);
  }

  getBillingOption(plan: SubscriptionPlan, currency: string, periodicity: string): SubscriptionPlanBillingOption {
    return plan.billingOptions.find(
      (option) =>
        option.id ===
        [plan.name, currency, periodicity].join('_')
    );
  }

  getTaxIfApplicable(plan: SubscriptionPlan, options: {currency: string, periodicity: string}, countryCode: string, format: boolean = true): undefined | number | string {
    const taxRates = plan.taxRates[countryCode];
    if (taxRates) {
      const option = this.getBillingOption(plan, options.currency, options.periodicity);
      const tax = taxRates.reduce((acc, { percentage }) => acc + (option.price * percentage / 100), 0);
      return format ? formatCurrency(tax, option.currency) : tax;
    } else {
      return undefined;
    }
  }
}
