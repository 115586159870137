import { Component, Input, OnInit } from '@angular/core';

import { ClubPublic } from 'src/app/core/model/interfaces/club-public';

@Component({
  selector: 'yo-channel-card',
  templateUrl: './channel-card.component.html',
  styleUrls: ['./channel-card.component.scss']
})
export class ChannelCardComponent implements OnInit {

  @Input("size") size: "small" | "medium" | "large" = "medium";
  @Input("club") club: ClubPublic;

  routerLink: string[] = [];

  constructor() { }

  ngOnInit() {
    this.routerLink = [
      "/browse", "clubs", this.club.docId
    ];
  }

}
