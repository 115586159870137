import { BehaviorSubject, Observable, combineLatest, from, merge, of } from "rxjs";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewEncapsulation } from "@angular/core";

import { FormControl } from "@angular/forms";
import { TeamPublic } from "src/app/core/model/interfaces/teamPublic";
import { tap } from "rxjs/operators";

@Component({
  selector: 'yo-subchannel-selector',
  templateUrl: './subchannel-selector.component.html',
  styleUrls: ['./subchannel-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubchannelSelectorComponent implements OnInit, OnChanges {

  @Input('subchannels') subchannels: TeamPublic[];
  @Input('selectedSubchannel') selectedSubchannel: string;
  @Output('onSubchannelSelected') onSubchannelSelected: EventEmitter<string> = new EventEmitter();

  DEFAULT_VALUE = -1; // Hack for material to display the default value of the select list. see https://github.com/angular/components/issues/6466
  subchannelFormControl: FormControl = new FormControl('');
  selectedSubchannelSource: BehaviorSubject<string | number> = new BehaviorSubject(this.DEFAULT_VALUE);
  selectedSubchannel$: Observable<string | number> = this.selectedSubchannelSource.asObservable();

  ngOnInit(): void {
    this.selectedSubchannel$
      .subscribe(
        value => this.subchannelFormControl.setValue(value || this.DEFAULT_VALUE, { emitEvent: false })
      );
    this.subchannelFormControl.valueChanges.subscribe(scId => this.subchannelSelected(scId));
  }

  ngOnChanges(changes: SimpleChanges & { selectedSubchannel: SimpleChange, subchannels: SimpleChange }): void {
    if (changes.selectedSubchannel) {
      this.selectedSubchannelSource.next(changes.selectedSubchannel.currentValue);
    }
  }

  subchannelSelected(subchannelDocId) {
    this.onSubchannelSelected.emit(subchannelDocId === this.DEFAULT_VALUE ? undefined : subchannelDocId);
  }

}
