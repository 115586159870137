import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventCardComponentInput, EventCardConfig } from 'src/app/yoimo-ui/cards/event/event-card.component';
import { map, subscribeOn, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Livestream } from 'src/app/core/model/interfaces/livestream';
import { PaymentsService } from 'src/app/core/model/services/payments.service';

@Component({
  selector: 'yo-streams',
  templateUrl: './streams.component.html',
  styleUrls: ['./streams.component.scss']
})
export class StreamsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  @Input("streams") streams$: Observable<Livestream[]>;
  @Input("isMobileView") isMobileView: boolean = false;
  @Input("showHeader") showHeader = true;
  @Input("showLogos") showLogos = true;
  @Input('isSection') isSection = true;
  @Input('customFormatFields') customFormatFields: {
    title?: string,
    subtitle?: string
  } = {};

  refreshTrigger: BehaviorSubject<boolean> = new BehaviorSubject(true);
  elements$: Observable<EventCardComponentInput[]>;

  cardConfiguration: Partial<EventCardConfig> = {
    appearance: 'stream',
    showCountdown: false,
    trackEventStatus: false,
  };

  constructor(
    private paymentService: PaymentsService,
  ) { }

  ngOnInit() {
    this.elements$ = this.refreshTrigger.pipe(
      switchMap(_ => this.streams$),
      // tap(streams => console.log("yo-streams", streams)),
      map(streams => streams.map(s => this.formatStream(s))),
      // takeUntil(this.ngUnsubscribe)
    );

    this.cardConfiguration.showLogo = this.showLogos;

    // this.elements$.subscribe();
  }

  formatStream(stream: Livestream): EventCardComponentInput {
    let formattedStream = this.paymentService.fromStreamToEventCardInput(stream);
    let customFields = Object.entries(this.customFormatFields);
    customFields.forEach(([field, substitute]) => {
      formattedStream = { ...formattedStream, [field]: stream[substitute] }
    });
    return formattedStream;
  }

  handleFinishedEvent(docId: string) {
    // this.streams = this.streams.filter(s => s.docId !== docId);
    this.refreshTrigger.next(true);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
