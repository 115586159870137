import { Theme } from "./theme";

export const darkTheme: Theme = {
  name: "dark",
  properties: {
    background1: "#141414",
    onBackground1: "#fff",
    background2: "#1b1b1b",
    onBackground2: "#fff",
    background3: "#202020",
    onBackground3: "#fff",
    background4: "#1b222b",
    onBackground4: "#fff",
    background5: "#2a333a",
    onBackground5: "#B0B0B0",
    background6: "#4c5a67",
    onBackground6: "#fff",
    contrastBackground: "#fff",
    onContrastBackground: "#111",
    first: "#d6f551",
    onFirst: "#111",
    second: "#52f3e3",
    onSecond: "#111",
    third: "#ff00f8",
    onThird: "#fff",
    fourth: "#ff8244",
    onFourth: "#fff",
    fifth: "#ff4d4d",
    onFifth: "#fff",
  },
};
