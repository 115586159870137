import { EventCardComponentInput } from "src/app/yoimo-ui/cards/event/event-card.component";
import { Club, TextSearchBody, TextSearchOptions } from "../search.model";

export class SearchResult {
  pageSize = 12;
  totalResults = 0;
  body: TextSearchBody = { options: {} };
  isLoading = false;
  loadDocsFrom = 0;
  data: Club[] | EventCardComponentInput[] = [];
  docIdSet = new Set<string>();

  get defaultOptions(): TextSearchOptions {
    return {
      paginate: { from: 0, size: this.pageSize },
    };
  }

  constructor(additionalOptions?: TextSearchOptions) {
    this.body.options = this.defaultOptions;

    if (additionalOptions) {
      this.setSearchOptions(additionalOptions);
    }
  }

  setSearchOptions(options: TextSearchOptions) {
    Object.keys(options).map((key) => {
      this.body.options[key] = options[key];
    });
  }

  get canLoadMore() {
    return this.data.length < this.totalResults;
  }

  /**
   * Due to multiple emits from observables results may repeat.
   * precautionary function to eliminates duplicate results.
   * Bugfix: DE372
   * @param docs
   */
  addUniqueDocs(docs: Club[] | EventCardComponentInput[]) {
    const uniqueDocs = [];
    docs.map((doc) => {
      const docId = doc.docId || doc.id;
      if (!this.docIdSet.has(docId)) {
        uniqueDocs.push(doc);
        this.docIdSet.add(docId);
      }
    });
    this.data = [...this.data, ...uniqueDocs];
  }

  updateLoadDocsFrom() {
    this.loadDocsFrom += this.pageSize;
    this.body.options.paginate.from = this.loadDocsFrom;
  }
}


