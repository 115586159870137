import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { AuthService } from 'src/app/core/auth/auth.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Payment } from 'src/app/core/model/interfaces/payment';
import { PaymentsService } from 'src/app/core/model/services/payments.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'yo-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject();
  payment: Payment;
  processed: boolean = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentsService,
    private gtmService: GoogleTagManagerService
  ) { }

  ngOnInit() {
    const productId = this.route.snapshot.paramMap.get('productId');
    console.log('productId', productId);
    // Get user
    this.authService.authenticatedUser$
      .pipe(
        filter(authedUser => authedUser !== null),
        switchMap(authedUser => {
          console.log('authedUser', authedUser);
          // Get the payment
          return this.paymentService.query([
            ['userDocId', '==', authedUser.docId],
            ['productDocId', '==', productId]
          ]);
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        payments => {
          const payment = payments.pop(); // Where query returns an array, be wary of people buying live then buying vod
          console.group('Successful payment');
          console.log('fetched payment', payment);
          this.payment = payment;

          if (payment.confirmed) {
            this.processed = true;
            console.log('payment confirmed');
            const gtmTag = {
              'event': 'PaymentMade',
              'product_purchased': this.payment.productDocId,
              'product_type': this.payment.productType,
              'transaction_type': this.payment.transactionType,
              'order_id': this.payment.docId,
              'order_value': this.payment.stripeSession ? this.payment.stripeSession.amount_total : this.payment.amount || 0,
              'order_currency': this.payment.stripeSession ? this.payment.stripeSession.currency : 'unknown',
            };
            console.log('GTM - TAG', { ...gtmTag });
            this.gtmService.pushTag(gtmTag);
            console.log('GTM - TAG pushed');
            console.log('Creating a timeout for redirect');
            setTimeout(() => {
              console.log('Redirection would happen now');
              this.router.navigate(['browse', this.payment.productType == 'livestreamGroup' ? 'collections' : 'streams', productId]);
            }, 2000); // Set it for 25s for testing
          } else {
            console.log('payment not confirmed');
          }
          console.groupEnd();
        },
        err => console.error("An error occured while fetching the payment", err)
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
