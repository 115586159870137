<section class="section" [class.main]="headSection" [class.mobile]="isMobileView" [id]="sectionId">
  <header>
    <h2 >
      <img *ngIf="iconSrc"
           class="icon"
           role="img"
           [src]="iconSrc"
           alt="Section's Icon">
      <a (click)="headerClicked()" [routerLink]="link" [queryParams]="linkQueryParams" *ngIf="link">{{ title }}</a>
      <ng-container (click)="headerClicked()" *ngIf="link == null">{{ title }}</ng-container>
    </h2>
    <div (click)="subheaderClicked()" class="subheader">
      <ng-content select="[header]"></ng-content>
    </div>
  </header>

  <ng-content></ng-content>

  <footer [hidden]="!showMore" class="see-more" *ngIf="link && !headSection">
    <a mat-stroked-button (click)="actionButtonClicked()" [routerLink]="link" [queryParams]="linkQueryParams">{{ buttonLabel }}</a>
  </footer>
</section>
