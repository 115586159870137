import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'yo-stripe-subscription-payment',
  templateUrl: './stripe-subscription-payment.component.html',
  styleUrls: ['./stripe-subscription-payment.component.css']
})
export class StripeSubscriptionPaymentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
