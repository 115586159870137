import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatGridListModule } from "@angular/material/grid-list";

import { SearchComponent } from "./search.component";
import { SearchResultsComponent } from "./search-results/search-results.component";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { MatButtonModule } from "@angular/material/button";
import { YoimoUiModule } from "../yoimo-ui/yoimo-ui.module";
import { SearchRoutingModule } from "./search-routing.module";
import { ChannelResultsComponent } from "./channels/channels.component";
import { PlaylistResultsComponent } from "./playlists/playlists.component";
import { StreamResultsComponent } from "./streams/streams.component";

@NgModule({
  declarations: [
    ChannelResultsComponent,
    PlaylistResultsComponent,
    SearchComponent,
    SearchResultsComponent,
    StreamResultsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    ReactiveFormsModule,
    SearchRoutingModule,
    YoimoUiModule,
  ],
  exports: [SearchComponent],
})
export class SearchModule {}
