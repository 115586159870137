import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';

import { MatPaginator } from '@angular/material/paginator';
import { MatTab } from '@angular/material/tabs';
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/core/model/interfaces/user';
import { UserData } from '../users-table/users-table.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'yo-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  @Input() activeTab: string;
  @Input() authenticatedUser: User;
  @Input() klass: string = '';
  @Input() tabs: ["admin" | "trainer" | "streamer" | "member" | "invite"];
  @Input() userSource: Observable<UserData[]>;

  @Output() selectionChanged = new EventEmitter<
    SelectionChange<Partial<UserData>>
  >();
  @Output() onRemoveClicked = new EventEmitter<boolean>();
  @Output() onTabSelected: EventEmitter<string> = new EventEmitter();

  @ViewChild('userPaginator', { static: true }) userPaginator: MatPaginator;

  selectedUser: UserData;
  selectedTab: number;
  selection: SelectionModel<Partial<UserData>>;
  showingTabs = {
    admin: false,
    trainer: false,
    streamer: false,
    member: false,
    invite: false
  };
  userDataSource: MatTableDataSource<UserData>;
  users: UserData[];
  usersLength: number = 0;

  constructor() { }

  ngOnInit() {
    console.group('Users list - init');
    console.info(this.activeTab);
    console.info(this.tabs);
    console.info(this.showingTabs);

    this.selection = new SelectionModel(true, []);
    this.selection.changed
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(selection => {
        console.log("Selecting this user", selection);
        this.selectionChanged.emit(selection);
      });

    this.tabs.forEach(givenTab => this.showingTabs[givenTab] = true);
    this.userDataSource = new MatTableDataSource([]);
    this.userDataSource.paginator = this.userPaginator;
    console.info(this.showingTabs);

    this.userSource.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      users => {
        console.group('Users list - user source');
        console.info('Existing users', this.users);
        console.info('Received users', users);
        this.users = users || [];
        this.userDataSource.data = this.users;
        console.groupEnd();
      },
      err => console.error(err)
    );

    console.groupEnd();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  hasTab(tabName: string) {
    console.group('Has tab');
    console.info('Tab name', tabName);
    console.info('tabs', this.tabs);
    const tabIndex = this.tabs.findIndex(tab => tab === tabName);
    console.info('tab result', tabIndex);
    console.groupEnd();
    return tabIndex ? true : false;
  }

  mapActiveTabToIndex(): number {
    return this.tabs.findIndex(tab => tab === this.activeTab);
  }

  onSelectedTabChange(event) {
    console.log(event);
    this.selectedTab = event.index;
    this.selection.clear();
    this.onTabSelected.emit(this.tabs[event.index]);
  }

  onRemoveClick() {
    this.onRemoveClicked.emit(true);
  }

  selectRow(row) {
    this.selection.clear();
    this.selection.select(row);
  }
}