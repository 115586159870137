import { Callable, Collection } from '../collection';
import { Injectable, Injector } from '@angular/core';

import { UploadType } from '../../utility/upload.service';
import {User} from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends Collection<User> {

  public openCustomerPortal: Callable<User, any>;
  constructor(
    injector: Injector
  ) {
    super(injector, 'users', true);
    this.openCustomerPortal = this.declareCallable<UsersService['openCustomerPortal']>('stripe-openCustomerPortal');
  }

  /**
   * Update the given user's fingerprint if needed
   *
   * @param user
   * @param fingerprint
   */
  updateUserFingerprint(user: User, fingerprint: number): Promise<void> {
    return this.update(user.docId, {fingerprint: fingerprint});
  }

  getStoragePath(userDocId: string, target: UploadType): string {
    return ["users", userDocId, target].join("/");
  }

}
