import {Directive, ElementRef, Input, Renderer2, OnInit} from '@angular/core';
import {darkTheme} from '../theme/dark-theme';

@Directive({
  selector: '[yoBackgroundColor]'
})
export class BackgroundColorDirective implements OnInit {
  @Input() yoBackgroundColor = 'background1';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', darkTheme.properties[this.yoBackgroundColor]);
    const textColor = 'on' + this.yoBackgroundColor[0].toUpperCase() + this.yoBackgroundColor.substr(1);
    this.renderer.setStyle(this.elementRef.nativeElement, 'color', darkTheme.properties[textColor]);
  }
}
