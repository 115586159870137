import {ActivatedRoute} from '@angular/router';
import { ClientJS } from 'clientjs';
import { Injectable } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  public client;
  public fingerprint;
  public appUserAgent;
  public appVersionNumber;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.client = new ClientJS();
    this.init();
  }

  /**
   * Return true if client_app route parameter equals 'ios' else false
   *
   * @param route
   */
  static isClientAppIOS(route: ActivatedRoute): boolean {
    return ClientService.isClientAppEqualsTo(route, 'ios');
  }

  /**
   * Return true if client_app route parameter equals 'android' else false
   *
   * @param route
   */
  static isClientAppAndroid(route: ActivatedRoute): boolean {
    return ClientService.isClientAppEqualsTo(route, 'android');
  }

  /**
   * Return true if client_app route parameter equals given client os (ios, android, etc) else false
   *
   * @param route
   * @param client
   */
  static isClientAppEqualsTo(route: ActivatedRoute, client: string): boolean {
    return route.snapshot.queryParams['client_app'] && route.snapshot.queryParams['client_app'].toLowerCase() === client.toLowerCase();
  }

  /**
   * Initializes the service with basic information upon instantiation.
   */
  init() {
    // TODO: We might need to add more information onto fingerprints later on.
    // Getting client fingerprint
    const browserVersion = this.client.getBrowserVersion();
    const osVersion = this.client.getOSVersion();
    const deviceType = this.client.getDeviceType();
    const cpu = this.client.getCPU();
    const plugins = this.client.getPlugins();
    const mimeTypes = this.client.getMimeTypes();
    this.fingerprint = this.client.getCustomFingerprint(browserVersion, osVersion, deviceType, cpu, plugins, mimeTypes);

    // Getting client user agent
    const userAgent = this.client.getUserAgent();

    // Extracting app exclusive information
    const regexp = new RegExp(/(joymo)\.(\w+)((?:\/[\w-]*|\d*\.*)+)/gm);
    const matches = userAgent.match(regexp);
    if (matches && matches.length > 0) {
      const [appUserAgent, appVersionNumber] = matches[0].split('/');
      this.appUserAgent = appUserAgent;
      this.appVersionNumber = appVersionNumber;
    } else {
      this.appUserAgent = null;
      this.appVersionNumber = null;
    }
  }

  /**
   * Return true if the website is displayed in the webview
   */
  isClientInApp() {
    let isClientInApp = false;

    switch (this.appUserAgent) {
      case 'joymo.ios':
      case 'joymo.android':
        // iOS or Android Webview
        isClientInApp = true;
        break;
      default :
        isClientInApp = false;
        break;
    }

    return isClientInApp;
  }

  /**
   * Return true if the website is displayed on a mobile device
   */
  isMobileDevice(): boolean {
    return this.client.isMobile() ||
      this.client.isMobileIOS() ||
      this.client.isIphone() ||
      this.client.isIpad() ||
      this.client.isIpod() ||
      this.client.getOS() === 'Android' ||
      this.client.getDeviceType() === 'tablet';
  }

  /**
   * Return true if the website is displayed on a mobile device and not in the client app
   */
  isOnMobileAndNotInClientApp(): boolean {
    return this.isMobileDevice() && !this.isClientInApp();
  }

  get breakpoint(): Observable<string> {
    return this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(
        map((bpState) => {
          const stateEntries = Object.entries(bpState.breakpoints);
          const index = stateEntries.findIndex(([key, value]) => value == true);
          return stateEntries[index][0];
        }),
        catchError((err, bpStateKey) => {
          console.error("An error occured while determining breakpoint", err);
          return bpStateKey;
        })
      );
  }
}

export enum ClientAppType {
  Undefined,
  iOS,
  Android
}
