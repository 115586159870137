<ul role="toolbar">
  <li>
    <strong>Share</strong>
  </li>
  <li *ngFor="let target of socialTargets">
    <button
      type="button"
      role="social-button"
      [url]="shareableUrl"
      [shareButton]="target.label | lowercase"
    >
      <img [src]="target.iconUrl" [alt]="target.iconAlt" />
    </button>
  </li>
  <li>
    <button
      type="button"
      role="copy-link"
      shareButton="copy"
      [url]="shareableUrl"
      (click)="onCopiedUrl()"
    >
      <img src="assets/icons/link.svg" alt="Copy link" />
      Copy link
    </button>
  </li>
</ul>
