import { AccessOption, PaymentsService } from './payments.service';
import {Callable, Collection, PagedQueryCommand, PagingState, QueryWhereParameter, uniqBy} from '../collection';
import { FieldPath, OrderByDirection, Timestamp, updateDoc } from '@angular/fire/firestore';
import { Injectable, Injector } from '@angular/core';
import {Livestream, LivestreamTechnical} from '../interfaces/livestream';
import {Observable, combineLatest, from, of} from 'rxjs';
import {filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import { AttachedAd } from '../interfaces/attached-ad';
import { AuthService } from '../../auth/auth.service';
import { DocumentSnapshot } from '@angular/fire/firestore';
import { EventCardComponentInput } from 'src/app/yoimo-ui/cards/event/event-card.component';
import { LivestreamMemberData } from '../interfaces/livestream-member-data';
import { Shareable } from '../interfaces/shareable';
import { User } from '../interfaces/user';
import moment from 'moment';

interface AccessDeniedResponse {
  granted: false;
}

type MatchableRules = "GROUP" | "LIVE" | "VOD" | "BUNDLE" | "VIEWER" | "COATCH" | "ADMIN";

type CookieName = string;
type CookieValue = string;
type CookieDomain = string;
type CookiePath = string;
type CookieExpiracy = string;
type CookieTuple = [CookieName, CookieValue, CookieDomain, CookiePath, CookieExpiracy];

export interface AccessGrantedResponse {
  granted: true;
  matchingRule: MatchableRules;
  proxyProductId: string;
  validity: Date;
  attachedAd?: AttachedAd;
  cookies: CookieTuple[];
  videoAccessUrl: string;
}

export type AccessCheckDenialReason =
  | 'CONTENT_NOT_AVAILABLE'
  ;

export type AccessRightResponse = AccessDeniedResponse | AccessGrantedResponse;

export type AccessCheckState =
  | { type: 'CHECKING' }
  | { type: 'GRANTED', permission: AccessGrantedResponse }
  | { type: 'DENIED', reason: AccessCheckDenialReason }
  | { type: 'PAYMENT_NEEDED', buyingOptions: AccessOption[], needsAuth: boolean };

@Injectable({
  providedIn: 'root'
})
export class LivestreamsService extends Collection<Livestream> implements Shareable {

  public readonly gdprStatusApproved = 'approved';
  public readonly gdprStatusAwaiting = 'awaiting';
  public readonly gdprStatusSelfApproved = 'self approved';
  public deleteLivestream: Callable<{livestreamDocId: string}, boolean>;
  public accessRightCheckCallable: Callable<{livestreamId: string}, AccessRightResponse>;

  private lstCollection: Collection<LivestreamTechnical>;
  constructor(
    injector: Injector,
    private authService: AuthService,
    private paymentsService: PaymentsService,
  ) {
    super(injector, 'livestreams', true);
    console.warn('Using the default converter for LivestreamsService');
    this.lstCollection = new Collection(injector, 'livestreamTechnicals', true);
    this.deleteLivestream = this.declareCallable<LivestreamsService['deleteLivestream']>('livestreams-deleteLivestream');
    this.accessRightCheckCallable = this.declareCallable<LivestreamsService['accessRightCheckCallable']>('livestreams-checkAccessRight');
  }


  /**
   * @deprecated Use getShareableUrl()
   * @param livestreamDocId 
   * @returns 
   */
  static getLivestreamShareUrl(livestreamDocId): string {
    const locationUrl = window.location;
    return locationUrl.protocol + '//' + locationUrl.host + '/api/share/' + livestreamDocId;
  }

  getDuration(stream: Livestream) {
    // TO UPDATE WHEN USING Firebase Queries
    const startTime = stream.scheduledStartTime;
    const stopTime = stream.scheduledStopTime;
    const startMoment = moment(new Timestamp(startTime.seconds, startTime.nanoseconds).toDate());
    const stopMoment = moment(new Timestamp(stopTime.seconds, stopTime.nanoseconds).toDate());

    const duration = moment.duration(stopMoment.diff(startMoment));
    return duration.asMinutes().toFixed(0);
  }

  getStartTime(stream: Livestream) {
    const startTime = stream.scheduledStartTime;
    return moment(new Timestamp(startTime.seconds, startTime.nanoseconds).toDate()).calendar(null, {
      // sameDay: '[Today] - HH:mm',
      // nextDay: '[Tomorrow] - HH:mm',
      // nextWeek: 'dddd - HH:mm',
      // lastDay: '[Yesterday] - HH:mm',
      // lastWeek: '[Last] dddd - HH:mm',
      sameDay: '[Today] - HH:mm',
      nextDay: '[Tomorrow] - HH:mm',
      nextWeek: 'DD/MM/YYYY - HH:mm',
      lastDay: 'DD/MM/YYYY - HH:mm',
      lastWeek: 'DD/MM/YYYY - HH:mm',
      sameElse: 'DD/MM/YYYY - HH:mm'
    });
  }

  getStopTime(stream: Livestream) {
    const stopTime = stream.scheduledStopTime;
    return moment(new Timestamp(stopTime.seconds, stopTime.nanoseconds).toDate()).calendar(null, {
      sameDay: '[Today] - HH:mm',
      nextDay: '[Tomorrow] - HH:mm',
      nextWeek: 'DD/MM/YYYY - HH:mm',
      lastDay: 'DD/MM/YYYY - HH:mm',
      lastWeek: 'DD/MM/YYYY - HH:mm',
      sameElse: 'DD/MM/YYYY - HH:mm'
    });
  }

  getFutureStreams(latestSnapshot?: DocumentSnapshot<Livestream>, includeArchived: boolean = false): Observable<Livestream[]> {
    const now = new Date();
    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);
    // TODO#: Change this to paged queries
    const $futureStreams = this.query([
        ['scheduledStopTime', '>', now],
        ['isPublic', '==', true]
      ],
      50,
      [{field: 'scheduledStopTime', sort: 'asc'}]);

    return $futureStreams.pipe(
      map(streams => streams.filter(d => includeArchived ? true : d.archived !== true))
    );
  }

  getPastStreams(latestSnapshot?: DocumentSnapshot<Livestream>, includeArchived: boolean = false): Observable<Livestream[]> {
    const now = new Date();
    // TODO#: Implement paging correctly
    const $pastStreams = this.query([
        ['scheduledStopTime', '<', now],
        ['isPublic', '==', true],
      ],
      100,
      [{field: 'scheduledStopTime', sort: 'desc'}]);
    return $pastStreams.pipe(
      map(streams => streams.filter(d => includeArchived ? true : d.archived !== true))
    );
  }

  extendLivestream(livestreamDocId: string, newTime: Timestamp): Promise<void> {
    return this.update(livestreamDocId, {scheduledStopTime: newTime});
  }

  hasViewingRights(livestream: Livestream, userDocId: string) {
    // console.log(userDocId);
    // Todo: A user could have access by a LivestreamGroup, so needs to check for that as well in here
    return livestream.buyerDocIds.includes(userDocId) || livestream.ownerDocId === userDocId;
  }
  /**
   * @deprecated
   * @param livestreamDocId Stream document ID
   */
  canAccessLivestream(livestreamDocId: string): Observable<AccessRightResponse>  {
    return this.callCheckAccessFunction(livestreamDocId);
  }

  callCheckAccessFunction(livestreamDocId: string): Observable<AccessRightResponse> {
    return this.accessRightCheckCallable({ livestreamId: livestreamDocId }).pipe(
      tap((rights) => {if (rights.granted) { this.setupSignedAccessCookies(rights.cookies); }})
    );
  }

  /**
   * Returns an observable reporting the user's access status for a given livestream.
   * This should eventually replace calls to canAccessLivestream
   * @param livestreamDocId Steam document ID
   */
  getStreamAccessToLivestream(livestream: Livestream): Observable<AccessCheckState> {
    return new Observable(observer => {

      const getPaymentOptions = (
        stream,
        signupNeeded
      ): Observable<AccessCheckState> =>
        this.paymentsService.getTicketOptionsForStream(stream, window.location.pathname).pipe(
          switchMap<AccessOption[], Observable<AccessCheckState>>((options) => {
            if (options.length === 0) {
              return of({ type: 'DENIED', reason: 'CONTENT_NOT_AVAILABLE' });
            } else {
              return of({
                type: 'PAYMENT_NEEDED',
                buyingOptions: options,
                needsAuth: signupNeeded,
              });
            }
          })
        );

      observer.next({ type: 'CHECKING' });

      let currentUserID;
      this.authService.authenticatedUser$.pipe(
        filter(user => { const ret = currentUserID !== (user && user.docId); currentUserID = user && user.docId; return ret; }),
        take(1),
        // We first check if the user is logged in
        switchMap<User, Observable<AccessCheckState>>(user => {
          if (livestream.archived) {
            return of({ type: 'DENIED', reason: 'CONTENT_NOT_AVAILABLE' });
          }
          if (!user) {
            // User not logged in
            return getPaymentOptions(livestream, true);
          } else {
            return this.callCheckAccessFunction(livestream.docId).pipe(
              // Check the backend to see if the user has access to the stream.
              switchMap<AccessRightResponse, Observable<AccessCheckState>>(
                (checkAccessResult) => {
                  if (checkAccessResult.granted) {
                    // Happy path, access is granted
                    this.setupSignedAccessCookies(checkAccessResult.cookies);
                    return of({ type: 'GRANTED', permission: checkAccessResult });
                  } else {
                    // The user needs to proceed with a payment, we return the list of
                    // options available.
                    return getPaymentOptions(livestream, false);
                  }
                }
              )
            );
          }
        })
      ).subscribe((state: AccessCheckState) => {
        observer.next(state);
        console.log("Access Check changed");
        if (state.type !== 'CHECKING') {
          console.log("Access Check Finalized with ", state.type);
          observer.complete();
        }
      });
    });
  }

  getByDocIds(docIds: string[], includeArchived: boolean = false): Observable<Livestream[]> {
    return super.getByDocIds(docIds).pipe(
      map( docs => includeArchived ? docs : docs.filter(d=>d.archived !== true ))
    );
  }

  getByTeamDocId(
    teamDocId: string,
    loadMore: Observable<PagedQueryCommand>,
    limit?: number,
    wheres?: QueryWhereParameter | QueryWhereParameter[],
    orderBy?: {field: FieldPath | string, sort: OrderByDirection}[],
    includeArchived: boolean = false)
  : Observable<{data: Livestream[], paging: PagingState}> {
    return this.pagedQuery(
      limit,
      loadMore,
      [['teamDocId', '==', teamDocId ], ...wheres],
      orderBy
    ).pipe(
      map( ({data, paging}) => ({paging, data: data.filter((s) => includeArchived || s.archived !== true)}))
    );
  }
  // This function adds the cookie signature using javascript. Safari
  // not saving cookies in redirects and also not sending third party
  // cookies means we have to do this through javascript and it only
  // works if we are on the same domain.
  setupSignedAccessCookies(
    cookies: [string, string, string, string, string][]
  ) {
    for (const [key, value, domain, path, expires] of cookies) {
      document.cookie = `${key}=${value};domain=${domain};path=${path};expires=${expires};`;
    }
  }

  private _pickConflicting(
    streamId: string,
    streams: Livestream[],
    scheduledStart: Date,
    scheduledStop: Date,
    predicate?: (stream: Livestream) => boolean
  ) {
    return streams.filter(stream =>
      streamId !== stream.docId
      && !(stream.scheduledStopTime.toDate() < scheduledStart || scheduledStop < stream.scheduledStartTime.toDate())
      && (predicate ? predicate(stream) : true)
    );
  }
  private _checkSportyConflicts(sportyId: string, ignoredSportyIds: string[]) {
    return (stream: Livestream) => stream.sportyDocId === sportyId;
  }
  private _checkStreamQuotaConflicts(streamLimit: number, scheduledStart: Date, scheduledStop: Date, streams: Livestream[])  {
    // Algorithm :
    // 1- Create a list of checkpoints which are pairs of (timestamp, value)
    //    every stream will produce 2 checkpoints (startTime, +1) and (endTime, -1)
    // 2- Sort the list of checkpoints on the timestamp
    // 3- Go through the list and sum "value" keeping track of the maximum
    //
    // 01234567890123456789012
    // ----[         ]---------
    // ========================>
    // -[          ]---------- (1,+1) (12,-1)
    // -----------[       ]--- (12,+1) (19,-1)
    // --[        ]----------- (2,+1) (11, -1)
    //
    // Sort = (1,+1) (2,+1) (11, -1) (12,-1) (12,+1) (19,-1)
    // (PartialSum, Max) = (1,1) => (2,2) => (1,2) => (0,2) => (1,2) => (0,2)
    if (streamLimit === undefined || streamLimit === -1) {
      return [];
    }
    const chargeMap = streams
      .reduce(
        (acc, c) =>
          acc.concat([
            [c.scheduledStartTime.toMillis(), 1],
            [c.scheduledStopTime.toMillis(), -1],
          ]),
        [ [scheduledStart.getTime(), 1], [ scheduledStop.getTime(), -1] ]
      )
      .sort(([a], [b]) => a - b);

    const [, maxCharge] = chargeMap.reduce(([s, max], [, v]) => [s + v, Math.max(max, s + v)], [0, 0]);

    if ( maxCharge > streamLimit) {
      return streams;
    }

    return [];
  }

  getConflictingStreams(
    livestreamId: string | null,
    sportyId: string,
    clubDocId: string,
    streamLimit: number,
    scheduledStart: Date,
    scheduledStop: Date,
    ignoredSportyIds: string[]
  ): Observable<{limit: Livestream[], sporty: Livestream[]}> {
    const lookupWindowStart = new Date(scheduledStart.getTime() - 12 * 60 * 60 * 1000);
    const lookupWindowEnd = new Date(scheduledStop.getTime() + 5 * 60 * 60 * 1000);

    // We have to get streams from multiple source because the livecasters/sporties
    // can be shared between clubs and we should still check that they are not conflicting.
    // The conflict boundary is thus all the games from the same club plus possibly the games
    // from other clubs using the same livecaster.
    // !! The two sets are not equivalent !!
    const clubSource = this.query([
      ['clubDocId', '==', clubDocId],
      ['scheduledStartTime', '>=', lookupWindowStart],
      ['scheduledStartTime', '<=', lookupWindowEnd],
    ]);

    const sportySource = sportyId
      ? this.query([
          ['sportyDocId', '==', sportyId],
          ['scheduledStartTime', '>=', lookupWindowStart],
          ['scheduledStartTime', '<=', lookupWindowEnd],
        ])
      : of([] as Livestream[]);

    return combineLatest([sportySource, clubSource]).pipe(
      map(([sportyResults, clubResults]) => ([
        this._pickConflicting(
          livestreamId,
          sportyResults,
          scheduledStart,
          scheduledStop,
          this._checkSportyConflicts(sportyId, ignoredSportyIds)
        ),
        this._checkStreamQuotaConflicts(
          streamLimit,
          scheduledStart,
          scheduledStop,
          this._pickConflicting(livestreamId, clubResults, scheduledStart, scheduledStop)
        )
      ])),
      map( ([sporty, limit]) => ({ sporty, limit})),
      take(1),
    );
  }

  getByLivestreamGroupDocId(livestreamGroupDocId: string): Observable<Livestream[]> {
    return this.query(['livestreamGroupDocId', '==', livestreamGroupDocId]);
  }

  getMemberData(livestreamDocId: string): Observable<any> {
    return this.getSubCollection(livestreamDocId, 'memberData').getDocData('memberData');
  }

  getTechnicalDetails(livestreamDocId: string): Observable<LivestreamTechnical> {
    return this.lstCollection.getByDocId(livestreamDocId);
  }

  sortByName(livestreams: Livestream[]): Livestream[] {
    return livestreams.sort((a, b) => {
      const streamAName = a.name.toLowerCase();
      const streamBName = b.name.toLowerCase();

      if (streamAName < streamBName) {
        // sort ascending
        return -1;
      }
      if (streamAName > streamBName) {
        return 1;
      }
      return 0;
    });
  }

  sortByStartTime(streams: Livestream[], order: "asc" | "desc"): Livestream[] {
    return streams.sort((s1, s2) => {
      switch (order) {
        case "asc":
          return s1.scheduledStartTime.toDate().getTime() - s2.scheduledStartTime.toDate().getTime();
        case "desc":
          return s2.scheduledStartTime.toDate().getTime() - s1.scheduledStartTime.toDate().getTime()
      }
    });
  }

  stopLivestream(livestreamDocId: string): Promise<void> {
    return this.update(livestreamDocId, { scheduledStopTime: Timestamp.fromDate(new Date()) });
  }

  getShareableURL(docId: string): URL {
    return new URL(`/api/share/${docId}`, document.location.href);
  }

  /**
   * Decides whether or not a stream can be removed. 
   * @param stream needs to be an aggregate of a Livestream and it's MemberData
   * @returns boolean
   */
  isRemovable(stream: Livestream & { memberData: LivestreamMemberData }): boolean {
    if (!stream) { return false; }
    if (stream.channelReady === true) { return false; }
    if (stream.memberData && stream.memberData.schedulingState && (
      !['STACK_STOPPED', 'UNSCHEDULED', 'SCHEDULING_QUEUED', undefined].includes(stream.memberData.schedulingState)
    )) {
      return false;
    }
    // if (stream.scheduledStartTime.toDate() < new Date()) { return false; }
    // if (stream.buyerDocIds.length > 0) { return false; }

    return true;
  }
}
