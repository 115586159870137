<mat-toolbar *ngIf="!isMobileSearchActive">
  <mat-toolbar-row class="border bottom">
    <div class="menu">
      <button mat-icon-button
              class="nav"
              (click)="onToggleSideNav.emit()">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="logo">
        <a [routerLink]="['/browse']">
          <img src="assets/Joymo_Logo_White_CMYK.svg"
               alt="Joymo logo" />
        </a>
      </div>
    </div>
    <!-- <span class="header-title" ngClass.lt-sm="lt-sm" ngClass.gt-md="gt-md" ngClass.md="md" routerLink="/browse">
      Joymo
    </span> -->
    <!-- <object class="logo" data="assets/Joymo_Logo_Dark_Grey_CMYK.svg" type="image/svg+xml"></object> -->

    <yo-search *ngIf="!isMobileView"></yo-search>

    <div fxLayout="row"
         fxLayoutAlign="end center"
         class="user">
      <ng-container *ngIf="isMobileView">
        <button mat-icon-button
                class="logged mobile-search-btn"
                (click)="isMobileSearchActive=true">
          <mat-icon>search</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="user$ | async as user; else signInTpl">
        <mat-menu backdropClass="logged-menu"
                  #userMenu="matMenu">
          <button mat-menu-item
                  [routerLink]="['/account']">
            <div class="account">
              <mat-icon>person</mat-icon>
              <span class="labels">
                <span class="label">My account</span>
                <span class="email">{{ user.email }}</span>
              </span>
            </div>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item
                  *ngIf="hasManageAccess"
                  [routerLink]="['/manage']">
            <mat-icon>insert_chart_outlined</mat-icon>Dashboard
          </button>
          <button mat-menu-item
                  [routerLink]="['/browse/tickets']">
            <mat-icon>confirmation_number</mat-icon>My Tickets
          </button>

          <button mat-menu-item
                  (click)="onToggleLogin.emit()">
            <mat-icon>exit_to_app</mat-icon>Sign Out
          </button>
        </mat-menu>
        <ng-container *ngIf="!isMobileView; else mobileMenuTriger">
          <button class="logged"
                  mat-stroked-button
                  [matMenuTriggerFor]="userMenu">
            <mat-icon>account_circle</mat-icon>
          </button>
          <yo-new-stream-button *ngIf="hasManageAccess"></yo-new-stream-button>
        </ng-container>
        <ng-template #mobileMenuTriger>
          <button class="logged"
                  mat-icon-button
                  [matMenuTriggerFor]="userMenu">
            <mat-icon>account_circle</mat-icon>
          </button>
          <yo-new-stream-button *ngIf="hasManageAccess"
                                [mobile]="true"></yo-new-stream-button>
        </ng-template>
      </ng-container>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<yo-search *ngIf="isMobileSearchActive"
           (goBack)="isMobileSearchActive=false"></yo-search>

<ng-template #signInTpl>
  <button mat-raised-button
          class="login"
          color="accent"
          (click)="onToggleLogin.emit()">
    Sign In
  </button>
</ng-template>
