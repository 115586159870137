<section class="playlists">
  <div class="content" [style.margin]="-1 / 2 + 'rem'">
    <yo-event-card
      *ngFor="
        let item of playlists;
        let key = index;
        trackBy: trackElements
      "
      class="yo-theme card-holder"
      [ngClass]="{ mobile: isMobileView }"
      [style.margin]="1 / 2 + 'rem'"
      [style.flexBasis]="isMobileView ? none : 'calc(25% - 1rem)'"
      [event]="item"
      [useMobileTemplate]="isMobileView"
      [appearance]="eventCardConfig.appearance"
      [showLogo]="false"
      [showCountdown]="eventCardConfig.showCountdown"
      (eventFinished)="handleFinishedEvent(item.id)"
    >
    </yo-event-card>
  </div>
  <!-- Playlists load more -->
  <footer>
    <p *ngIf="!canLoadMore">You've reached the end of this page.</p>
    <ng-container *ngIf="canLoadMore">
      <button (click)="loadMoreDocs()" mat-stroked-button type="button">
        <ng-container *ngIf="!isLoading; else spinner">
          Load more
        </ng-container>
        <ng-template #spinner>
          <yo-spinner></yo-spinner>
        </ng-template>
      </button>
    </ng-container>
  </footer>
</section>
